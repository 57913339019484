import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Spacer,
    Card,
    CardBody,
    Badge,
    Progress,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    Center,
    HStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { getMemberDetails, performLogout } from "../../../application/action/auth_action";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat } from "../../../common/util";
import GeneralTopBar from "../../../component/general-top-bar";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { ApiKey, Language } from "../../../common/constant";
import { Pages } from "../../../routes/page";
import { ConfigEnum } from "../../../common/config";
import Header from "../../non-auth-pages/header";
import { useNavigate } from "react-router";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const [expBarValue, setExpBarValue] = useState([0, 0, 0, 0]);
    const [data, setData] = useState([]);
    const languageCode = localStorage.getItem(ConfigEnum._LANGUAGE) || Language._ENGLISH;
    const _BOTTOM_BUTTOM = [
        {
            icon: require('../../../assets/images/downline-member.png'),
            title: 'DOWNLINES_MEMBER_REPORT'
        },
        {
            icon: require('../../../assets/images/general-help.png'),
            title: 'GENERAL_HELP'
        },
        {
            icon: require('../../../assets/images/profile-security.png'),
            title: 'SECURITY_OR_PRIVACY'
        },
        {
            icon: require('../../../assets/images/profile-setting.png'),
            title: 'SETTINGS'
        },
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        _dispatch(getMemberDetails());

        return () => null;
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        calculateExpBar();
        getInactivityAlert();
    }, [userData])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getInactivityAlert = async () => {
        try {
            let responseJson = await ApiEngine.get(Routes._MEMBER_INACTIVITY_ALERT, {
                headers: { skipLoading: true }
            });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setData(responseJson[ApiKey._API_DATA_KEY]);
            }
        } catch (err) {
            // nothing
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const signOutHandler = () => {
        _dispatch(
            showCustomDialog({
                title: t('LOGOUT'),
                content: t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT'),
                horizontalBtn: false,
                onConfirm: () => {
                    _dispatch(performLogout());
                },
                onCancel: () => { }
            })
        );
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function settingActionHandler(key) {
        switch (key) {
            case _BOTTOM_BUTTOM[0]['title']:
                _router.navigate(Pages._DOWNLINE_REPORT);
                break;
            case _BOTTOM_BUTTOM[1]['title']:
                _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}?lang=${languageCode}` });
                break;
            case _BOTTOM_BUTTOM[2]['title']:
                _router.navigate(Pages._SECURITY_OR_PRIVACY);
                break;
            case _BOTTOM_BUTTOM[3]['title']:
                _router.navigate(Pages._SETTINGS);
                break;
            default:
                break;
        }
    }

    function calculateExpBar() {
        let expBar = [];
        let singleBarMaxValue = userData?.nextRankDetail?.minimumExperience / 4;
        let userCurrentExp = userData?.experienceDetail?.experience;

        for (let i = 0; i < 4; i++) {
            if (userCurrentExp >= singleBarMaxValue) {
                expBar.push(100);
            }
            else {
                expBar.push((userCurrentExp / singleBarMaxValue) * 100);
            }

            if (userCurrentExp - singleBarMaxValue > 0) {
                userCurrentExp -= singleBarMaxValue;
            }
            else {
                userCurrentExp = 0;
            }

        }

        setExpBarValue(expBar);
    }

    if (!checkBrowser()) {
        return (
            <div className='body-content display-center' style={{ position: 'fixed' }}>
                <GeneralTopBar title="MY_PROFILE" />
                <Stack p={1} style={{ backgroundImage: `url(${userData?.experienceDetail?.outerFrame})`, borderRadius: '20px', marginTop: '10px' }}>
                    <Card className="player-detail-box" borderRadius='20px'>
                        <Stack style={{ background: '#232E46', borderRadius: '20px' }}>
                            <CardBody>
                                <Flex align={'center'}>
                                    <div className="profile-picture">
                                        <Image
                                            className="rank-image"
                                            boxSize={'55px'}
                                            src={userData?.experienceDetail?.innerFrame} />
                                        <Image
                                            className="profile-image"
                                            boxSize={'49px'}
                                            borderRadius={25}
                                            src={userData?.profileImage
                                                ? userData?.profileImage
                                                : require('../../../assets/images/topbar-user-defaultphoto.png')} />
                                    </div>
                                    <Stack ml={2}>
                                        <Text as='b' fontSize={'sm'}>{userData?.alias ?? '-'}</Text>
                                        <Text color={'#C6D1E2'} mt={-2.5}>{userData?.username ?? '-'}</Text>
                                    </Stack>
                                    <Spacer />
                                    <Button
                                        className="grey-share-button"
                                        style={{ width: '35%' }}
                                        leftIcon={<Image boxSize={'28px'} src={require('../../../assets/images/scan.png')} />}
                                        onClick={() => { _router.navigate(Pages._MY_QR_CODE, { isMemberReferral: true }); }}>
                                        {t('MY_QR')}
                                    </Button>
                                </Flex>
                            </CardBody>
                        </Stack>
                        <Stack style={{ background: 'black', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '40vh' }}>
                            <CardBody>
                                <Flex align={'center'}>
                                    <Image
                                        boxSize={'70px'}
                                        ml={-2}
                                        src={userData?.experienceDetail?.badgeFile}
                                        onClick={() => { _router.navigate(Pages._RANK_LIST); }} />
                                    <Stack>
                                        <Badge colorScheme='twitter' w='fit-content' borderRadius='20px'>{t('BETA')}</Badge>
                                        <Text mt={-2}>{t('CURRENT_RANK_LEVEL')}</Text>
                                    </Stack>
                                    <Spacer />
                                    <Button variant={'link'} onClick={() => {
                                        _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/ranking?lang=${languageCode}` });
                                    }}>
                                        <Image
                                            boxSize={'30px'}
                                            src={require('../../../assets/images/home-question-after-click.png')}
                                        />
                                    </Button>
                                    <Button
                                        className="grey-share-button"
                                        style={{ width: '25%', marginLeft: '0.5rem' }}
                                        onClick={() => {
                                            _router.navigate(Pages._RANK_HISTORY);
                                        }}>
                                        {t('HISTORY')}
                                    </Button>
                                </Flex>
                                <Stack mt={2}>
                                    <Flex>
                                        <Text as='b'>{t(userData?.experienceDetail?.rankName)}</Text>
                                        <Spacer />
                                        {
                                            data.downgradable && data.dayLeft <= data.maxInactivityDayToShow &&
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Button variant={'link'} justifyContent={'flex-end'} onClick={() => { }}>
                                                        <img width={'20px'} height={'20px'} src={require('../../../assets/images/svg/general-icon-alert.svg').default} />
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="app-alert-popover">
                                                    <PopoverArrow color={'transparent'} />
                                                    <PopoverBody>
                                                        <Text as='b' color='#AACBFF' fontSize={'sm'}>{t('INACTIVITY_ALERT')}</Text> <br />
                                                        <Text>{t('PENALTY_DEDUCTION_OF_EXP_IN_MORE_DAYS', { days: data.dayLeft })}</Text>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        }
                                    </Flex>
                                    <Stack direction={'row'} gap={0}>
                                        <Progress className="experiance-bar" w={'full'} borderStartRadius={20} value={expBarValue[0]} />
                                        <Progress className="experiance-bar" w={'full'} value={expBarValue[1]} />
                                        <Progress className="experiance-bar" w={'full'} value={expBarValue[2]} />
                                        <Progress className="experiance-bar" w={'full'} borderEndRadius={20} value={expBarValue[3]} />
                                    </Stack>
                                    <Flex mb={2.5}>
                                        <Text as='b'>{numberWithCurrencyFormat((userData?.experienceDetail?.experience ?? 0), 2, false) + ' ' + t('EXP')}</Text>
                                        <Spacer />
                                        <Text as='b'>
                                            {userData?.nextRankDetail
                                                ? numberWithCurrencyFormat(userData?.nextRankDetail?.minimumExperience, 2, false) +
                                                ' ' +
                                                t('EXP')
                                                : t('MAX')}
                                        </Text>
                                    </Flex>
                                    <Text textAlign={'center'} color={'app.blue'} as='i'>{t('EARN_MORE_EXP_TO_UPGRADE')}</Text>
                                </Stack>
                            </CardBody>
                        </Stack>
                    </Card>
                </Stack>
                <div className="bottom-sticky-button">
                    {
                        _BOTTOM_BUTTOM.map((item, index) => {
                            return (
                                <Button key={index} className="profile-setting-button" onClick={() => settingActionHandler(item.title)}>
                                    <Flex align={'center'} w='full'>
                                        <Image boxSize={'30px'} src={item.icon} />
                                        <Text fontSize={'sm'} color={'#BBC7DB'}>{t(item.title)}</Text>
                                        <Spacer />
                                        <Image boxSize={'25px'} transform={'rotate(180deg)'} src={require('../../../assets/images/general-back.png')} />
                                    </Flex>
                                </Button>
                            )
                        })
                    }
                    <Button className="grey-button" style={{ borderRadius: 0, height: '3rem' }} onClick={signOutHandler}>
                        {t('SIGN_OUT')}
                    </Button>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={10}>{t('MY_PROFILE')}</Text>
                        </div>

                        <Stack p={1} width={'30em'} style={{ backgroundImage: `url(${userData?.experienceDetail?.outerFrame})`, borderRadius: '20px', marginTop: '10px' }}>
                            <Card className="player-detail-box" borderRadius='20px'>
                                <Stack style={{ background: '#232E46', borderRadius: '20px' }}>
                                    <CardBody>
                                        <Flex align={'center'}>
                                            <div className="profile-picture">
                                                <Image
                                                    className="rank-image"
                                                    boxSize={'51px'}
                                                    src={userData?.experienceDetail?.innerFrame} />
                                                <Image
                                                    className="profile-image"
                                                    boxSize={'45px'}
                                                    borderRadius={25}
                                                    src={userData?.profileImage
                                                        ? userData?.profileImage
                                                        : require('../../../assets/images/topbar-user-defaultphoto.png')} />
                                            </div>
                                            <Stack ml={2}>
                                                <Text as='b' fontSize={'sm'}>{userData?.alias ?? '-'}</Text>
                                                <Text color={'#C6D1E2'} fontSize={'sm'} mt={-2.5}>{userData?.username ?? '-'}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Button
                                                className="grey-share-button"
                                                width={'fit-content !important'}
                                                fontSize={'sm'}
                                                leftIcon={<Image boxSize={'28px'} src={require('../../../assets/images/scan.png')} />}
                                                onClick={() => { _router.navigate(Pages._MY_QR_CODE, { isMemberReferral: true }); }}>
                                                {t('MY_QR')}
                                            </Button>
                                        </Flex>
                                    </CardBody>
                                </Stack>
                                <Stack style={{ background: '#131825', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px'}}>
                                    <CardBody>
                                        <Flex align={'center'}>
                                            <Image
                                                boxSize={'70px'}
                                                ml={-2}
                                                cursor={'pointer'}
                                                src={userData?.experienceDetail?.badgeFile}
                                                onClick={() => { _router.navigate(Pages._RANK_LIST); }} />
                                            <Stack>
                                                <Badge colorScheme='twitter' w='fit-content' borderRadius='20px'>{t('BETA')}</Badge>
                                                <Text mt={-2}>{t('CURRENT_RANK_LEVEL')}</Text>
                                            </Stack>
                                            <Spacer />
                                            <Button variant={'link'} onClick={() => {
                                                _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/ranking?lang=${languageCode}` });
                                            }}>
                                                <Image
                                                    boxSize={'28px'}
                                                    src={require('../../../assets/images/home-question-after-click.png')}
                                                />
                                            </Button>
                                            <Button
                                                className="grey-share-button"
                                                fontSize={'sm'}
                                                paddingBlock={1}
                                                style={{ width: 'fit-content', marginLeft: '0.2rem', height:'fit-content' }}
                                                onClick={() => {
                                                    _router.navigate(Pages._RANK_HISTORY);
                                                }}>
                                                {t('HISTORY')}
                                            </Button>
                                        </Flex>
                                        <Stack mt={2}>
                                            <Flex>
                                                <Text as='b'>{t(userData?.experienceDetail?.rankName)}</Text>
                                                <Spacer />
                                                {
                                                    data.downgradable && data.dayLeft <= data.maxInactivityDayToShow &&
                                                    <Popover>
                                                        <PopoverTrigger>
                                                            <Button variant={'link'} justifyContent={'flex-end'} onClick={() => { }}>
                                                                <img width={'20px'} height={'20px'} src={require('../../../assets/images/svg/general-icon-alert.svg').default} />
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="app-alert-popover">
                                                            <PopoverArrow color={'transparent'} />
                                                            <PopoverBody>
                                                                <Text as='b' color='#AACBFF' fontSize={'sm'}>{t('INACTIVITY_ALERT')}</Text> <br />
                                                                <Text>{t('PENALTY_DEDUCTION_OF_EXP_IN_MORE_DAYS', { days: data.dayLeft })}</Text>
                                                            </PopoverBody>
                                                        </PopoverContent>
                                                    </Popover>
                                                }
                                            </Flex>
                                            <Stack direction={'row'} gap={0}>
                                                <Progress className="experiance-bar" w={'full'} borderStartRadius={20} value={expBarValue[0]} />
                                                <Progress className="experiance-bar" w={'full'} value={expBarValue[1]} />
                                                <Progress className="experiance-bar" w={'full'} value={expBarValue[2]} />
                                                <Progress className="experiance-bar" w={'full'} borderEndRadius={20} value={expBarValue[3]} />
                                            </Stack>
                                            <Flex mb={2.5}>
                                                <Text fontSize={'sm'}>{numberWithCurrencyFormat((userData?.experienceDetail?.experience ?? 0), 2, false) + ' ' + t('EXP')}</Text>
                                                <Spacer />
                                                <Text fontSize={'sm'}>
                                                    {userData?.nextRankDetail
                                                        ? numberWithCurrencyFormat(userData?.nextRankDetail?.minimumExperience, 2, false) +
                                                        ' ' +
                                                        t('EXP')
                                                        : t('MAX')}
                                                </Text>
                                            </Flex>
                                            <Text fontSize={'sm'} color={'app.blue'} as='i'>{t('EARN_MORE_EXP_TO_UPGRADE')}</Text>
                                            {
                                                _BOTTOM_BUTTOM.map((item, index) => {
                                                    return (
                                                        <HStack cursor={'pointer'} onClick={() => settingActionHandler(item.title)}>
                                                            <Image boxSize={'30px'} src={item.icon} />
                                                            <Text fontSize={'sm'} color={'#BBC7DB'}>{t(item.title)}</Text>
                                                        </HStack>
                                                    )

                                                })
                                            }
                                            <Button mt={4} fontWeight={'bold'} borderRadius={15} backgroundImage={"linear-gradient(#e5b823, #8d7216)"} color={'white'} paddingBlock={6} onClick={signOutHandler}>
                                                {t('SIGN_OUT')}
                                            </Button>

                                        </Stack>


                                    </CardBody>
                                </Stack>
                            </Card>
                        </Stack>                               
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    HStack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Center
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../hooks/useRouter";
import { showCustomDialog } from "../../application/action/app_action";
import GeneralTopBar from "../../component/general-top-bar";
import { ApiKey, BG_TYPE, ValidationPattern, _LANGUAGE_OPTION, _RECEIPT_WATERMARK, ScreenWidth } from "../../common/constant";
import { useForm, Controller } from 'react-hook-form';
import CustomInput from "../../component/input";
import ApiEngine from "../../common/api_engine";
import Routes from "../../common/api_routes";
import { checkBrowser, isObjectEmpty, stringIsNullOrEmpty } from "../../common/util";
import { isMobile } from "react-device-detect";
import { Pages } from "../../routes/page";
import Footer from "./footer";
import Header from "./header";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { control, handleSubmit, errors, setValue, clearErrors, watch } = useForm();
    const { i18n, t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const doPasswordMatch = (value) => {
        if (value) {
            clearErrors(["confirmNewPassword", "newPassword"]);
            return true;
        }

        return false;
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            window.scrollTo(0, 0);
            var responseJson = await ApiEngine.get(`${Routes._FORGET_PASSWORD_URL}?phonenumber=${data.phoneNo}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            if (responseJson[ApiKey._API_DATA_KEY] == null || isObjectEmpty(responseJson[ApiKey._API_DATA_KEY])) {
                _dispatch(showCustomDialog({
                    success: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: responseJson[ApiKey._API_MESSAGE_KEY],
                    onConfirm: () => {
                        _router.back();
                    }
                }));
            }
            else {
                _dispatch(showCustomDialog({
                    success: false,
                    content: t('RESET_PASSWORD_ATTEMPT_CHARGE', { attempt: responseJson[ApiKey._API_DATA_KEY].attempt, charge: responseJson[ApiKey._API_DATA_KEY].charge }),
                    onConfirm: () => {
                        resetPasswordWithCharges(data.phoneNo);
                    },
                    onCancel: () => { },
                    horizontalBtn: false
                }));
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    async function resetPasswordWithCharges(phoneNumber) {
        var responseJson = await ApiEngine.get(`${Routes._FORGET_PASSWORD_URL}?phonenumber=${phoneNumber}&chargeConfirmation=true`);

        _dispatch(showCustomDialog({
            success: responseJson[ApiKey._API_SUCCESS_KEY],
            content: responseJson[ApiKey._API_MESSAGE_KEY],
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _router.back();
                }
            }
        }));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('phoneNo')));
    }

    if (!checkBrowser()) {
        return (
            <div >
                <GeneralTopBar bgColor="black" />
                <Flex align={'center'} mb={5} paddingInline={5}>
                    <Text as='b' fontSize={'2xl'}>{t('FORGOT_PASSWORD')}</Text>
                    <Spacer />
                    <Box className="settings-icon-box">
                        <Image boxSize={'50px'} src={require('../../assets/images/security/password.png')} />
                    </Box>
                </Flex>
                <Stack paddingInline={5}>
                    <Text color={'#C6D1E2'}>{t("NOTE")}: {t("ENTER_YOUR_MOBILE_NUMBER_AND_WE_WILL_SEND_YOU_TEMP_PIN")}</Text>
                    <Card className="grey-box">
                        <CardBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Controller
                                    control={control}
                                    name="phoneNo"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            inputMode='tel'
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label='ENTER_MOBILE_NUMBER'
                                            placeHolder={t('ENTER_MOBILE_NUMBER')}
                                            inputType='tel'
                                            errors={errors}
                                            patternErrorTxt={t('PLEASE_FILL_VALID_PHONE_NO')} />
                                    )}
                                    rules={{ required: true, pattern: ValidationPattern._PHONE_NUMBER_REGEX }}
                                />
                                <Button className="grey-button" type="submit">{t('SUBMIT')}</Button>
                            </form>
                        </CardBody>
                        <Divider className="app-divider" />
                        <CardFooter justify={'center'}>
                            <Text color={'app.grey'}>{t('ALREADY_HAVE_AN_ACCOUNT')}</Text>
                            <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.back() }}><Text color={'app.blue'} decoration='underline'>{t('SIGN_IN_NOW')}</Text></Button>
                        </CardFooter>
                    </Card>
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                
                <Center margin="100px auto 0 auto">
                    <HStack spacing='100px'>
                        <div>
                            <Image boxSize='250px' objectFit='contain' src={require('../../assets/images/logo.png')} alt='BETMAN' />
                        </div>
                        <div>
                            <Text fontSize='2xl' fontWeight={'bold'} marginBottom={"5px"}>{t('FORGOT_PASSWORD?')}</Text>
                            <Text fontSize='md' color={"white"} marginBottom={"35px"}>{t('ENTER_YOUR_MOBILE_NUMBER_AND_WE_WILL_SEND_YOU_TEMP_PIN')}</Text>
                            <Card border="1px solid white" borderRadius="15px" backgroundColor={'#232E46'} padding="12px 5px" minWidth="480px">
                                <CardBody>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    inputMode='tel'
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    label='ENTER_MOBILE_NUMBER'
                                                    inputType='tel'
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    marginBottom="10px"
                                                    errors={errors}
                                                    patternErrorTxt={t('PLEASE_FILL_VALID_PHONE_NO')} />
                                            )}
                                            rules={{ required: true, pattern: ValidationPattern._PHONE_NUMBER_REGEX }}
                                        />
                                        <Button style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#303e5d", color: "white", padding: "25px 0px" }} w='100%' type='submit'>{t('RESET_PASSWORD')}</Button>
                                    </form>
                                </CardBody>
                                <CardFooter justify={'center'}>
                                    <Text color={'app.grey'}>{t('ALREADY_HAVE_AN_ACCOUNT')}</Text>
                                    <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._LOGIN) }}><Text color={'app.blue'} decoration='underline' fontWeight='normal' >{t('LOGIN_NOW')}</Text></Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </HStack>
                </Center>
                <Footer />
            </>
        )
    }
}
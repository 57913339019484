import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../../hooks/useRouter";
import {
    Stack,
    Text,
    Image,
    Spacer,
    HStack,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    useDisclosure,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import { Pages } from "../../routes/page";
import { _LANGUAGE_OPTION, _RECEIPT_WATERMARK, ApiKey, Language } from "../../common/constant";
import { ConfigEnum } from "../../common/config";
import ApiEngine from "../../common/api_engine";
import Routes from "../../common/api_routes";
import Marquee from "react-fast-marquee";
import { useOrientation } from 'react-use';
import { checkBrowser, stringIsNullOrEmpty } from "../../common/util";

export default () => {
    var _router = useRouter();
    const { t, i18n } = useTranslation();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const _ALL_CATEGORY = { id: -1, title: t('ALL') };
    const [vendorCategories, setVendorCategories] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [selectedVendorCategory, setSelectedVendorCategory] = useState(_ALL_CATEGORY.id);
    const { type } = useOrientation();
    const [showTNC, setShowTNC] = useState(false);
    const [showPolicy, setShowPolicy] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(Routes._ANONYMOUS_GET_VENDOR_CATEGORY);

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let tempVendorCategoryList = responseJson[ApiKey._API_DATA_KEY][
                        'data'
                    ].filter(v => isMobile ? v.id : v.displayAtDesktop).map((i) => ({ id: i.id, title: i.translatedName, localizations: i.localizations }));
                    setVendorCategories([
                        { id: -1, title: t('ALL') },
                        ...tempVendorCategoryList
                    ]);
                }
            }
            catch (err) {
                // do nothing
            }
        })();
    }, [isMobile])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(`${Routes._ANONYMOUS_GET_VENDOR}?categoryId=${selectedVendorCategory}`);

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setVendorList(responseJson[ApiKey._API_DATA_KEY]['data']);
                }
            }
            catch (err) {
                // do nothing
            }
        })();
    }, [selectedVendorCategory])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function onChangeLanguage(language) {
        i18n.changeLanguage(language);
        localStorage.setItem(ConfigEnum._LANGUAGE, language);
        onClose();
    }

    return (
        <>
            <Stack marginInline={!checkBrowser() ? '-25px' : ''}>
                <Stack className="app-top-bar" padding={['15px', '25px']}>
                    <Stack direction={'row'} gap='2' align={'center'}>
                        <Image boxSize={['45px', '70px']} src={require('../../assets/images/logo.png')} />
                        {
                            !isMobile &&
                            <>
                                <Button color={'white'} onClick={() => { _router.navigate(Pages._REGISTER) }}>{t('HOME')}</Button>
                                <Button color={'white'} onClick={() => { _router.navigate(Pages._REGISTER) }}>{t('GAMES')}</Button>
                                <Button color={'white'} onClick={() => { _router.navigate(Pages._REGISTER) }}>{t('TRANSACTION')}</Button>
                                <Button color={'white'} onClick={() => { _router.navigate(Pages._REGISTER) }}>{t('BONUS')}</Button>
                                <Button color={'white'} onClick={() => { _router.navigate(Pages._REGISTER) }}>{t('RELOAD_SERVICE')}</Button>
                            </>
                        }
                        <Spacer />
                        <Button className="app-top-bar-button" color={'white'} onClick={() => _router.navigate(Pages._LOGIN)}>{t('LOGIN')}</Button>
                        <Popover
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}>
                            <PopoverTrigger>
                                <Button className="app-top-bar-button" color={'white'}>
                                    {_LANGUAGE_OPTION.find(l => l.value == localStorage.getItem(ConfigEnum._LANGUAGE))?.prefix}
                                    <img src={require('../../assets/images/svg/white-arrow.svg').default} style={{ transform: 'rotate(90deg)', marginLeft: '5px' }} />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="app-popover" width={'max-content'}>
                                <PopoverArrow />
                                {
                                    _LANGUAGE_OPTION.map((item, index) => {
                                        if (item.value != localStorage.getItem(ConfigEnum._LANGUAGE)) {
                                            return (
                                                <PopoverHeader key={index} textAlign={'right'} onClick={() => onChangeLanguage(item.value)}>
                                                    <Text>{item.label}</Text>
                                                </PopoverHeader>
                                            )
                                        }
                                    })
                                }
                            </PopoverContent>
                        </Popover>
                    </Stack>
                </Stack>
                <Stack mt={!checkBrowser() ? ['15px', '65px'] : '28'}>
                    <Carousel showArrows={false} showThumbs={false} showStatus={false} interval={10000} infiniteLoop autoPlay emulateTouch>
                        <div>
                            <Image src={require(`../../assets/images/banner/1-${stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE)) ? Language._ENGLISH : localStorage.getItem(ConfigEnum._LANGUAGE)}.png`)} />
                        </div>
                        <div>
                            <Image src={require(`../../assets/images/banner/2-${stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE)) ? Language._ENGLISH : localStorage.getItem(ConfigEnum._LANGUAGE)}.png`)} />
                        </div>
                    </Carousel>
                </Stack>
            </Stack>
            <Stack marginInline={[0, 150]}>
                <HStack marginBlock={'25px'} overflowX={'auto'}>
                    {
                        vendorCategories.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    color={'white'}
                                    width={'full'}
                                    className={`landing-page-category-button${selectedVendorCategory == item.id ? '-selected' : ''}`}
                                    onClick={() => setSelectedVendorCategory(item.id)}>
                                    {item.id > _ALL_CATEGORY.id ?
                                        vendorCategories[index]?.localizations?.find(l => l.languageId == localStorage.getItem(ConfigEnum._LANGUAGE))?.value :
                                        t(item.title)}
                                </Button>
                            )
                        })
                    }
                </HStack>
                <SimpleGrid columns={[3, 4]} spacing={[2.5, 5]}>
                    {
                        vendorList.map((item, index) => {
                            return (
                                <div key={index} className="landing-page-game-box">
                                    <Stack align={'center'} p={3}>
                                        <HStack w={'full'} justify={'center'}>
                                            {
                                                isMobile && item.gameName.length > 12 ?
                                                    <Marquee style={{ justifyContent: 'center' }}>
                                                        <Text fontSize={[12, 13, 14]} as={'b'}>{item['gameName']} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                                                    </Marquee> :
                                                    <Text fontSize={[12, 13, 14]} as={'b'}>{item['gameName']}</Text>
                                            }
                                        </HStack>
                                        <Image boxSize={['80px', '100px']} src={item.imagePath} />
                                        <Stack className={`neon-wrapper neon-button-1`}>
                                            <Button size={['xs', 'md']} color={'white'} background={'black'} onClick={() => { _router.navigate(Pages._REGISTER) }}>
                                                {t('PLAY_NOW')}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </div>
                            )
                        })
                    }
                </SimpleGrid>
                <HStack mt={120} align={'end'} mb={10}>
                    <Stack>
                        <Image boxSize={['45px', '70px']} src={require('../../assets/images/logo.png')} />
                        <Text as='u' color={'#C6D1E2'} width={'max-content'} onClick={() => setShowTNC(!showTNC)}>{t('TERM_CONDITIONS')}</Text>
                        <Spacer />
                        <Text as='u' color={'#C6D1E2'} width={'max-content'} onClick={() => setShowPolicy(!showPolicy)}>{t('PRIVACY_POLICY')}</Text>
                        <Spacer />
                        <Text color={'#C6D1E2'}>{`${t("COPYRIGHT")} © 2024 ${_RECEIPT_WATERMARK}. ${t('ALL_RIGHT_RESERVED')}`}</Text>
                    </Stack>
                    <Spacer />
                    <Stack>
                        <Text color={'#FAC926'} as={'b'}>{t('PAYMENT_METHOD')}</Text>
                        <SimpleGrid columns={[3, 4]} spacing={[2.5, 5]}>
                            <Image boxSize={'50px'} src={require('../../assets/images/payment-method/fpx.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/transaction/ewallet-tng.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/transaction/ewallet-grabpay.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/transaction/ewallet-boost.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/transaction/ewallet-shopee.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/payment-method/maxis.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/payment-method/celcom.png')} />
                            <Image boxSize={'50px'} src={require('../../assets/images/payment-method/digi.png')} />
                        </SimpleGrid>
                    </Stack>
                </HStack>
            </Stack>
            <Modal
                isOpen={showTNC}
                scrollBehavior={'inside'}
                size={'full'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('TERM_CONDITIONS')}</ModalHeader>
                    <ModalBody backgroundColor={'black'}>
                        <Text as={'b'}>Terms of Use</Text>
                        <Text>By accessing or logging into Betman's gaming platform via websites (including sub-domains or any other related URLS) that are registered by Betman, you hereby agree to be bounded by, understood and accepted the full Terms of Use as outlined and interpreted below. The Terms of Use shall be used as the core reference, which prevails at all times in avoiding any potential disputes between Betman and its members.</Text>
                        <Text>Please read the Terms of Use entirely prior to registering your new Betman account.</Text>
                        <Text as={'u'}>General</Text>
                        <Text>1. You hereby fully understand and agree to be bounded by the Terms of Use outlined herein and maybe to be amended by us at any time without any prior notice or consent.</Text>
                        <Text>2. It is your responsibility to read, review and understand the Terms of Use and its Game Rules for each and every gaming product (as defined by the Game Provider) that you have chosen to play. Betman reserves the right to add and remove any games from its platform without any prior notice or consent.</Text>
                        <Text as={'u'}>Member's Account</Text>
                        <Text>1. You must be over the local legal age before proceeding to any activities on Betman. We reserve the right to carry out further KYC (Know Your Customer) verification on you for proof of age and suspend your member account until satisfactory supporting document is provided.</Text>
                        <Text>2. You hereby agree, acknowledge and consent to Betman to process your personal information and data from the submission of member account registration for the purpose of allowing you to access and participate in the products, games, promotions and payment gateway solutions that are offered in Betman's platform.</Text>
                        <Text>3. It is your responsibility to ensure accurate, relevant and up-to-date personal details and other related information such as mobile number and bank account details are filled in and updated into your member account. Non-compliance shall be deemed as a breach on Terms of Use and Betman reserves the sole right to suspend your member account for further investigation, immediate closure of your member account or even forfeiting the available balance in your member account. Non-compliance can also result possible delay on the processing of withdrawal request.</Text>
                        <Text>4. You are only allowed to have one registered member account on Betman. In the event that you are having more than one member account, Betman reserves the sole right to suspend your member account for further investigation, immediate closure of your member account or even forfeiting the available balance in your member account (if found that you have created multiple member accounts with ill-intentions to gain unreasonable advantage from Betman).</Text>
                        <Text>5. You are responsible for the confidentiality of your member account and password. You must not allow any other person or third party to use your member account. Bets placed and accepted via your member account will be regarded as valid bets. We suggest you to frequently update your password. Betman will not take responsibility or make compensation to you if your member account is stolen due to our personal negligence.</Text>
                        <Text>6. It is your responsibility to actively maintain your member account with us. You have to at least log in once to your member account and utilized our services in any six (6) months period to maintain an active account. If your member account is inactive for six (6) consecutive months or longer, we reserve the right to close your member account. Remaining funds that may still be available for withdrawal for an inactive, meanwhile for a permanently closed member account, remaining funds will be forfeited and set aside for distribution to a nominated charity organization chosen by Betman.</Text>
                        <Text>7. The confidentiality and high level security of your personal data is our utmost priority. Betman commits and provides high assurances that your information remains safe with us. Betman has invested into the latest firewall technologies to ensure the delivery of our security commitment. The data transfer processing and storage in our servers undergo a series of high-tech encryption technologies; having a strict proficiency in protecting your data from being misused, lost or assessable to the general public.</Text>
                        <Text as={'u'}>Deposits & Withdrawals</Text>
                        <Text>1. All deposits made to Betman will be credited to your member account after the payment has been received, processed and verified. You can make deposit into your member account at any time.</Text>
                        <Text>2. If you are using a deposit method to your member account in respect of which you are not the account holder, Betman reserves the right to deem such deposit into your member account as being invalid (and any winnings arising from such deposit as void).</Text>
                        <Text>3. For your convenience, deposits can be made into your member account via the pre-approved built-in Authorized Payment Solutions available in the Betman's platform. You can also perform deposit into your member account via manual direct transfer into Betman's banking account and submit your deposit slips to Betman for further processing and verification.</Text>
                        <Text>4. It is important to note that, without having an official written endorsement or consent letter from Betman, the assigned Authorized Payment Solutions providers do not have the authorization to represent Betman by any means such as to receive funds on behalf of Betman and cannot in any way market, advertise, publicize or promote Betman and its services. Betman will not be responsible and does not accept any liability or indemnity claim of such.</Text>
                        <Text>5. Should funds be credited to your member account in error, it is your responsibility to inform Betman immediately without any delay. Betman reserves the right to void bets placed using funds that are credited incorrectly to your member account and to recover such funds via manual adjustment at any time.</Text>
                        <Text>6. There are limits and controls on the number of times for deposits and withdrawals that can be performed on the same day. You are not allowed to submit more than three (5) withdrawal requests in a day. You should therefore avoid sending duplicate requests repeatedly, as it will be counted into the daily limit.</Text>
                        <Text>7. The daily withdrawal limit is capped at RM50,000 (Ringgit Malaysia: Fifty Thousand Only).</Text>
                        <Text>8. A turnover of at least one (1) time on the value of deposit needs to be reached or achieved prior to a withdrawal request can be submitted.</Text>
                        <Text>9. Betman reserve the right, if deemed applicable or necessary, to charge a handling fee (at a fair and justifiable market rate) on the submitted withdrawal requests, based on the transaction costs as imposed by the selected third party payment gateway service provider.</Text>
                        <Text>10. Withdrawal of money from your member account may only be made in the same currency in which deposits were made. Moreover, withdrawal can only be made in the name of and to the registered account holder. No withdrawal cancellation can be submitted thereafter from you if the withdrawal transaction has already been made and on the way to your requested bank account.</Text>
                        <Text>11. All winnings from settled bets will be credited back to the balance of your member account and shall be withdrawn in accordance with Betman's Terms of Use.</Text>
                        <Text>12. Betman reserves the right to carry out random identification check or KYC (Know Your Customer) verification as a due diligence effort in respect to your withdrawal request, especially for transactions of large sum.</Text>
                        <Text>13. Betman reserves the right to charge you the relevant amounts incurred if there any charge-backs, reversals or other charges were incurred in respect to your member account. For the avoidance of doubt, your member account must not be used by you for personal fund management purposes, such as siphoning of funds by manipulating both the deposit and withdrawal transactions that is not being supported with a justifiable gaming activity. Betman reserves the sole right to deduct an administration charge, suspend your member account for further investigation, immediate closure of your member account or even forfeiting the available balance in your member account should such activity comes into Betman's alert and attention.</Text>
                        <Text as={'u'}>Management of Betting Transactions</Text>
                        <Text>1. Betman is solely a gaming platform that consolidates popular and hot online games available in the market offered by various game providers.</Text>
                        <Text>2. All wagers are binding; if a wager is placed or a hand is played using your member account; it will be considered to have been made by you, therefore valid and cannot be cancelled.</Text>
                        <Text>3. Accepted bets with a final confirmation note or a Ticket ID on a specific round will stand and continue even if the member subsequently logs out or is disconnected for any reason from the game, such as poor connection, connection cut-off and so on.</Text>
                        <Text>4. A confirmation note or a Ticket ID represents a valid bet. In the event a confirmation note or a Ticket ID is not generated for a submitted bet, in indicates bets placed on our games were not successfully submitted, the bet will be considered as invalid.</Text>
                        <Text>5. You are not allowed to cancel or change an existing bet that has been placed, accepted and recorded. And, Betman hold no responsibility to cancel the bet of such category. In the event you wish to escalate on the matter further, the Customer Service Representative can be contacted for investigation to be performed. A possible resolution for case closure shall be at Betman's sole and reasonable discretion.</Text>
                        <Text>6. Betman maintains a twelve (12) months records for all online transactions and betting activities for reconciliation purposes if necessary when disputes occur.</Text>
                        <Text>7. The game provider reserves its rights to cancel or void the affected draw or bet in the event of any technical glitch, malfunction of any sort, including, but not exclusively software, hardware or connection error and breakdown.</Text>
                        <Text>8. The game provider reserves its rights to cancel or void the affected draw or bet in the event of any suspicion that the player is committing an act of fraud, collusion or other illegal activities.</Text>
                        <Text>9. The game provider reserves its rights to cancel or void the affected draw or bet in the event of an error (due to incorrect input by the game provider's admin) or system failure, which results in incorrect odds being created and used for its game.</Text>
                        <Text>10. Every game provider is having and adhering to its maximum payout tolerance level and shall be solely responsible for all final and also the maximum payout amounts for each of its specific games. Betman has neither the rights nor control over the final payout amount by game provider.</Text>
                        <Text as={'u'}>Collusion & Abuse Activities</Text>
                        <Text>Should Betman discovers improper method (such as plug-in) or any illegal personal of group or syndicate betting activities causing unfair advantage or damage to Betman, we have the right of retention to cancel bets of such and may suspend, block or close your member account immediately and solely at Betman's absolute discretion.</Text>
                        <Text>Should Betman discovers any betting tickets or transactions that are found with irregular betting behavior (such as having ill-intention to manipulate and inflate the “Betting Turnover” unfairly through arbitrage betting combining different selections within the same event where the outcome of one will affect the other), we have the right of retention to cancel or void bets of such and may suspend, block or close your member account immediately and solely at Betman’s absolute discretion.</Text>
                        <Text>If company discovers any improper method (plug-in) or any illegal group gambling behavior that causes damage to the company, the company reserves the right to cancel illegal bets.</Text>
                        <Text>Should Betman discovers the misuse of Betman websites by introducing viruses, Trojans, worms, DDOS or any other harmful hardware or software technology, causing unfair advantage or damage to Betman, we have the right of retention to cancel bets of such and may suspend, block or close your member account immediately and solely at Betman's absolute discretion.</Text>
                        <Text>Other than that, due to the above, you hereby agree to hold harmless on Betman and fully indemnify for all the losses and damages suffered by Betman.</Text>
                        <Text>Should Betman receives notices from any Game Providers, alerting Betman on users involving in abnormal and arbitrage gaming activities, especially on blackjack in live casino or any other potential live casino games in which users can abuse or exploit, Betman reserves the right of retention to cancel bets of such. Speedy and immediate action will be taken solely at Betman’s absolute discretion. Betman will not take responsibility, does not accept any liability or indemnity claim and does not make compensation to users of such. In addition, Betman will not entertain on any appeal effort and user of such shall take its own responsibility on the committed act.</Text>
                        <Text as={'u'}>Handling On System Interruptions On Force Majuere</Text>
                        <Text>In case of any external factor that results poor internet connection, connection error, poor hardware, etc., which may cause differences between Betman's products and its database, the final results or outcomes shall be based on the latest recorded data as reflected in Betman's and also its game provider's database in determining the most accurate reference.</Text>
                        <Text>Under any circumstances of force majeure (such as natural disaster, hacking attack, etc.) which causes loss of data, please check Betman's announcement for further notice and update.</Text>
                        <Text as={'u'}>Administrations of Existing Term of Use</Text>
                        <Text>The Terms of Use as written in English shall be the accurately explained version. If there is any inconsistency between different languages, the English version shall be prevailing version and to be referred at all times.</Text>
                        <Text>Betman reserves the right to change any of the terms, conditions, policies, articles, jackpot rules and game rules at any time, without notice and in Betman's sole discretion. Any revision or amendment in the agreement shall take effect immediately once announced.</Text>
                        <Text as={'u'}>Customer Service</Text>
                        <Text>With the full coverage of 24-hours live Customer Service and Support that is available on 7-days per week; Betman's professionally trained and friendly Customer Service Representative is always ready to attend to any of your urgent queries; assisting you towards an efficient and effective resolution.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant={'outline'} colorScheme={'red'} onClick={() => setShowTNC(!showTNC)}>{t('CLOSE')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={showPolicy}
                scrollBehavior={'inside'}
                size={'full'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('PRIVACY_POLICY')}</ModalHeader>
                    <ModalBody backgroundColor={'black'}>
                        <Text as={'u'}>Overview</Text>
                        <Text>
                            This section basically outlines and explains on how i8.Live is collecting, using and handling your personal data on its platform; subsequently ensures all data circulations and usages adhere to a full compliance of i8.Live’s strict Privacy Policy that is under a highly secured and fully encrypted environment.
                        </Text>
                        <Text>
                            Betman.com therefore requires you to read through and understand the details of the below Privacy Policy carefully on how Betman.com uses and handles your personal data. By using or registering on Betman.com, you are deemed to have agreed and accepted the terms and conditions as outlined in this Privacy Policy. If you do not agree to accept and be bound by this Privacy Policy or any amendment thereto, kindly discontinue using the platform immediately.
                            Betman.com reserves the right, at any time, to make amendment to this Privacy Policy and it shall take into effect immediately when the contents are officially published on its platform.
                        </Text>
                        <Text as={'u'}>Data Collections</Text>
                        <Text>
                            Betman.com collects, store and maintain your up to date personal data, to enable us to identify your identity accurately and safeguard us from any potential possibility of identity replication in the platform. Thus, this allows Betman.com to correspond confidently with the correct and genuine customer at all times.
                        </Text>
                        <Text>The data that Betman.com collects may include, but is not limited to:</Text>
                        <Text>1. Your name, email address, phone number, date of birth and any other possible mandatory or optional details as may be required for new account registration;</Text>
                        <Text>2. Information related your bank accounts as may be required for the processing of withdrawal request on the available game credits in Betman.com’s wallet;</Text>
                        <Text>3. Records and histories of your gameplay activities on Betman.com as may be required for transaction reconciliations;</Text>
                        <Text>4. Records of your correspondences with Betman.com’s Customer Service Representatives via any possible communication channels such as phone, emails, live chats, social media, etc. These records may also be used by Betman.com; being compiled, analyzed and studied into various analytics and trends, later to be used as a constructive reference for Betman.com continuous business process improvement effort;</Text>
                        <Text>5. Any data you may provide while registering or participating on any marketing promotion or event that is organized and administered under Betman.com;</Text>
                        <Text>6. Records and histories of your referral profiles settings and assignments of downlines on Betman.com, as may be required processing of commission payouts;</Text>
                        <Text>7. Ad-hoc request of any other possible and sensible personal or supporting data to facilitate Betman.com’s verification effort, as may be required for its compliance initiatives.</Text>
                        <Text as={'u'}>Use of Your Personal Data</Text>
                        <Text>By using or registering on Betman.com, you are deemed to have acknowledged and given Betman.com your consent to use and process your personal data, as maybe deemed sensitive, for any administration of transactions that are related to your Betman.com’s account. Your personal data may be used by Betman.com internally or may be sent to a third party company or vendor that processes it on behalf of Betman.com.</Text>
                        <Text as={'u'}>Data Security & Confidentiality</Text>
                        <Text> The confidentiality of customers’ data is the utmost priority. Betman.com commits and provides high assurances that your information remains safe with us. We have invested into the latest Firewall technologies available to date to ensure the delivery of our security commitment. All data transfer processing and storage in our server undergoes a series of high-tech encryption technologies in order to protect your data from being misused and/or lost.</Text>
                        <Text>With the securities of all technologies via the internet is never completely secure and might still expose to some level of risk of being hacked, Betman.com cannot pledge or guarantee the security of any information that you have provided to Betman.com during the use of our platform. Therefore, by using or registering on Betman.com, you are deemed to have understand the possible security implications that may arise while using the internet and agree that Betman.com accept no liability from you for any potential losses and damages in result of such occasion.</Text>
                        <Text>Betman.com shall keep and hold your personal data in our platform for as long as your account remains active, while you continue using the services in the platform. Should your account remains idle for a period of more than twelve (12) month, Betman.com reserves its absolute the rights to deactivate your account temporarily or permanently if necessary and subsequently remove your data in the event the purpose of use has been met.</Text>
                        <Text as={'u'}>Cookies</Text>
                        <Text>A cookie is a piece of data from a website that is stored within a web browser, in which the website can retrieve at a later time. Cookies are used to tell the server that users have returned to a particular website, hence Betman.com is using it to keep track on the browsing patterns of its customer. This subsequently allows Betman.com to understand its customers’ behavior and preference better while they are accessing to the platform. The collected data is translated into effective analytics for Betman.com’s reference so that we are able to improve our service from time to time for better user experience, as a part of our continuous improvement strategies.</Text>
                        <Text>Therefore, by using or registering on Betman.com, you are deemed to have noted that your personal data maybe collected and stored in Betman.com via Cookies (only when you have accepted feature). You are not obligated to accept cookies from Betman.com and you can always opt to decline or disable the cookie feature. Without the consent of such, Betman.com may not be able to collect certain data of yours and may not be able to provide you with some or all of our services that are normally available in Betman.com.</Text>
                        <Text as={'u'}>Marketing & Promotion Activities</Text>
                        <Text>While having an active account with Betman.com, you may receive marketing and promotion communications from us. Betman.com does engage third party vendors, who are also our business counterparts to assist in these marketing and promotion campaigns, thus Betman.com may share your personal data to them. Our business counterparts are fully committed to strictly adhere and comply to Betman.com data privacy and security policy.</Text>
                        <Text>Should you wish to opt out from these activities, kindly contact our Customer Service Representative of Betman.com via the website’s or platform’s Live Chat section.</Text>
                        <Text as={'u'}>Winnings</Text>
                        <Text>Betman.com reserves its absolute rights to publish news and promotional materials related to any of your wins and/or prizes received, on its platform or any other social media websites that are related to Betman.com. Such publish contents shall not be limited to your account names, the winning amount and any other possible related information that can be associated to the promotional campaign for further creating marketing awareness on Betman.com’s brand name.</Text>
                        <Text as={'u'}>Comments & Concerns</Text>
                        <Text>For any queries, comments or feedbacks about this Privacy Policy, kindly contact our Customer Service Representative of Betman.com via the website’s or platform’s Live Chat section.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant={'outline'} colorScheme={'red'} onClick={() => setShowPolicy(!showPolicy)}>{t('CLOSE')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
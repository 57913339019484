import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Stack,
    Flex,
    Text,
    Button,
    Image,
    Spacer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    Tabs,
    TabList,
    Tab,
    Divider,
    SimpleGrid,
    Center,
    Box,
    Spinner,
    AbsoluteCenter,
    HStack
} from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import ApiEngine from "../../../common/api_engine";
import axios from 'axios';
import { ApiKey, _MAXIMUM_PLAYERS, Status, Language, ScreenWidth } from "../../../common/constant";
import Routes from "../../../common/api_routes";
import { stringIsNullOrEmpty, isObjectEmpty, createFormBody, numberWithCurrencyFormat, copyToClipboard, share, checkIsStateRangeValid, getUserCurrentLocation, checkBrowser } from "../../../common/util";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog } from "../../../application/action/app_action";
import BottomSheet from "../../../component/bottom-sheet";
import { Pages } from "../../../routes/page";
import { isBrowser, isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { Carousel } from 'react-responsive-carousel';
import { ConfigEnum } from "../../../common/config";


/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _userData = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const _ALL_CATEGORY = { id: -1, title: t('ALL') };
    const [announcementText, setAnnouncementText] = useState(t('WELCOME_TO_EPAY_ENJOY_THE_CONVENIENCE'));
    const [vendorCategories, setVendorCategories] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [selectedVendorCategory, setSelectedVendorCategory] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isVendorListLoading, setIsVendorListLoading] = useState(true);
    const [isPlayerListLoading, setIsPlayerListLoading] = useState(false);
    const [players, setPlayers] = useState([]);
    const [playersQueue, setPlayersQueue] = useState([]);
    const [selectedPlayerUsername, setSelectedPlayerUsername] = useState(null);
    const [goToPlayerDetail, setGoToPlayerDetail] = useState(null);
    const [addPlayer, setAddPlayer] = useState(false);
    const cancelTokenSource = axios.CancelToken.source();
    const _POPOVER_CONTENT = [
        {
            icon: require('../../../assets/images/svg/reward-icon.svg').default,
            title: 'SUPPORT_TURNOVER',
            description: 'WILL_BE_CALCULATED_BASED_ON_THE_INTERVAL_SET_BY_EACH_OF_THE_GAME_VENDORS'
        },
        {
            icon: require('../../../assets/images/svg/high-payout-icon.svg').default,
            title: 'HIGH_PAYOUT',
            description: 'WILL_GAIN_EXTRA_PAYOUT_WHEN_YOU_BET',
            subDescription1: 'FOR',
            subDescription2: 'LOTTO'
        },
        {
            icon: require('../../../assets/images/svg/high-rebate-icon.svg').default,
            title: 'HIGH_REBATE',
            description: 'WILL_REBATES_FOR_YOUR_BETS',
            subDescription1: 'FOR',
            subDescription2: 'LOTTO'
        },
        {
            icon: require('../../../assets/images/svg/commission-icon.svg').default,
            title: 'HIGH_COMMISSION',
            description: 'WILL_GET_REWARDED_FROM_YOUR_DOWNLINE_SALE'
        },
        {
            icon: require('../../../assets/images/svg/manual-login.svg').default,
            title: 'AUTO_LOGIN',
            description: 'AUTO_LOGIN_DESCRIPTION',
            largeIcon: true
        }
    ]
    const [isLocationRestricted, setIsLocationRestricted] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if(!checkBrowser()){
            _navigate(Pages._DEFAULT);
        }

        getVendorCategories();
    }, [isMobile]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            if (_userData?.is_location_restricted) {
                setIsLocationRestricted(true);
            }
            else {
                setIsLocationRestricted(false);
            }
        })();
    }, [_userData?.is_location_restricted]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (vendorCategories?.length) {
            getVendorList();
        }
    }, [vendorCategories]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!isFirstLoad) {
            getVendorList();
        }
    }, [selectedVendorCategory]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        // If vendor not manual read score, queue players to get details
        if (!isObjectEmpty(selectedVendor)) {
            let vendorPlayers = selectedVendor.players;

            let tempVendorPlayers = vendorPlayers.map((item) => ({
                ...item,
                isSameRegion: true
            }));

            setPlayers(tempVendorPlayers);
            setPlayersQueue(vendorPlayers.map((i) => i.username));
        } else {
            setPlayers([]);
            setPlayersQueue([]);

            if (selectedPlayerUsername) {
                // Cancel ongoing request
                cancelTokenSource.cancel();
                setSelectedPlayerUsername(null);
            }
        }
    }, [selectedVendor]);

    /// <summary>
    /// Author: Saitama
    /// Navigate to player detail when new player is added
    /// </summary>
    useEffect(() => {
        if (goToPlayerDetail) {
            let playerDetail = players.find((i) => i.id == goToPlayerDetail);

            if (playerDetail) {
                setAddPlayer(false);
                _router.navigate(Pages._PLAYER_DETAIL, {
                    playerDetail: playerDetail,
                    selectedVendor: selectedVendor,
                    vendorList: vendorList,
                })
                setGoToPlayerDetail(null);
            }
        }
    }, [goToPlayerDetail, players.length]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        // If no player in queue selected, get the first item from queue to retrieve player details
        if (playersQueue.length > 0 && selectedPlayerUsername == null) {
            getPlayerDetail(playersQueue[0]);
        }
    }, [playersQueue.length, selectedPlayerUsername]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function queuePlayer(username) {
        let tempPlayersQueue = [...playersQueue];

        if (!tempPlayersQueue.includes(username)) {
            tempPlayersQueue.push(username);
            setPlayersQueue(tempPlayersQueue);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getPlayerDetail(username) {
        try {
            setSelectedPlayerUsername(username);
            setIsPlayerListLoading(true);
            let responseJson = await ApiEngine.get(
                `${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${username}`,
                {
                    cancelToken: cancelTokenSource.token,
                    headers: { skipLoading: true }
                }
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                const { score, disable, isSameRegion } =
                    responseJson[ApiKey._API_DATA_KEY][0];
                let tempPlayers = players;

                // update player score
                tempPlayers.find((i) => i.username == username)['score'] =
                    score;
                tempPlayers.find((i) => i.username == username)['status'] =
                    disable ? Status._DISABLED : Status._ENABLED;
                tempPlayers.find((i) => i.username == username)[
                    'isSameRegion'
                ] = isSameRegion;
                setPlayers(tempPlayers);
            }
        } finally {
            setPlayersQueue(playersQueue.filter((i) => i != username));
            setSelectedPlayerUsername(null);
            if (playersQueue.length == 1) {
                setIsPlayerListLoading(false);
            }
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function addNewPlayer() {
        try {
            setAddPlayer(true);
            let params = {
                Id: _userData.guid,
                VendorId: selectedVendor.id
            };

            let formBody = createFormBody(params);
            let responseJson = await ApiEngine.post(
                Routes._ADD_PLAYER,
                formBody
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(
                    showCustomDialog({
                        success: true,
                        headerTitle: t('NEW_PLAYER_ADDED'),
                        customContent: (
                            <Center>
                                <Stack>
                                    <Center>
                                        <Image boxSize='160px' mb={1.5} src={require('../../../assets/images/graphic-newplayer.png')} />
                                    </Center>
                                    <Text fontWeight={'bold'}>{t('YOU_VE_ADDED_PLAYER', { vendor: selectedVendor.name })}</Text>
                                    <Center>
                                       <Stack direction='row' align='center'>
                                        <Text>{t('PLAYER_ID') + ': ' + responseJson[ApiKey._API_DATA_KEY]['vendor_username']}</Text>
                                        <Button variant='link' onClick={() => {
                                            copyToClipboard(responseJson[ApiKey._API_DATA_KEY]['vendor_username'], t('COPIED_SUCCESFULLY'))
                                        }}>
                                            <Image boxSize='30px' src={require('../../../assets/images/copy.png')} />
                                        </Button>
                                    </Stack> 
                                    </Center>
                                    <Center>
                                       <Stack direction='row' align='center'>
                                        <Text>{t('PASSWORD') + ': ' + responseJson[ApiKey._API_DATA_KEY]['password']}</Text>
                                        <Button variant='link' onClick={() => {
                                            copyToClipboard(responseJson[ApiKey._API_DATA_KEY]['password'], t('COPIED_SUCCESFULLY'))
                                        }}>
                                            <Image boxSize='30px' src={require('../../../assets/images/copy.png')} />
                                        </Button>
                                    </Stack> 
                                    </Center>
                                    <Center>
                                        <Button
                                            className="grey-share-button"
                                            leftIcon={<Image boxSize='30px'
                                                src={require('../../../assets/images/share-btn.png')} />}
                                            onClick={() => {
                                                onShare(selectedVendor?.gameName, responseJson[ApiKey._API_DATA_KEY]);
                                            }}>
                                            <Text color={'#2A3247'}>{t('SHARE')}</Text>
                                        </Button>
                                    </Center>
                                </Stack>
                            </Center>
                        ),
                        confirmTxt: t('TRANSFER_AND_PLAY'),
                        onConfirm: () => {
                            setGoToPlayerDetail(
                                responseJson[ApiKey._API_DATA_KEY]['player_id']
                            );
                        },
                        cancelTxt: t('BACK_TO_HOME'),
                        onCancel: () => {
                            setAddPlayer(false);
                        },
                        horizontalBtn: true
                    })
                );
                getVendorList();
            } else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                    onConfirm: () => {
                        setAddPlayer(false);
                    }
                })
            );
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getAnnouncement() {
        var responseJson = await ApiEngine.get(Routes._GET_ANNOUNCEMENT, {
            headers: { skipLoading: true }
        });

        if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY].length > 0) {
            let tempStr = responseJson[ApiKey._API_DATA_KEY].map((a) => a.message).join('   ');
            setAnnouncementText(tempStr);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getVendorList = async () => {
        try {
            setIsVendorListLoading(true);
            let responseJson = await ApiEngine.get(
                `${Routes._GET_VENDOR_LIST}${selectedVendorCategory != null
                    ? '?categoryId=' + selectedVendorCategory
                    : ''
                }`,
                { headers: { skipLoading: true } }
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let tempVendorList = responseJson[ApiKey._API_DATA_KEY]['data'];
                setVendorList(tempVendorList);

                if (selectedVendor) {
                    setSelectedVendor(
                        tempVendorList.find((i) => i.id == selectedVendor.id)
                    );
                }

                if (!selectedVendorCategory) {
                    let categoryId =
                        responseJson[ApiKey._API_DATA_KEY].overallInfo
                            ?.categoryId;

                    setSelectedVendorCategory(
                        (categoryId ? categoryId : _ALL_CATEGORY.id).toString()
                    );
                }
            }
        } finally {
            setIsVendorListLoading(false);
            setIsFirstLoad(false);

            if (isFirstLoad) {
                getAnnouncement();
            }
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getVendorCategories = async () => {
        try {
            var responseJson = await ApiEngine.get(
                `${Routes._GET_VENDOR_CATEGORY_LIST}`,
                { headers: { skipLoading: true } }
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let tempVendorCategoryList = responseJson[ApiKey._API_DATA_KEY][
                    'data'
                ].filter(v => isMobile ? v.id : v.displayAtDesktop).map((i) => ({ id: i.id, title: i.translatedName }));
                setVendorCategories([
                    { id: -1, title: t('ALL') },
                    ...tempVendorCategoryList
                ]);
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const RenderTabs = ({ selectedId }) => {
        return (
            <Tabs index={vendorCategories.findIndex(v => v.id == selectedId)} isFitted size='sm' variant='enclosed' mt={2}>
                <TabList
                    overflowX={"scroll"}
                    sx={{
                        scrollbarWidth: "none",
                        "::-webkit-scrollbar": {
                            display: "none"
                        }
                    }}>
                    {
                        vendorCategories.map((item) => {
                            return (
                                <Tab isDisabled={isVendorListLoading} mr={0.5} className="app-tabs" onClick={() => setSelectedVendorCategory(item.id.toString())}>{item.title}</Tab>
                            )
                        })
                    }
                </TabList>
                <Divider className="app-divider-full-width" />
            </Tabs>
        )
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const selectVendorHandler = (item) => {
        if (!isPlayerListLoading) {
            setSelectedVendor(item);
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getPlayerDetail(username) {
        try {
            setSelectedPlayerUsername(username);
            setIsPlayerListLoading(true);
            let responseJson = await ApiEngine.get(
                `${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${username}`,
                {
                    cancelToken: cancelTokenSource.token,
                    headers: { skipLoading: true }
                }
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                const { score, disable, isSameRegion } =
                    responseJson[ApiKey._API_DATA_KEY][0];
                let tempPlayers = players;

                // update player score
                tempPlayers.find((i) => i.username == username)['score'] =
                    score;
                tempPlayers.find((i) => i.username == username)['status'] =
                    disable ? Status._DISABLED : Status._ENABLED;
                tempPlayers.find((i) => i.username == username)[
                    'isSameRegion'
                ] = isSameRegion;
                setPlayers(tempPlayers);
            }
        } finally {
            setPlayersQueue(playersQueue.filter((i) => i != username));
            setSelectedPlayerUsername(null);
            if (playersQueue.length == 1) {
                setIsPlayerListLoading(false);
            }
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function onShare(game, response) {
        try {
            if (response) {
                let shareMsg = 'Betman \n\n';
                shareMsg += response.member
                    ? 'Username: ' + response.member + '\n'
                    : '';
                shareMsg += 'Game: ' + game + '\n';
                shareMsg += response.vendor_username
                    ? 'ID: ' + response.vendor_username + '\n'
                    : '';
                shareMsg += response.password
                    ? 'Pw: ' + response.password + '\n'
                    : '';
                shareMsg += response.downloadUrlAndroid
                    ? 'Download URL (Android): ' +
                    response.downloadUrlAndroid +
                    '\n'
                    : '';
                shareMsg += response.downloadUrlIOS
                    ? 'Download URL (IOS): ' + response.downloadUrlIOS + '\n'
                    : '';
                shareMsg += '\nBetman \n\n';
                shareMsg += response.createdDateTime;

                share(shareMsg)
            }
        } catch (ex) {
            /// when user close the share option will come to here
        }
    }

    return (
        <div>
            <Carousel showArrows={false} showThumbs={false} showStatus={false} interval={10000} infiniteLoop autoPlay emulateTouch>
                <div>
                    <Image src={require(`../../../assets/images/banner/1-${stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE)) ? Language._ENGLISH : localStorage.getItem(ConfigEnum._LANGUAGE)}.png`)} />
                </div>
                <div>
                    <Image src={require(`../../../assets/images/banner/2-${stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE)) ? Language._ENGLISH : localStorage.getItem(ConfigEnum._LANGUAGE)}.png`)} />
                </div>
            </Carousel>
            <div className='body-content' style={checkBrowser() ? { maxWidth: "1080px", margin: "10px auto 0 auto", width: "100%", padding: "0 20px" } : { marginTop: '10px' }}>
                {
                    isLocationRestricted ?
                        <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("LOCATION_RESTRICTED")}</Text>
                        </AbsoluteCenter> :
                        <Stack mb={'6.5rem'}>
                            <Flex mt={3}>
                                <div style={{ width:"100%", backgroundImage: "linear-gradient(to right, #323430, #e3b725, #323430)", boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.3)" }}>
                                    <Flex>
                                        <Image ml={2} mt={1} mr={1} boxSize={'20px'} src={require('../../../assets/images/games/megaphone.png')} />
                                        <Marquee style={{ marginTop: '0.3rem' }}>[]
                                            <Text>{announcementText}</Text>
                                        </Marquee>
                                    </Flex>
                                </div>
                                <Popover>
                                    <PopoverTrigger>
                                        <Button variant={'link'} justifyContent={'flex-end'} onClick={() => { }}>
                                            <Image boxSize={'30px'} src={require('../../../assets/images/home-question.png')} />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="app-popover">
                                        <PopoverArrow />
                                        {
                                            _POPOVER_CONTENT.map((item, index) => {
                                                return (
                                                    <PopoverHeader>
                                                        <Flex>
                                                            <img width={item.largeIcon ? '28px' : '20px'} height={item.largeIcon ? '28px' : '20px'} src={item.icon} alt='BETMAN' />
                                                            <Text noOfLines={3} ml={2}>{t(item.title)}
                                                                {
                                                                    item.subDescription1 &&
                                                                    <Text as='span' color='app.blue'> {t(item.subDescription1)} </Text>
                                                                }
                                                                {
                                                                    item.subDescription2 &&
                                                                    <Text as='span'>{t(item.subDescription2)}</Text>
                                                                }
                                                                <Text as='span' color='app.blue'>{t(item.description)}</Text>
                                                            </Text>
                                                        </Flex>
                                                    </PopoverHeader>
                                                )
                                            })
                                        }
                                    </PopoverContent>
                                </Popover>
                            </Flex>
                            <HStack marginTop={'10px'} overflowX={'auto'}>
                                {
                                    vendorCategories.map((item, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                color={'white'}
                                                width={'full'}
                                                className={`landing-page-category-button${selectedVendorCategory == item.id ? '-selected' : ''}`}
                                                onClick={() => setSelectedVendorCategory(item.id)}>
                                                {t(item.title)}
                                            </Button>
                                        )
                                    })
                                }
                            </HStack>
                            <div style={{ overflowY: 'auto' }}>
                                {
                                    !isFirstLoad &&
                                    <>
                                        {
                                            vendorList.length > 0 ?
                                                <SimpleGrid columns={3} spacing={3} mt={3}>
                                                    {
                                                        vendorList.map((item, index) => {
                                                            return (
                                                                <Button key={index} variant={'link'} paddingBlock={2} onClick={() => !checkBrowser() ? selectVendorHandler(item) : _navigate(Pages._GAMES_DETAILS, { state: item })}>
                                                                    <Box className="app-game-button" height={'100%'}>
                                                                        <Flex direction={'column'} h={'full'} pt={4}>
                                                                            {
                                                                                item['gameName'].length > 12 ?
                                                                                    <Marquee play={item['gameName'].length > 12}>
                                                                                        <Text fontSize={[13, 14, 16]}>{item['gameName']} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                                                                                    </Marquee> :
                                                                                    <Text fontSize={[13, 14, 16]}>{item['gameName']}</Text>
                                                                            }
                                                                            <Spacer />
                                                                            <Center margin={'35px auto'}>
                                                                                {
                                                                                    item['imagePath'] ?
                                                                                        <Image boxSize={['70px', '80px', '110px']} src={item['imagePath']} /> :
                                                                                        <Text>{item['gameName']}</Text>
                                                                                }
                                                                            </Center>
                                                                            <Spacer />
                                                                            <Flex paddingInline={3} paddingBlock={3} w='full' bg={'#253145'} borderBottomLeftRadius={10} borderBottomRightRadius={10} boxShadow={'0px -6px 8px #161c2a'}>
                                                                                <Image
                                                                                    mr={2}
                                                                                    boxSize={[14.5, 16.5, 21]}
                                                                                    src={require('../../../assets/images/topbar-user-defaultphoto.png')}
                                                                                />
                                                                                <Text fontSize={[12, 13, 14]} mr={3}>{item['players'].length}</Text>
                                                                                <Spacer />
                                                                                <Flex>
                                                                                    {
                                                                                        item.isAutoLogin &&
                                                                                        <Image style={{ marginLeft: '0.5rem' }} width={[18.5, 19, 21]} height={[18.5, 19, 21]} src={_POPOVER_CONTENT.find(p => p.title == "AUTO_LOGIN").icon} alt='BETMAN' />
                                                                                    }
                                                                                    {
                                                                                        item.enableRewardCalculation && item.enableRolloverCalculation &&
                                                                                        <Image style={{ marginLeft: '0.5rem' }} width={[16.8, 19]} height={[16.8, 19]} src={_POPOVER_CONTENT.find(p => p.title == "SUPPORT_TURNOVER").icon} alt='BETMAN' />
                                                                                    }
                                                                                    {
                                                                                        item.payoutRate > 0 &&
                                                                                        <Image style={{ marginLeft: '0.5rem' }} width={[16.8, 19]} height={[16.8, 19]} src={_POPOVER_CONTENT.find(p => p.title == "HIGH_PAYOUT").icon} alt='BETMAN' />
                                                                                    }
                                                                                    {
                                                                                        item.bonusRate > 0 &&
                                                                                        <Image style={{ marginLeft: '0.5rem' }} width={[16.8, 19]} height={[16.8, 19]} src={_POPOVER_CONTENT.find(p => p.title == "HIGH_REBATE").icon} alt='BETMAN' />
                                                                                    }
                                                                                    {
                                                                                        item.downlineCommissionRate > 0 &&
                                                                                        <Image style={{ marginLeft: '0.5rem' }} width={[16.8, 19]} height={[16.8, 19]} src={_POPOVER_CONTENT.find(p => p.title == "HIGH_COMMISSION").icon} alt='BETMAN' />
                                                                                    }
                                                                                </Flex>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Box>
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </SimpleGrid> :
                                                <AbsoluteCenter>
                                                    <Text fontSize={'sm'} fontStyle={'italic'}>{t('NO_GAME_FOUND')}</Text>
                                                </AbsoluteCenter>
                                        }
                                    </>
                                }
                            </div>
                            {!checkBrowser() &&
                                <BottomSheet
                                    isOpen={!isObjectEmpty(selectedVendor)}
                                    onClose={() => setSelectedVendor(null)}
                                    title={selectedVendor?.gameName}
                                    content={
                                        <Stack>
                                            {
                                                players.length > 0 &&
                                                players.map((item, index) => {
                                                    return (
                                                        <>
                                                            <Flex w='100%' paddingInline={'1rem'} mb={2} key={index}>
                                                                <Button variant={'link'} w={'90%'} onClick={() => {
                                                                    _router.navigate(Pages._PLAYER_DETAIL, {
                                                                        playerDetail: item,
                                                                        selectedVendor: selectedVendor,
                                                                        vendorList: vendorList,
                                                                    })
                                                                }}>
                                                                    <Flex w='full'>
                                                                        <Flex>
                                                                            {
                                                                                item.status == Status._ENABLED ?
                                                                                    <Image boxSize={'35px'} src={require('../../../assets/images/withdraw.png')} /> :
                                                                                    <img width={'35px'} src={require('../../../assets/images/svg/general-block.svg').default} />
                                                                            }
                                                                            <Stack textAlign={'left'} ml={2}>
                                                                                <Text fontSize={['2xs', 'xs']}>{`${index + 1}. ${t('PLAYER_ID')}`}</Text>
                                                                                <Text color={'app.blue'}>{item.displayUsername}</Text>
                                                                            </Stack>
                                                                        </Flex>
                                                                        <Spacer />
                                                                        <Flex>
                                                                            <Stack>
                                                                                <Text fontSize={['2xs', 'xs']}>{t('SCORE')}</Text>
                                                                                <Box
                                                                                    position={'relative'}
                                                                                    paddingInline={5}
                                                                                    paddingBlock={.5}
                                                                                    borderColor={'#C6D1E2'}
                                                                                    borderWidth={'1px'}
                                                                                    borderRadius={'15px'}>
                                                                                    <Image
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            left: '-10px',
                                                                                            top: '-5px'
                                                                                        }}
                                                                                        boxSize={'28px'}
                                                                                        src={require('../../../assets/images/topbar-icon-score.png')} />
                                                                                    <Text>
                                                                                        {item.score !=
                                                                                            undefined
                                                                                            ? numberWithCurrencyFormat(
                                                                                                item.score
                                                                                            )
                                                                                            : '----'}</Text>
                                                                                </Box>
                                                                            </Stack>
                                                                        </Flex>
                                                                    </Flex>
                                                                </Button>
                                                                <Spacer />
                                                                {
                                                                    playersQueue.includes(item.username) ?
                                                                        <Spinner color='white' /> :
                                                                        <Button ml={[1.5, 0.5]} variant={'link'} w={'10%'} onClick={() =>
                                                                            queuePlayer(
                                                                                item.username
                                                                            )
                                                                        }>
                                                                            <Image
                                                                                boxSize={'30px'}
                                                                                src={require('../../../assets/images/refresh.png')} />
                                                                        </Button>
                                                                }
                                                            </Flex>
                                                            {
                                                                index + 1 != players.length &&
                                                                <Divider className="app-divider-darker" />
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                players.length < _MAXIMUM_PLAYERS &&
                                                <Button className="add-player-button" onClick={() => {
                                                    _dispatch(
                                                        showCustomDialog({
                                                            success: true,
                                                            title: t('ADD_PLAYER'),
                                                            content:
                                                                t('ADD_PLAYER_CONFIRMATION') +
                                                                selectedVendor?.gameName +
                                                                '?',
                                                            confirmTxt: t('CONFIRM'),
                                                            horizontalBtn: false,
                                                            onConfirm: () => {
                                                                addNewPlayer();
                                                            },
                                                            onCancel: () => { }
                                                        })
                                                    );
                                                }}>
                                                    {t('ADD_PLAYER')}
                                                </Button>
                                            }
                                        </Stack>
                                    } />}
                        </Stack>
                }
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    AbsoluteCenter,
    Spinner,
    Flex,
    Divider,
    Spacer,
    Image,
    Center,
    Box,
    SimpleGrid,
    Badge
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import { _PAGE_SIZE, ApiKey, _NOTIFICATION_TYPES } from "../../../common/constant";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat } from "../../../common/util";
import { useRouter } from "../../../hooks/useRouter";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _userData = useSelector(state => state.authState.userData);
    const { t } = useTranslation();
    const [rankList, setRankList] = useState([]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getRankList();
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getRankList = async () => {
        try {
            let responseJson = await ApiEngine.get(Routes._GET_RANK_LIST);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let dataList = responseJson[ApiKey._API_DATA_KEY]['data'];
                setRankList(dataList);
            } else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }))
        }
    }

    if (!checkBrowser()) {
        return (
            <div className='body-content'>
                <GeneralTopBar title={'RANK_DETAILS'} isBeta bgColor="black" />
                <SimpleGrid
                    columns={[2, 2, 2, 1]}
                    spacing={5}>
                    {
                        rankList.map((value, index) => {
                            return (
                                <Box opacity={value.id != _userData?.experienceDetail?.rankId ? 0.3 : 1}>
                                    <Stack align={'center'}>
                                        <Image zIndex={1} boxSize={'75px'} src={value.badgeFile} />
                                    </Stack>
                                    <Stack bg={value.colorCode} p={1} mt={-10} borderRadius={10} position={'relative'} textAlign={'center'}>
                                        <Image height={'160px'} src={value.outerFrame} borderRadius={10} />
                                        <AbsoluteCenter>
                                            <Text color={'black'} fontWeight={'bold'} fontSize={'sm'}>{t(value.translatedName)}</Text>
                                            {
                                                (value.id <= _userData.experienceDetail.rankId || value.id === _userData.experienceDetail.rankId + 1) &&
                                                <>
                                                    <Text color={'black'} fontSize={'sm'}>EXP</Text>
                                                    <Text color={'black'} fontWeight={'bold'} fontSize={'sm'}>{numberWithCurrencyFormat(value.minimumExperience, 2, false)}-{value.maximumExperience != null ? numberWithCurrencyFormat(value.maximumExperience, 2, false) : "∞"}</Text>
                                                    <Text color={'black'} fontSize={'sm'}>{t('REBATE')}</Text>
                                                    <Text color={'black'} fontWeight={'bold'} fontSize={'sm'}>{t(value.rebateRate)}%</Text>
                                                </>
                                            }
                                        </AbsoluteCenter>
                                    </Stack>
                                    {
                                        value.id == _userData.experienceDetail.rankId &&
                                        <Stack mt={-1.5} align={'center'}>
                                            <Badge zIndex={1} w='fit-content' borderRadius='20px' colorScheme={'messenger'}>{t('CURRENT_RANK')}</Badge>
                                        </Stack>
                                    }
                                </Box>
                            )
                        })
                    }
                </SimpleGrid>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "45rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={2} fontWeight={'bold'}>{t('RANK_DETAILS')}</Text>
                            <Badge colorScheme='twitter' w='fit-content' borderRadius='20px'>{t('BETA')}</Badge>
                        </div>
                        <SimpleGrid
                            columns={2}
                            spacing={5}>
                            {
                                rankList.map((value, index) => {
                                    return (
                                        <Box opacity={value.id != _userData?.experienceDetail?.rankId ? 0.3 : 1}>
                                            <Stack align={'center'}>
                                                <Image zIndex={1} boxSize={'75px'} src={value.badgeFile} />
                                            </Stack>
                                            <Stack bg={value.colorCode} p={1} mt={-10} borderRadius={10} position={'relative'} textAlign={'center'}>
                                                <Image height={'160px'} src={value.outerFrame} borderRadius={10} />
                                                <AbsoluteCenter>
                                                    <Text color={'black'} fontWeight={'bold'} fontSize={'sm'}>{t(value.translatedName)}</Text>
                                                    {
                                                        (value.id <= _userData.experienceDetail.rankId || value.id === _userData.experienceDetail.rankId + 1) &&
                                                        <>
                                                            <Text color={'black'} fontSize={'sm'}>EXP</Text>
                                                            <Text color={'black'} fontWeight={'bold'} fontSize={'sm'}>{numberWithCurrencyFormat(value.minimumExperience, 2, false)}-{value.maximumExperience != null ? numberWithCurrencyFormat(value.maximumExperience, 2, false) : "∞"}</Text>
                                                            <Text color={'black'} fontSize={'sm'}>{t('REBATE')}</Text>
                                                            <Text color={'black'} fontWeight={'bold'} fontSize={'sm'}>{t(value.rebateRate)}%</Text>
                                                        </>
                                                    }
                                                </AbsoluteCenter>
                                            </Stack>
                                            {
                                                value.id == _userData.experienceDetail.rankId &&
                                                <Stack mt={-4} align={'center'}>
                                                    <Badge paddingInline={3} paddingBlock={1} zIndex={1} w='fit-content' borderRadius='20px' colorScheme={'messenger'}>{t('CURRENT_RANK')}</Badge>
                                                </Stack>
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </SimpleGrid>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
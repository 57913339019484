import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Card,
    CardBody,
    Divider,
    Center
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog, disposeCustomDialog } from "../../../application/action/app_action";
import GeneralTopBar from "../../../component/general-top-bar";
import { ApiKey, BG_TYPE, ValidationPattern } from "../../../common/constant";
import { checkBrowser, createFormBody, stringIsNullOrEmpty } from "../../../common/util";
import { useForm, Controller } from 'react-hook-form';
import CustomInput from "../../../component/input";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import Countdown, { zeroPad } from 'react-countdown';
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { control, handleSubmit, errors, setValue, clearErrors, watch } = useForm();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const [canResend, setCanResend] = useState(true);
    const _EXPIRED_TIME_IN_SECONDS = 60000;

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const doPasswordMatch = (value) => {
        if (value) {
            clearErrors(["confirmNewPassword", "newPassword"]);
            return true;
        }

        return false;
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function triggerResend() {
        var responseJson = await ApiEngine.post(Routes._RESEND_SECONDARY_PASSWORD, createFormBody({ UserId: userData.guid }));

        _dispatch(showCustomDialog({
            success: responseJson[ApiKey._API_SUCCESS_KEY],
            content: responseJson[ApiKey._API_MESSAGE_KEY],
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setCanResend(false);
                }
            },
        }));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const TimerRenderer = ({ minutes, seconds }) => {
        return <span className="app-countdown-timer"><Text fontSize={'md'} color={'app.blue'}>{zeroPad(minutes)}:{zeroPad(seconds)}</Text></span>;
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('oldPassword')) && !stringIsNullOrEmpty(watch('newPassword')) && !stringIsNullOrEmpty(watch('confirmNewPassword')));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            window.scrollTo(0, 0);
            let params = {
                OldSecondaryPassword: data.oldPassword,
                SecondaryPassword: data.confirmNewPassword
            };

            var responseJson = await ApiEngine.post(Routes._CHANGE_SECONDARY_PASSWORD, createFormBody(params));

            _dispatch(showCustomDialog({
                success: responseJson[ApiKey._API_SUCCESS_KEY],
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: () => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _router.back();
                    }
                },
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div >
                <GeneralTopBar bgColor="black" />
                <Flex align={'center'} mb={5} paddingInline={5}>
                    <Text as='b' fontSize={'xl'}>{t('CHANGE_SECONDARY_PASSWORD')}</Text>
                    <Spacer />
                    <Box className="settings-icon-box">
                        <Image boxSize={'50px'} src={require('../../../assets/images/security/password.png')} />
                    </Box>
                </Flex>
                <Stack paddingInline={5} className="display-center">
                    <Text color={'#C6D1E2'}>{t("NOTE")}: {t("PASSWORD_MUST_BE_6_DIGIT_NUMBERS")}</Text>
                    <Card className="grey-box">
                        <CardBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Controller
                                    control={control}
                                    name="oldPassword"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            inputMode='tel'
                                            isPassword
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label='OLD_SECONDARY_PASSWORD'
                                            placeHolder={t('OLD_SECONDARY_PASSWORD')}
                                            inputType='tel'
                                            errors={errors}
                                            patternErrorTxt="PASSWORD_MSG" />
                                    )}
                                    rules={{
                                        required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                        pattern: ValidationPattern._DIGIT_REGEX
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="newPassword"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            inputMode='tel'
                                            isPassword
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label='NEW_SECONDARY_PASSWORD'
                                            placeHolder={t('NEW_SECONDARY_PASSWORD')}
                                            errors={errors}
                                            patternErrorTxt="PASSWORD_MSG" />
                                    )}
                                    rules={{
                                        required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                        pattern: ValidationPattern._DIGIT_REGEX
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="confirmNewPassword"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            inputMode='tel'
                                            isPassword
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label='CONFIRM_NEW_SECONDARY_PASSWORD'
                                            placeHolder={t('CONFIRM_NEW_SECONDARY_PASSWORD')}
                                            inputType='tel'
                                            errors={errors}
                                            patternErrorTxt="PASSWORD_MSG" />
                                    )}
                                    rules={{
                                        required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                        pattern: ValidationPattern._DIGIT_REGEX,
                                        validate: {
                                            matchingPassword: value => doPasswordMatch(value === watch('newPassword')) || 'PASSWORD_NOT_MATCH_MSG'
                                        }
                                    }}
                                />
                                <Button className="grey-button" type="submit">{t('UPDATE_SECONDARY_PASSWORD')}</Button>
                            </form>
                        </CardBody>
                        <Divider />
                        <CardBody>
                            <Flex justify={'center'}>
                                {
                                    canResend ?
                                        <>
                                            <Text color={'app.grey'}>{t('FORGOT_OLD_SECONDARY_PASSWORD')}</Text>
                                            <Button
                                                ml='0.3rem'
                                                color={'app.blue'}
                                                variant='link'
                                                onClick={() => { triggerResend(); }}>
                                                <Text color={'app.blue'} decoration='underline'>{t('RESET')}</Text>
                                            </Button>
                                        </> :
                                        <Countdown
                                            date={Date.now() + _EXPIRED_TIME_IN_SECONDS}
                                            renderer={TimerRenderer}
                                            onComplete={() => setCanResend(!canResend)}
                                        />
                                }
                            </Flex>
                        </CardBody>
                    </Card>
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", minWidth: "32rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "25px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={10}>{t('CHANGE_SECONDARY_PASSWORD')}</Text>
                        </div>
                        <Text fontSize={'sm'} mb={4} color={'#C6D1E2'}>{t("NOTE")}: {t("PASSWORD_MUST_BE_6_DIGIT_NUMBERS")}</Text>
                        <Card>
                            <CardBody bg={'#2c3956'} borderRadius={20}>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <Controller
                                        control={control}
                                        name="oldPassword"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputMode='tel'
                                                isPassword
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                borderRadius="10px !important"
                                                paddingBlock="21px !important"
                                                label='OLD_SECONDARY_PASSWORD'
                                                inputType='tel'
                                                errors={errors}
                                                patternErrorTxt="PASSWORD_MSG" />
                                        )}
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                            maxLength: 6,
                                            pattern: ValidationPattern._DIGIT_REGEX
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name="newPassword"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputMode='tel'
                                                isPassword
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                borderRadius="10px !important"
                                                paddingBlock="21px !important"
                                                label='NEW_SECONDARY_PASSWORD'
                                                errors={errors}
                                                patternErrorTxt="PASSWORD_MSG" />
                                        )}
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                            maxLength: 6,
                                            pattern: ValidationPattern._DIGIT_REGEX
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name="confirmNewPassword"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputMode='tel'
                                                isPassword
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                borderRadius="10px !important"
                                                paddingBlock="21px !important"
                                                label='CONFIRM_NEW_SECONDARY_PASSWORD'
                                                inputType='tel'
                                                errors={errors}
                                                patternErrorTxt="PASSWORD_MSG" />
                                        )}
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                            maxLength: 6,
                                            pattern: ValidationPattern._DIGIT_REGEX,
                                            validate: {
                                                matchingPassword: value => doPasswordMatch(value === watch('newPassword')) || 'PASSWORD_NOT_MATCH_MSG'
                                            }
                                        }}
                                    />
                                    <Button mt={2}
                                        style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#161f30", color: "white", padding: "25px 0px" }} w='100%'
                                        type="submit">{t('UPDATE_SECONDARY_PASSWORD')}</Button>
                                </form>
                            </CardBody>
                            <Divider />
                            <CardBody>
                                <Flex justify={'center'}>
                                    {
                                        canResend ?
                                            <>
                                                <Text color={'app.grey'}>{t('FORGOT_OLD_SECONDARY_PASSWORD')}</Text>
                                                <Button
                                                    ml='0.3rem'
                                                    color={'app.blue'}
                                                    variant='link'
                                                    onClick={() => { triggerResend(); }}>
                                                    <Text color={'app.blue'} decoration='underline'>{t('RESET')}</Text>
                                                </Button>
                                            </> :
                                            <Countdown
                                                date={Date.now() + _EXPIRED_TIME_IN_SECONDS}
                                                renderer={TimerRenderer}
                                                onComplete={() => setCanResend(!canResend)}
                                            />
                                    }
                                </Flex>
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React from "react";
import { QRCode } from 'react-qrcode-logo';

export default ({
    value,
    action,
    containerSize = 65, // in px
    qrSize = 60, // in px
    borderRadius = 0, // in px
    logoImage
}) => {
    return (
        <div className="qr-code-container" style={{ width: `${containerSize}px`, height: `${containerSize}px`, borderRadius: `${borderRadius}px` }} onClick={action}>
            <QRCode
                value={value}
                size={qrSize}
                logoImage={logoImage}
                logoWidth={qrSize * 0.3}
                logoHeight={qrSize * 0.3} />
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Text, Stack, Box, Flex, Card, Spacer, Image, Button, Divider, SimpleGrid, Center } from "@chakra-ui/react";
import GeneralTopBar from "../../../component/general-top-bar";
import { checkBrowser, isObjectEmpty, stringIsNullOrEmpty } from "../../../common/util";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from "react-i18next";
import { ApiKey, _EVENT_STATUS } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { ConfigEnum } from "../../../common/config";
import moment from "moment";
import kogDetails from './kogEventDetails.json';
import kogRanking from './kogRanking.json'
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { eventId } = _location?.state;
    const [eventDetails, setEventDetails] = useState();
    const [expandView, setExpandView] = useState(false);
    const [currentRank, setCurrentRank] = useState();
    const [myRanking, setMyRanking] = useState();

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(eventId)) {
            _router.back();
        }
        else {
            retrieveEventDetail();
            getKogRanking();
            getMyRanking();
        }
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const retrieveEventDetail = async () => {
        try {
            let responseJson = await ApiEngine.get(Routes._EVENT_DETAILS.replace("{id}", eventId), {
                headers: { "skipLoading": true }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setEventDetails(responseJson[ApiKey._API_DATA_KEY]);
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getKogRanking = async () => {
        let ranking = {};

        try {
            let localRanking = [];
            let requiredApiCall = true;
            let eventRankingString = localStorage.getItem(ConfigEnum._EVENT_RANKING);

            if (!stringIsNullOrEmpty(eventRankingString)) {
                localRanking = JSON.parse(eventRankingString);
                let rankingObj = localRanking.find(e => e.eventId == eventId &&
                    moment(e.lastSync).isBetween(moment().format('YYYY-MM-DD 05:00:00'), moment().add(1, 'day').format('YYYY-MM-DD 04:59:59')));

                if (rankingObj) {
                    ranking = rankingObj['storedRanking'];
                    requiredApiCall = false;
                }
            }

            if (requiredApiCall) {
                let responseJson = await ApiEngine.get(`${Routes._EVENT_KOG_RANKING}?eventId=${eventId}`, {
                    headers: { "skipLoading": true }
                });

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    ranking = responseJson[ApiKey._API_DATA_KEY]["data"];
                    localRanking.push({
                        eventId: eventId,
                        lastSync: moment().format('YYYY-MM-DD HH:mm:ss'),
                        storedRanking: ranking
                    });

                    localStorage.setItem(ConfigEnum._EVENT_RANKING, JSON.stringify(localRanking));
                }
                else {
                    _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }))
                }
            }
        } catch (err) {
            // nothing
        }

        setCurrentRank(ranking);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getMyRanking = async () => {
        let userRanking = {};

        try {
            let localUserRanking = [];
            let requiredApiCall = true;
            let userEventRankingString = localStorage.getItem(ConfigEnum._USER_EVENT_RANKING);

            if (!stringIsNullOrEmpty(userEventRankingString)) {
                localUserRanking = JSON.parse(userEventRankingString);
                let rankingObj = localUserRanking.find(e => e.eventId == eventId &&
                    moment(e.lastSync).isBetween(moment().format('YYYY-MM-DD 05:00:00'), moment().add(1, 'day').format('YYYY-MM-DD 04:59:59')));

                if (rankingObj) {
                    userRanking = rankingObj['storedRanking'];
                    requiredApiCall = false;
                }
            }

            if (requiredApiCall) {
                let responseJson = await ApiEngine.get(Routes._EVENT_USER_KOG_RANKING.replace("{id}", eventId));

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    userRanking = responseJson[ApiKey._API_DATA_KEY];
                    localUserRanking.push({
                        eventId: eventId,
                        lastSync: moment().format('YYYY-MM-DD HH:mm:ss'),
                        storedRanking: userRanking
                    });

                    localStorage.setItem(ConfigEnum._USER_EVENT_RANKING, JSON.stringify(localUserRanking))
                }
            }
        } catch (err) {
            // nothing
        }

        setMyRanking(userRanking);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const PrizeItem = ({ index, prize, eventDetails }) => {
        const PRIZE_MAPPING = {
            0: {
                svgKey: require('../../../assets/images/svg/first-prize.svg').default,
                text: '1st'
            },
            1: {
                svgKey: require('../../../assets/images/svg/second-prize.svg').default,
                text: '2nd'
            },
            2: {
                svgKey: require('../../../assets/images/svg/third-prize.svg').default,
                text: '3rd'
            }
        }

        return <>
            {index !== 0 && <Divider className="app-divider-full-width-setting" />}
            <Flex justifyContent={'center'} alignItems={'center'} p={3} gap={checkBrowser() ? 1 : 0}>
                <Flex w={!checkBrowser() ? '15%' : '10%'}>
                    {
                        index < 3 && <img src={PRIZE_MAPPING[index].svgKey} />
                    }
                    {
                        index > 2 && (
                            <Text>
                                {index + 1 + 'th'}
                            </Text>
                        )
                    }
                </Flex>
                <Spacer />
                <Text w={'100%'} fontWeight={'bold'}>{prize.prizeName}</Text>
                <Spacer />
                <Image w={'30%'} boxSize={'35px'} src={prize.logo} />
                <Spacer />
                <Text w={'10%'} textAlign={'right'} fontWeight={'bold'}>x{prize.quantity}</Text>
            </Flex>
            {
                index === 2 && index < 3 &&
                <>
                    <Divider className="app-divider-full-width-setting" />
                    <Button paddingInline={3} onClick={() => { _router.navigate(Pages._PRIZE_DETAILS, { eventId: prize.eventId, prizeList: eventDetails?.prizeList }); }}>
                        <Flex align={'center'} w='full'>
                            <Text textAlign={'left'} color={'#FFD055'}>{t('SHOW_MORE')}</Text>
                            <Spacer />
                            <Image boxSize={'10px'} transform={'rotate(90deg)'} src={require('../../../assets/images/svg/general-updown-yellow.svg').default} />
                        </Flex>
                    </Button>
                </>
            }
        </>;
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const RankingItem = ({ index, user }) => {
        const PRIZE_SVG = {
            0: require('../../../assets/images/svg/first-prize.svg').default,
            1: require('../../../assets/images/svg/second-prize.svg').default,
            2: require('../../../assets/images/svg/third-prize.svg').default
        }

        return <>
            {
                index !== 0 && <Divider className="app-divider-full-width-setting" />
            }
            <Flex alignItems={'center'}>
                <Box w={!checkBrowser() ? '25%' : '15%'} textAlign={!checkBrowser() ? 'center' : ''}>
                    {
                        [0, 1, 2].includes(index)
                            ? <Image src={PRIZE_SVG[index]} ml={!checkBrowser() ? 1.5 : 0} />
                            : (
                                <Text color={"#A2A2A2"}>{index + 1}</Text>
                            )
                    }
                </Box>
                <Spacer />
                <Image w={'25%'} boxSize={'25px'} src={require("../../../assets/images/chat/chat-people.png")} />
                <Spacer />
                <Text w={'100%'} ml={!checkBrowser() ? 1 : 3} color={"#A2A2A2"}>{`${user.maskedUsername} ${!stringIsNullOrEmpty(user.name) ? "(" + user.name + ")" : ""}`}</Text>
                <Spacer />
                <Text w={'75%'} textAlign={'right'} color={"#A2A2A2"}>{`${Number(user.winAmount).toFixed(2) ?? 0}`}</Text>
            </Flex>
        </>;
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const MyRankingItem = ({ user }) => {
        const PRIZE_SVG = {
            1: require('../../../assets/images/svg/first-prize.svg').default,
            2: require('../../../assets/images/svg/second-prize.svg').default,
            3: require('../../../assets/images/svg/third-prize.svg').default
        }

        return <div className="bottom-sticky-button" style={{ maxWidth: "50rem" }}>
            <Flex alignItems={'center'} p={2} bg={'gray.100'} paddingInline={8} borderTopRadius={20}>
                <Box w={'25%'} textAlign={'center'}>
                    {
                        [1, 2, 3].includes(user.rankPosition) ?
                            <Image src={PRIZE_SVG[user.rankPosition]} ml={1.5} />
                            :
                            <Text color={"#000"}>{user.rankPosition ?? "..."}</Text>

                    }
                </Box>
                <Spacer />
                <Image w={'25%'} boxSize={'25px'} src={require("../../../assets/images/chat/chat-people.png")} />
                <Spacer />
                <Text w={'100%'} ml={1} color={"#000"}>{`${user.username} ${!stringIsNullOrEmpty(user.name) ? "(" + user.name + ")" : ""}`}</Text>
                <Spacer />
                <Text w={'75%'} textAlign={'right'} color={"#000"}>{`${user.winAmount ?? 0}`}</Text>
            </Flex>
        </div>
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'EVENT'} />
                <Text marginBlock={1.5} fontSize={'md'} fontWeight={'bold'}>{eventDetails?.name}</Text>
                <Box borderRadius={20} borderWidth={1} borderColor={'white'} mb={2}>
                    <Image src={eventDetails?.bannerImage} borderRadius={20} />
                </Box>
                <Flex>
                    <Stack>
                        <Text fontWeight={'bold'}>{moment(eventDetails?.startDate).format("DD/MM/YYYY, hh:mm:ss A")}</Text>
                        <Text color={'#FFD055'}>{t("START_DATE")}</Text>
                    </Stack>
                    <Spacer />
                    <Stack spacing={0} alignItems={'center'}>
                        <img src={require('../../../assets/images/svg/date-to.svg').default} style={{ width: '28px', height: '28px' }} />
                        <Text fontStyle={'italic'} fontWeight={750} color={eventDetails?.status == _EVENT_STATUS.ENDED ? 'red' : 'green.300'}>{eventDetails?.status == _EVENT_STATUS.ENDED ? t('ENDED') : t('ON_GOING')}</Text>
                    </Stack>
                    <Spacer />
                    <Stack>
                        <Text textAlign={'right'} fontWeight={'bold'}>{moment(eventDetails?.endDate).format("DD/MM/YYYY, hh:mm:ss A")}</Text>
                        <Text textAlign={'right'} color={'#FFD055'}>{t("END_DATE")}</Text>
                    </Stack>
                </Flex>
                <Divider marginBlock={2.5} className="app-divider-full-width-setting" />
                <Text color={'#FFD055'}>{t("DESCRIPTION")}</Text>
                <Text>{eventDetails?.description}</Text>
                <Text color={'#FFD055'} mt={1.5}>{t("VENDOR")}</Text>
                <SimpleGrid columns={7} spacing={2}>
                    {
                        eventDetails?.vendorList?.map((item, index) => {
                            if (index > 6 && !expandView) {
                                return;
                            }

                            return (
                                <Image boxSize={'45px'} src={item?.vendorLogo} />
                            );
                        })
                    }
                </SimpleGrid>
                {
                    eventDetails?.vendorList?.length > 6 && (
                        <Button variant={'link'} onClick={() => setExpandView(!expandView)}>
                            <Text mr={1}>
                                {t(expandView ? "SHOW_LESS" : "SHOW_MORE")}
                            </Text>
                            <img style={{ transform: expandView ? 'rotate(180deg)' : 'rotate(0deg)' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                        </Button>
                    )
                }
                <Divider marginBlock={2.5} className="app-divider-full-width-setting" />
                {
                    !stringIsNullOrEmpty(eventDetails.prizeList) &&
                    <Stack>
                        <Text color={'#FFD055'}>{t("PRIZE")}</Text>
                        <Card className="grey-box" borderRadius={20}>
                            {
                                eventDetails?.prizeList?.map((prize, index) =>
                                    index < 3 && (
                                        <PrizeItem index={index} prize={prize} eventDetails={eventDetails} />
                                    )
                                )
                            }
                        </Card>
                    </Stack>
                }
                {
                    currentRank && currentRank.length > 0 &&
                    <Stack mt={2} pb={!isObjectEmpty(myRanking) ? '7.5vh' : 5}>
                        <Box paddingBlock={2} bg={'#FFD055'} marginInline={'-3.75vw'}>
                            <Text fontWeight={500} marginLeft={'3.75vw'}>{t("CURRENT_RANKING")}</Text>
                        </Box>
                        <Text color={"#A2A2A2"}>{t("DISCLAIMER")}</Text>
                        <Stack spacing={0}>
                            <Text color={"#A2A2A2"}>1.{t("RANKING_WILL_BE_UPDATED_AT_12_AM_EVERYDAY")}</Text>
                            <Text color={"#A2A2A2"}>2.{t("RANKING_WILL_BE_UPDATED_AT_5_AM_EVERYDAY")}</Text>
                            <Text color={"#A2A2A2"}>3.{t("WINNER_WITH_THE_SAME_EXP_WILL_BE_CONTACTED_AT_THE_END_OF_THE_EVENT")}</Text>
                        </Stack>
                        {
                            currentRank.map((value, index) =>
                                <RankingItem index={index} user={value} />
                            )
                        }
                    </Stack>
                }
                {
                    !isObjectEmpty(myRanking) && (
                        <>
                            <Divider className="app-divider-full-width-setting" />
                            <MyRankingItem user={myRanking} />
                        </>
                    )
                }
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", maxWidth: "50rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('EVENT')}</Text>
                        </div>
                        <Text marginBlock={1.5} fontSize={'md'} fontWeight={'bold'}>{eventDetails?.name}</Text>
                        <Box borderRadius={20} borderWidth={1} borderColor={'white'} mb={2}>
                            <Image src={eventDetails?.bannerImage} borderRadius={20} />
                        </Box>
                        <Flex>
                            <Stack>
                                <Text fontWeight={'bold'}>{moment(eventDetails?.startDate).format("DD/MM/YYYY, hh:mm:ss A")}</Text>
                                <Text color={'#FFD055'}>{t("START_DATE")}</Text>
                            </Stack>
                            <Spacer />
                            <Stack spacing={0} alignItems={'center'}>
                                <img src={require('../../../assets/images/svg/date-to.svg').default} style={{ width: '28px', height: '28px' }} />
                                <Text fontStyle={'italic'} fontWeight={750} color={eventDetails?.status == _EVENT_STATUS.ENDED ? 'red' : 'green.300'}>{eventDetails?.status == _EVENT_STATUS.ENDED ? t('ENDED') : t('ON_GOING')}</Text>
                            </Stack>
                            <Spacer />
                            <Stack>
                                <Text textAlign={'right'} fontWeight={'bold'}>{moment(eventDetails?.endDate).format("DD/MM/YYYY, hh:mm:ss A")}</Text>
                                <Text textAlign={'right'} color={'#FFD055'}>{t("END_DATE")}</Text>
                            </Stack>
                        </Flex>
                        <Divider marginBlock={2.5} className="app-divider-full-width-setting" />
                        <Text color={'#FFD055'}>{t("DESCRIPTION")}</Text>
                        <Text>{eventDetails?.description}</Text>
                        <Text color={'#FFD055'} mt={1.5}>{t("VENDOR")}</Text>
                        <SimpleGrid columns={7} spacing={2}>
                            {
                                eventDetails?.vendorList?.map((item, index) => {
                                    if (index > 6 && !expandView) {
                                        return;
                                    }

                                    return (
                                        <Image boxSize={'45px'} src={item?.vendorLogo} />
                                    );
                                })
                            }
                        </SimpleGrid>
                        {
                            eventDetails?.vendorList?.length > 6 && (
                                <Button variant={'link'} onClick={() => setExpandView(!expandView)}>
                                    <Text mr={1}>
                                        {t(expandView ? "SHOW_LESS" : "SHOW_MORE")}
                                    </Text>
                                    <img style={{ transform: expandView ? 'rotate(180deg)' : 'rotate(0deg)' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                </Button>
                            )
                        }
                        <Divider marginBlock={2.5} className="app-divider-full-width-setting" />
                        {
                            !stringIsNullOrEmpty(eventDetails.prizeList) &&
                            <Stack>
                                <Text color={'#FFD055'}>{t("PRIZE")}</Text>
                                <Card bg={'#2c3956'} borderRadius={20} paddingBlock={2} paddingInline={4}>
                                    {
                                        eventDetails?.prizeList?.map((prize, index) =>
                                            index < 3 && (
                                                <PrizeItem index={index} prize={prize} eventDetails={eventDetails} />
                                            )
                                        )
                                    }
                                </Card>
                            </Stack>
                        }
                        {
                            currentRank && currentRank.length > 0 &&
                            <Stack mt={4} pb={!isObjectEmpty(myRanking) ? 3 : 5}>
                                <Box paddingBlock={2} bg={'#FFD055'} borderRadius={20}>
                                    <Text fontWeight={500} marginLeft={6}>{t("CURRENT_RANKING")}</Text>
                                </Box>
                                <Text color={"#A2A2A2"}>{t("DISCLAIMER")}</Text>
                                <Stack spacing={0} mb={3}>
                                    <Text color={"#A2A2A2"}>1.{t("RANKING_WILL_BE_UPDATED_AT_12_AM_EVERYDAY")}</Text>
                                    <Text color={"#A2A2A2"}>2.{t("RANKING_WILL_BE_UPDATED_AT_5_AM_EVERYDAY")}</Text>
                                    <Text color={"#A2A2A2"}>3.{t("WINNER_WITH_THE_SAME_EXP_WILL_BE_CONTACTED_AT_THE_END_OF_THE_EVENT")}</Text>
                                </Stack>
                                {
                                    currentRank.map((value, index) =>
                                        <RankingItem index={index} user={value} />
                                    )
                                }
                            </Stack>
                        }
                        {
                            !isObjectEmpty(myRanking) && (
                                <>
                                    <Divider className="app-divider-full-width-setting" />
                                    <MyRankingItem user={myRanking} />
                                </>
                            )
                        }
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Flex, Center } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ValidationPattern, _AMOUNT_TYPE, _PAYMENT_DEVICE_TYPE, EWalletType } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isString, createFormBody, stringIsNullOrEmpty, checkBrowser } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import CustomInput from "../../../component/input";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
var md5 = require('md5');

/// <summary>
/// Author: CK
/// </summary>
export default ({ isDuitNow }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector((state) => state.authState.userData);
    const [selectedOption, setSelectedOption] = useState(EWalletType[0].key);
    const [processingFee, setProcessingFee] = useState(0);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(Routes._GET_MXPAY_PROCESSING_FEE);

                setProcessingFee(responseJson['data']['processingFee']);
            }
            catch (err) {
                // do nothing
            }
        })();
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('amount')) && !stringIsNullOrEmpty(selectedOption));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const submitForm = async (data, e) => {
        _dispatch(showCustomDialog({
            success: true,
            title: t("CONFIRMATION_OF_PAYMENT"),
            customContent: (
                <Flex direction={'column'}>
                    <Text>
                        {t("ARE_YOU_SURE_WANT_TO_DEPOSIT_TO_WALLET", { amount: `RM ${data.amount}` })}
                    </Text>
                    <Flex direction={'column'}>
                        <Text fontWeight='bold' fontSize={16} textAlign='left'>
                            {t('WARNING')}!!!
                        </Text>
                        <Text fontSize={14} textAlign='left'>
                            1) {t('E_WALLET_DO_NOT_USE_OLD_QR')}
                        </Text>
                        <Text fontSize={14} textAlign='left'>
                            2) {t('E_WALLET_SAME_AMOUNT')}
                        </Text>
                        <Text fontSize={14} textAlign='left'>
                            3) {t('E_WALLET_TIME_CONSTRAINTS')}
                        </Text>
                        <Text fontSize={14} textAlign='left'>
                            4) {t('E_WALLET_COMPLETION_FLOW')}
                        </Text>
                        <Image
                            marginBlock={1}
                            src={require('../../../assets/images/ewallet-amount.png')}
                        />
                        <Text fontSize={12} textAlign='left' fontWeight={'black'}>
                            {t('E_WALLET_REMARK')}
                        </Text>
                    </Flex>
                </Flex>
            ),
            onConfirm: () => {
                createTransaction(data);
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const createTransaction = async (data) => {
        try {
            let params = JSON.stringify({
                "Amount": parseFloat(data.amount),
                "TransferProvider": selectedOption.toString(),
                "FirstName": userData?.username
            });

            let responseJson = await ApiEngine.post(Routes._CREATE_EWALLET_TRANSACTION, params, { headers: { 'content-type': ApiKey._API_APPLICATION_JSON } });

            _router.navigate(Pages._E_WALLET_PAYMENT_GATEWAY, {
                html: responseJson
            });
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }))
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={t('EWALLET_DEPOSIT')}>
                    <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                </GeneralTopBar>
                <div className="header-margin-top inner-container">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Text fontSize={'sm'} mb={5}>{`1. ${t('EWALLET_OPTIONS')}`}</Text>
                        <Flex flexWrap={'wrap'} justifyContent={['space-between', 'flex-start']}>
                            {
                                EWalletType.map(i => {
                                    return (
                                        <Button onClick={() => setSelectedOption(i.key)} className="eWalletOptionBtn" mb={5}>
                                            <Flex className="eWalletOption" align={'center'}>
                                                <div className={`eWalletRadioBtn ${selectedOption == i.key ? "active" : ""}`}></div>
                                                <Flex className={`eWalletRadioContent`} direction={'column'} align={'center'}>
                                                    <img src={i.imagePath} />
                                                    <p>{i.title}</p>
                                                </Flex>
                                            </Flex>
                                        </Button>
                                    );
                                })
                            }
                        </Flex>
                        <Divider className='app-divider-full-width-setting' mb={5} />
                        <Controller
                            control={control}
                            name="amount"
                            defaultValue={10}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                    value={value}
                                    label={`1. ${t('ENTER_AMOUNT')}`}
                                    placeholder={t('ENTER_AMOUNT')}
                                    inputType="number"
                                    subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                    subLabelStyle={
                                        {
                                            color: '#AACBFF'
                                        }
                                    }
                                    style={
                                        {
                                            marginBottom: 0
                                        }
                                    }
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: true,
                                min: 1
                            }}
                        />
                        {!stringIsNullOrEmpty(watch('amount')) && processingFee > 0 &&
                            <Text mt={3} ml={1} color={'#AACBFF'} fontSize={12}>
                                {`E.g: RM10 x ${processingFee}% Rate = ${10 - (10 * processingFee / 100)} e-Credit`}
                            </Text>
                        }
                        <Stack spacing={4} direction='row' align='center' justifyContent={['space-between', 'flex-start']} mt={5}>
                            {_AMOUNT_TYPE.map((value, index) => (
                                <Button
                                    className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                    size='md'
                                    onClick={() => setValue('amount', value.amount)}
                                >
                                    RM {value.amount}
                                </Button>
                            ))}
                        </Stack>
                        <Button
                            className="grey-gradient-button btn-float-btm"
                            type="submit">
                            {t('NEXT')}
                        </Button>
                    </form>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('EWALLET_DEPOSIT')}</Text>
                        </div>
                        <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                        <div style={{ marginTop: "2rem" }}>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Text fontSize={'md'} mb={5}>{`1. ${t('EWALLET_OPTIONS')}`}</Text>
                                <Flex flexWrap={'wrap'} justifyContent={['space-between', 'flex-start']}>
                                    {
                                        EWalletType.map(i => {
                                            return (
                                                <Button onClick={() => setSelectedOption(i.key)} className="eWalletOptionBtn" mb={5}>
                                                    <Flex className="eWalletOption" align={'center'}>
                                                        <div className={`eWalletRadioBtn ${selectedOption == i.key ? "active" : ""}`}></div>
                                                        <Flex className={`eWalletRadioContent`} direction={'column'} align={'center'}>
                                                            <img src={i.imagePath} />
                                                            <p style={{fontSize:"10pt"}}>{i.title}</p>
                                                        </Flex>
                                                    </Flex>
                                                </Button>
                                            );
                                        })
                                    }
                                </Flex>
                                <Divider className='app-divider-full-width-setting' mb={6} mt={2} />
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={10}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label={`2. ${t('ENTER_AMOUNT')}`}
                                            placeholder={t('ENTER_AMOUNT')}
                                            inputType="number"
                                            borderRadius='10px !important'
                                            bg='#2c3956 !important'
                                            color='white'
                                            boxShadow='none !important'
                                            subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                            subLabelStyle={
                                                {
                                                    color: '#AACBFF',
                                                    fontSize: 'sm'
                                                }
                                            }
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                        min: 1
                                    }}
                                />
                                {!stringIsNullOrEmpty(watch('amount')) && processingFee > 0 &&
                                    <Text mt={'-1rem'} ml={1} color={'#AACBFF'} fontSize={'sm'}>
                                        {`E.g: RM10 x ${processingFee}% Rate = ${10 - (10 * processingFee / 100)} e-Credit`}
                                    </Text>
                                }
                                <Stack spacing={4} direction='row' align='center' mb={10} justifyContent={['space-between', 'flex-start']} mt={5}>
                                    {_AMOUNT_TYPE.map((value, index) => (
                                        <Button
                                            className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                            size='md'
                                            onClick={() => setValue('amount', value.amount)}
                                        >
                                            RM {value.amount}
                                        </Button>
                                    ))}
                                </Stack>
                                <Button
                                    style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#303e5d", color: "white", padding: "25px 0px" }} w='100%'
                                    type="submit">
                                    {t('NEXT')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createHMAC } from '../common/util';

/// <summary>
/// Author: Saitama
/// </summary>
const ChatwootWidget = () => {
    const _USER_DATA = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        window.chatwootSettings = {
            hideMessageBubble: true,
            position: "right",
            locale: "en",
            useBrowserLanguage: false,
            baseDomain: 'www.betman378.com/',
            type: "expanded_bubble",
            launcherTitle: "Chat with us",
            
        };

        (function (d, t) {
            var BASE_URL = "https://app.chatwoot.com";
            var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: 'h4av5NgLhzPVR8S65HQ1Zhdb',
                    baseUrl: BASE_URL
                })
            }
        })(document, "script");

        return () => {
            window.$chatwoot.reset();
        };
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (window.$chatwoot) {
            const key = "s5c1DLNvC2MSgLXWCWTcdw59";
            const identifier = _USER_DATA.username + "@betman378.com";
            let hmackey = createHMAC(identifier, key);

            window.$chatwoot.setUser(identifier, {
                email: identifier,
                name: _USER_DATA.username,
                phone_number: _USER_DATA.username,
                identifier_hash: hmackey
            });
            
            window.$chatwoot.toggle();
        }
    }, [window.$chatwoot])

    return (null)
}

export default ChatwootWidget
import _ from "lodash";
import { InputValidationKey, ScreenWidth } from "./constant";
import { toast } from 'react-toastify';
import {
  PoolColor,
  MAGNUM_POOL_NAME,
  DAMACAI_POOL_NAME,
  TOTO_POOL_NAME,
  SINGAPORE_POOL_NAME,
  SABAH_POOL_NAME,
  SANDAKAN_POOL_NAME,
  CASH_SWEEP_POOL_NAME,
  GRAND_DRAGON_POOL_NAME,
  NINE_LOTTO_POOL_NAME,
  ACTIVE_STATUS_TXT,
  CANCELLED_STATUS_TXT,
  _PROHIBITED_STATE_RANGE
} from './constant';
import CryptoJS from 'crypto-js';

/// <summary>
/// Author: YJ
/// </summary>
export function stringIsNullOrEmpty(data) {
  return (
    data == null ||
    (typeof data != "boolean" && data == "") ||
    data == undefined ||
    (typeof data == "string" && !data.trim())
  );
}

/// <summary>
/// Author: CK
/// </summary>
export function isString(value) {
  return typeof (value) === "string"
}

/// <summary>
/// Author : CK
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
  let formBody = [];

  for (let property in params) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");
  return formBody;
}

/// <summary>
/// Author : CK
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
  var formBody = new FormData();

  for (const [key, value] of Object.entries(params)) {
    formBody.append(key, value);
  }

  return formBody;
}

/// <summary>
/// Author: YJ
/// </summary>
export function isObjectEmpty(data) {
  return data === null || data === undefined || Object.keys(data).length === 0;
}

/// <summary>
/// Author: Saitama
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2, showDecimal = true) {
  const CURRENCY_DECIMAL = 2;
  var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

  if (decimal > CURRENCY_DECIMAL || !showDecimal) {
    let afterDecimalNumber = convertedNumber.split('.');
    let decimalDiff = decimal - CURRENCY_DECIMAL;
    if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
      let processNumber = afterDecimalNumber[1];
      for (let i = processNumber.length - 1; (i > 0 && decimalDiff > 0); i--) {
        if (processNumber.charAt(i) == '0') {
          processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
        }
        else {
          break;
        }
        decimalDiff--;
      }
      convertedNumber = afterDecimalNumber[0];

      if (showDecimal) {
        convertedNumber += "." + processNumber;
      }
    }
  }

  return convertedNumber
}

/// <summary>
/// Author: Saitama
/// </summary>
export function constructQueryString(url, filterObj) {
  if (!stringIsNullOrEmpty(url) && !isObjectEmpty(filterObj)) {
    url += (url.includes("?") ? "&" : "?") +
      Object.keys(filterObj)
        .filter((key) => !stringIsNullOrEmpty(filterObj[key]))
        .map((key) => key + "=" + encodeURIComponent(filterObj[key]))
        .join("&");
  }

  return url;
}

/// <summary>
/// Author: Saitama
/// </summary>
export function getRandomNumber(max = 10000000) {
  return Math.ceil(Math.random() * max);
}

/// <summary>
/// Author: Saitama
/// </summary>
export function sortObjectProperties(object) {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

/// <summary>
/// Author: Saitama
/// </summary>
export function constructDropdownOptions(
  data = [],
  responseTemplate = {
    label: "",
    value: "",
  },
  sortAscending = true,
  groupOptions = {
    childrenSortOptions: {
      orders: [],
      keys: [],
    },
    childrenResponseTemplate: {
      label: "",
      value: "",
    },
  }
) {
  var options = [];

  if (
    groupOptions &&
    !stringIsNullOrEmpty(groupOptions["childrenResponseTemplate"]["label"]) &&
    !stringIsNullOrEmpty(groupOptions["childrenResponseTemplate"]["value"])
  ) {
    var group = _.groupBy(data, responseTemplate["label"]);
    var keys = Object.keys(group).sort();

    if (!sortAscending) {
      Object.keys(group).sort().reverse();
    }

    options = keys.map((key) => {
      var obj = {};
      obj["label"] = key;

      if (
        groupOptions.hasOwnProperty("sortOptions") &&
        groupOptions["childrenSortOptions"]["keys"]["length"] > 0
      ) {
        if (groupOptions["childrenSortOptions"].hasOwnProperty("orders")) {
          groupOptions["childrenSortOptions"]["orders"] = [];
        }

        for (
          var i = 0;
          i < groupOptions["childrenSortOptions"]["keys"]["length"];
          i++
        ) {
          if (i + 1 > groupOptions["childrenSortOptions"]["orders"]["length"]) {
            groupOptions["childrenSortOptions"]["orders"].push("asc");
          }
        }

        obj["options"] = _.orderBy(
          obj["value"],
          groupOptions["childrenSortOptions"]["keys"],
          groupOptions["childrenSortOptions"]["orders"]
        ).map((e) => ({
          label: e[groupOptions["childrenResponseTemplate"]["label"]],
          value: e[groupOptions["childrenResponseTemplate"]["value"]],
        }));
      } else {
        obj["options"] = group[key].map((e) => ({
          label: e[groupOptions["childrenResponseTemplate"]["label"]],
          value: e[groupOptions["childrenResponseTemplate"]["value"]],
        }));
      }

      return obj;
    });
  } else {
    options = data.map((e) => ({
      label: e[responseTemplate["label"]],
      value: e[responseTemplate["value"]],
    }));

    if (sortAscending) {
      options = _.orderBy(options, "label");
    }
  }

  return options;
}

/// <summary>
/// Author: Saitama
/// </summary>
export function constructErrorMessage(
  i18n,
  formInstance,
  key,
  extraParams = {}
) {
  return formInstance.getFieldError(key).length > 0 &&
    !stringIsNullOrEmpty(formInstance.getFieldError(key)[0])
    ? i18n(formInstance.getFieldError(key)[0], { ...extraParams })
    : undefined;
}

/// <summary>
/// Author: Saitama
/// </summary>
export function stringFormat(value = "", params = []) {
  var finalValue = value;

  for (var i = 0; i < params.length; i++) {
    finalValue = finalValue.replace(`{${i}}`, params[i]);
  }

  return finalValue;
}

/// <summary>
/// Author: Saitama
/// </summary>
export async function convertBase64ToFile(
  data = { fileName: "", contentType: "", content: "" }
) {
  var response = await fetch(data.content);
  var fileData = await response.blob();

  return new File([fileData], data.fileName, { type: data.contentType });
}

/// <summary>
/// Author: Saitama
/// </summary>
export function getPageNumber(records, pageSize) {
  return Math.ceil(records / pageSize);
}

/// <summary>
/// Author: Saitama
/// </summary>
export function standardisePhoneNumber(phoneNumber) {
  return phoneNumber.replace("+", "");
}

/// <summary>
/// Author: Saitama
/// </summary>
export function constructRouteQuery(url, values) {
  return url + values.map(value => '/' + value)
}

/// <summary>
/// Author: Saitama
/// </summary>
export function getValidationMessage(error, customErrorTxt = "") {
  var validationMsg = "";

  switch (error.type) {
    case InputValidationKey._REQUIRED_KEY:
      validationMsg = "REQUIRED_INFO_MSG";
      break;
    case InputValidationKey._MAX_LENGTH_KEY:
      validationMsg = "MAX_LENGTH_EXCEEDED";
      break;
    case InputValidationKey._MIN_LENGTH_KEY:
      validationMsg = "MIN_LENGTH_NO_MET";
      break;
    case InputValidationKey._MAX_KEY:
      validationMsg = "MAX_ERROR";
      break;
    case InputValidationKey._MIN_KEY:
      validationMsg = "MIN_ERROR";
      break;
    case InputValidationKey._PATTERN:
      validationMsg = customErrorTxt;
      break;
    default:
      validationMsg = error.message
      break;
  }

  return validationMsg;
}

/// <summary>
/// Author: Saitama
/// </summary>
export function copyToClipboard(value, message, showToast = true) {
  navigator.clipboard.writeText(value);

  if (showToast) {
    toast(message, {
      type: 'info',
      position: "bottom-center",
      autoClose: 1500,
      closeOnClick: true,
      theme: 'dark'
    });
  }
}

/// <summary>
/// Author: Saitama
/// </summary>
export function removeSpecialChar(removeEmoji) {
  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/g;
  return removeEmoji.replace(regex, '');
}

/// <summary>
/// Author: Saitama
/// </summary>
export async function share(text, image) {
  let data = {};

  if (image) {
    data['files'] = [dataURLtoFile(image, `${text}.jpg`)];
  }
  else {
    data['text'] = text;
  }

  try {
    if (!(navigator.canShare(data))) {
      throw new Error("Can't share data.", data);
    }
    await navigator.share(data);
  }
  catch (err) {
    console.error(err.name, err.message);
  }
}

/// <summary>
/// Author: Saitama
/// </summary>
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mimeType = arr[0].match(/:(.*?);/)[1],
    decodedData = atob(arr[1]),
    lengthOfDecodedData = decodedData.length,
    u8array = new Uint8Array(lengthOfDecodedData);
  while (lengthOfDecodedData--) {
    u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
  }
  return new File([u8array], filename, { type: mimeType });
};

/// <summary>
/// Author: Saitama
/// </summary>
export const getPoolColor = (txt) => {
  var color;
  var str = txt.toUpperCase();

  if (str.indexOf('MAGNUM') > -1 || str.indexOf(MAGNUM_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.magnumBg;
  }
  else if (str.indexOf('DAMACAI') > -1 || str.indexOf(DAMACAI_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.damacaiBg;
  }
  else if (str.indexOf('SINGAPORE') > -1 || str.indexOf(SINGAPORE_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.singaporeBg;
  }
  else if (str.indexOf('SABAH') > -1 || str.indexOf(SABAH_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.sabahBg;
  }
  else if (str.indexOf('SANDAKAN') > -1 || str.indexOf('STC') > -1 || str.indexOf(SANDAKAN_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.sandakanBg;
  }
  else if (str.indexOf('CASH') > -1 || str.indexOf(CASH_SWEEP_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.cashSweepBg;
  }
  else if (str.indexOf('TOTO') > -1 || str.indexOf(TOTO_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.totoBg;
  }
  else if (str.indexOf('GRAND') > -1 || str.indexOf(GRAND_DRAGON_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.grandDragonBg;
  }
  else if (str.indexOf('NINE') > -1 || str.indexOf(NINE_LOTTO_POOL_NAME.toUpperCase()) > -1) {
    color = PoolColor.nineLottoBg;
  }
  else {
    color = '#08CD34';
  }

  return color;
}

/// <summary>
/// Author: Saitama
/// </summary>
export const getPoolFontColor = (txt) => {
  var color = 'white';
  var str = txt.toUpperCase();

  if (str.indexOf('MAGNUM') > -1 || str.indexOf(MAGNUM_POOL_NAME.toUpperCase()) > -1) {
    color = 'black';
  }

  return color;
}

/// <summary>
/// Author: Saitama
/// </summary>
export const permutation = (v, opts) => {
  if (!v) return [];
  opts = opts || {};
  function p(prefix, s, acc) {
    acc = acc || [];
    var n = s.length;
    if (n === 0) return acc.push(prefix);
    for (var i = 0; i < n; i++) {
      p(prefix + s.charAt(i), s.substring(0, i) + s.substring(i + 1), acc);
    }
    return opts.unique ? unique(acc) : acc;
  }

  return p('', v);
}

/// <summary>
/// Author: Saitama
/// </summary>
function uniqueFilter(value, index, self) {
  return self.indexOf(value) === index;
}

/// <summary>
/// Author: Saitama
/// </summary>
function unique(a) {
  return a.filter(uniqueFilter);
}

/// <summary>
/// Author: Saitama
/// </summary>
export function isTicketCancelled(status, isCancelled = false) {
  return isCancelled || status == CANCELLED_STATUS_TXT;
}

/// <summary>
/// Author: Saitama
/// </summary>
export const getUserCurrentLocation = async () => {
  try {
    const pos = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    // lat - 2.5574, long - 113.0012
    return {
      longitude: pos?.coords?.longitude,
      latitude: pos?.coords?.latitude,
    };
  }
  catch (err) {
    return {
      longitude: '',
      latitude: '',
    };
  }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const checkIsStateRangeValid = (latitude, longitude) => {
  let isValid = true;

  if (stringIsNullOrEmpty(latitude) || stringIsNullOrEmpty(longitude)) {
      isValid = false;
  }
  else if (latitude >= _PROHIBITED_STATE_RANGE.minLat && latitude <= _PROHIBITED_STATE_RANGE.maxLat &&
      longitude >= _PROHIBITED_STATE_RANGE.minLng && longitude <= _PROHIBITED_STATE_RANGE.maxLng) {
      isValid = false;
  }

  return isValid;
}

/// <summary>
/// Author: Saitama
/// </summary>
export const createHMAC = (message, secretKey) => {
  const hmac = CryptoJS.HmacSHA256(message, secretKey);
  const hmacBase64 = CryptoJS.enc.Hex.stringify(hmac);
  return hmacBase64;
};

/// <summary>
/// Author: Juin
/// </summary>
export const checkBrowser = () => {
  return window.innerWidth >= ScreenWidth._BROWSER;
}

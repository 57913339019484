import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setBusy, setIdle } from '../application/action/app_action';
import { getMemberDetails } from '../application/action/auth_action';
import { Box, Flex, Divider, Text, Image, Spacer, Button, Stack } from '@chakra-ui/react';
import { checkBrowser, numberWithCurrencyFormat } from '../common/util';

/// <summary>
/// Author: Saitama
/// </summary>
export default ({
    isReward = false,
    amount,
    onRefresh
}) => {
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const { credit, reward } = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const refreshHandler = () => {
        _dispatch(setBusy());

        try {
            _dispatch(getMemberDetails(false));

            if (onRefresh) {
                onRefresh();
            }
        }
        finally {
            _dispatch(setIdle());
        }
    }

    if (!checkBrowser()) {
        return (
            <Stack>
                <Divider className='app-divider-full-width-setting' />
                <Flex>
                    <Spacer />
                    <Flex align={'center'}>
                        <Text mr={2}>{t('WALLET')}</Text>
                        <Box
                            position={'relative'}
                            paddingInline={5}
                            paddingBlock={.5}
                            borderColor={'#C6D1E2'}
                            borderWidth={'1px'}
                            borderRadius={'15px'}>
                            <Image
                                style={{
                                    position: 'absolute',
                                    left: '-10px',
                                    top: '-5px'
                                }}
                                boxSize={'28px'}
                                src={require('../assets/images/topbar-icon-credit.png')} />
                            <Text>{numberWithCurrencyFormat(credit ?? 0)}</Text>
                        </Box>
                    </Flex>
                    <Spacer />
                    <Flex align={'center'}>
                        <Text mr={2}>{t(isReward ? "REWARD" : "SCORE")}</Text>
                        <Box
                            position={'relative'}
                            paddingInline={5}
                            paddingBlock={.5}
                            borderColor={'#C6D1E2'}
                            borderWidth={'1px'}
                            borderRadius={'15px'}>
                            <Image
                                style={{
                                    position: 'absolute',
                                    left: '-10px',
                                    top: '-5px'
                                }}
                                boxSize={'28px'}
                                src={isReward
                                    ? require('../assets/images/topbar-icon-gift.png')
                                    : require('../assets/images/topbar-icon-score.png')} />
                            <Text>{numberWithCurrencyFormat(amount ?? reward)}</Text>
                        </Box>
                    </Flex>
                    <Button variant='link' onClick={refreshHandler}>
                        <Image
                            boxSize={'30px'}
                            src={require('../assets/images/refresh.png')} />
                    </Button>
                    <Spacer />
                </Flex>
            </Stack>
        )
    } else {
        return (
            <Stack>
                <Flex>
                    <Spacer />
                    <Flex align={'center'}>
                        <Text mr={4}>{t('ECREDIT')}</Text>
                        <Box
                            position={'relative'}
                            paddingInline={5}
                            paddingBlock={.5}
                            borderColor={'#C6D1E2'}
                            borderWidth={'1px'}
                            borderRadius={'15px'}>
                            <Image
                                style={{
                                    position: 'absolute',
                                    left: '-15px',
                                    top: '-3px'
                                }}
                                boxSize={'32px'}
                                src={require('../assets/images/topbar-icon-credit.png')} />
                            <Text>{numberWithCurrencyFormat(credit ?? 0)}</Text>
                        </Box>
                    </Flex>
                    <Spacer />
                    <Flex align={'center'}>
                        <Text mr={4}>{t(isReward ? "REWARD" : "EPOINT")}</Text>
                        <Box
                            position={'relative'}
                            paddingInline={5}
                            paddingBlock={.5}
                            borderColor={'#C6D1E2'}
                            borderWidth={'1px'}
                            borderRadius={'15px'}>
                            <Image
                                style={{
                                    position: 'absolute',
                                    left: '-15px',
                                    top: '-3px'
                                }}
                                boxSize={'32px'}
                                src={isReward
                                    ? require('../assets/images/topbar-icon-gift.png')
                                    : require('../assets/images/topbar-icon-score.png')} />
                            <Text>{numberWithCurrencyFormat(amount ?? reward)}</Text>
                        </Box>
                    </Flex>
                    <Button variant='link' onClick={refreshHandler} ml={1}>
                        <Image
                            boxSize={'30px'}
                            src={require('../assets/images/refresh.png')} />
                    </Button>
                    <Spacer />
                </Flex>
            </Stack>
        )
    }
}
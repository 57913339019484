import React, { useEffect, useState, useMemo } from "react";
import { Text } from "@chakra-ui/react";
import DetailTopBar from "../../component/detail-top-bar";
import BottomBar from "../../component/bottom-bar";
import { SessionKey, BOTTOM_BAR_TITLE, BOTTOM_BAR, ScreenWidth } from "../../common/constant";
import { stringIsNullOrEmpty, checkBrowser } from "../../common/util";
import Games from "./games/index";
import Transactions from "./transactions";
import Chat from "./chat";
import Bonus from "./bonus";
import Epay from "./epay";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router";
import { Pages } from "../../routes/page";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const [content, setContent] = useState();
    const _navigate = useNavigate();

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (checkBrowser()) {
            _navigate(Pages._GAMES);
        }
    }, []);

    const RenderContent = useMemo(() => {
        let menuToUse = BOTTOM_BAR.find(bb => bb.id == content)?.title;
        window.scrollTo(0, 0);

        switch (menuToUse) {
            case BOTTOM_BAR_TITLE._GAME:
                return <Games />
            case BOTTOM_BAR_TITLE._TRANSACTION:
                return <Transactions />
            case BOTTOM_BAR_TITLE._CHAT:
                return <Chat />
            case BOTTOM_BAR_TITLE._SPIN:
                return <Bonus />
            case BOTTOM_BAR_TITLE._EPAY:
                return <Epay />
            default:
                return <Games />
        }
    }, [content]);

    return (
        <div>
            <DetailTopBar />
            {/* <RenderContent menuId={content} /> */}
            {RenderContent}
            <BottomBar onMenuChange={(item) => setContent(item)} />
        </div>
    )
}
import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PageSettings } from "../../routes/setting";
import {
    Stack, Text, Image, Spacer, Card, CardBody, CardFooter, Flex, Divider, Button, HStack, Center,
} from '@chakra-ui/react';
import CustomInput from "../../component/input";
import { useForm, Controller } from 'react-hook-form';
import { ValidationPattern, _BRANCH_IO_KEY, _LANGUAGE_OPTION, _RECEIPT_WATERMARK, ScreenWidth } from "../../common/constant";
import { useRouter } from "../../hooks/useRouter";
import { Pages } from "../../routes/page";
import Branch from 'branch-sdk';
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat, getUserCurrentLocation, checkBrowser } from "../../common/util";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showCustomDialog, disposeCustomDialog } from "../../application/action/app_action";
import ApiEngine from "../../common/api_engine";
import Routes from "../../common/api_routes";
import { ApiKey } from "../../common/constant";
import { isMobile } from "react-device-detect";
import Footer from "./footer";
import Header from "./header";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { i18n, t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _dispatch = useDispatch();
    const [branchReferral, setBranchReferral] = useState('');

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        Branch.init(_BRANCH_IO_KEY, function (err, data) {
            const deepLinkData = data?.data;

            if (!stringIsNullOrEmpty(deepLinkData)) {
                let deepLinkJson = JSON.parse(deepLinkData);

                setBranchReferral(deepLinkJson?.$og_title);
                setValue('referralCode', deepLinkJson?.$og_title);
            }
        });
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('phoneNo')) && !stringIsNullOrEmpty(watch('alias')) && !stringIsNullOrEmpty(watch('referralCode')));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            _dispatch(setBusy());
            const location = await getUserCurrentLocation();
            window.scrollTo(0, 0);

            let params = {
                "PhoneNumber": data.phoneNo,
                "IsEmulator": false,
                "Alias": data.alias,
                "ReferralCode": data.referralCode,
                'lat': location?.latitude,
                'lng': location?.longitude
            };

            var formBody = createFormBody(params);
            var responseJson = await ApiEngine.post(Routes._REGISTER_URL, formBody, { headers: { "skipLoading": true } });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            if (!stringIsNullOrEmpty(responseJson?.data?.bonus_amount) && responseJson?.data?.bonus_amount > 0) {
                _dispatch(showCustomDialog({
                    success: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: t('WELCOME_BONUS'),
                    customContent: (
                        <>
                            <Stack spacing={0}>
                                <Text color={'#C6D1E2'} fontSize={'md'}>
                                    {t('GREAT_NEWS_YOU_ENTITLED_WELCOME_BONUS')}
                                </Text>
                                <Text fontWeight={'bold'} color={'#38b750'} fontSize={'lg'}>{`RM ${responseJson?.data?.bonus_amount}. \n`}</Text>
                            </Stack>
                            <Stack marginBlock={0.5}>
                                <Text color={'#C6D1E2'} fontSize={'sm'} fontStyle={'italic'}>
                                    {t('WELCOME_BONUS_REQUIRED_CERTAIN_TURNOVER_TO_UNLOCK_1')}
                                    <Text as={'span'} color={'#38b750'} fontWeight={'bold'} fontSize={'lg'} fontStyle={'italic'}>{numberWithCurrencyFormat(responseJson?.data?.bonus_min_threshold)}</Text>
                                    <Text as={'span'} color={'#C6D1E2'} fontSize={'sm'} fontStyle={'italic'}>{t('WELCOME_BONUS_REQUIRED_CERTAIN_TURNOVER_TO_UNLOCK_2')}</Text>
                                    <Text as={'span'} color={'#38b750'} fontWeight={'bold'} fontSize={'lg'} fontStyle={'italic'}>{numberWithCurrencyFormat(responseJson?.data?.bonus_max_threshold)}</Text>
                                    <Text as={'span'} color={'#C6D1E2'} fontSize={'sm'} fontStyle={'italic'}>{t('WELCOME_BONUS_REQUIRED_CERTAIN_TURNOVER_TO_UNLOCK_3')}</Text>
                                </Text>
                            </Stack>
                            <Stack>
                                <Text color={'#C6D1E2'} fontSize={'md'}>{t('TO_CLAIM_AND_ENJOY', { user: data.phoneNo })}</Text>
                            </Stack>
                        </>),
                    confirmTxt: t('YES_I_WANT'),
                    onConfirm: () => {
                        _dispatch(disposeCustomDialog());
                        _router.navigate(Pages._CREATE_OR_UPDATE_BANK,
                            { bankAccountHolderName: '', userBankId: '', isNewUser: true, bonus: responseJson?.data?.bonus_amount, userId: responseJson?.data?.user_id, username: data.phoneNo })
                    },
                    cancelTxt: t('NO_THANKS'),
                    onCancel: () => {
                        _dispatch(disposeCustomDialog());
                        _router.navigate(Pages._DEFAULT, { username: data.phoneNo }, true)
                    },
                }));
            }
            else {
                _dispatch(showCustomDialog({
                    success: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: t('CONGRAT_REGISTER_SUCCESSFULLY_FIRST_TIME_PASSWORD_WILL_SEND_TO_YOUR_PHONE', { user: data.phoneNo }),
                    isManualHideAlert: true,
                    disableHardwareBackPress: true,
                    onConfirm: () => {
                        _dispatch(disposeCustomDialog());
                        _router.navigate(Pages._DEFAULT, { username: data.phoneNo }, true);
                    }
                }));
            }
        }
        catch (error) {
            _dispatch(showCustomDialog({ success: false, content: error }));
        }
        finally {
            _dispatch(setIdle());
        }
    }

    if (!checkBrowser()) {
        return (
            <Stack spacing={5}>
                <Flex>
                    <Text noOfLines={2} fontSize='3xl' as='b' width='45%' >{t('REGISTER_A_NEW_MEMBER')}</Text>
                    <Spacer />
                    <Image boxSize='100px' objectFit='contain' src={require('../../assets/images/logo.png')} alt='BETMAN' />
                </Flex>
                <Text fontSize='lg'>{t('HELLO_REGISTER_NOW')}</Text>
                <Card backgroundColor={'#232E46'}>
                    <CardBody>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Controller
                                control={control}
                                name="phoneNo"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        label='ENTER_MOBILE_NUMBER'
                                        inputType="tel"
                                        errors={errors}
                                        patternErrorTxt={t(
                                            'PLEASE_FILL_VALID_PHONE_NO'
                                        )} />
                                )}
                                rules={{
                                    required: true,
                                    pattern:
                                        ValidationPattern._PHONE_NUMBER_REGEX
                                }}
                            />
                            <Controller
                                control={control}
                                name="alias"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        errors={errors}
                                        label='ENTER_DISPLAY_NAME' />
                                )}
                                rules={{
                                    required: true,
                                    maxLength: 32,
                                    pattern: ValidationPattern._ALPHANUMBERIC_REGEX
                                }}
                            />
                            <Controller
                                control={control}
                                name="referralCode"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        errors={errors}
                                        label='ENTER_REFERRAL_CODE'
                                        isReadOnly={!stringIsNullOrEmpty(branchReferral)} />
                                )}
                                rules={{
                                    required: true
                                }}
                            />
                            <Button className="grey-button" w='100%' type='submit'>{t('REGISTER')}</Button>
                        </form>
                    </CardBody>
                    <Divider className="app-divider" />
                    <CardFooter justify={'center'}>
                        <Text color={'app.grey'}>{t('ALREADY_HAVE_AN_ACCOUNT')}</Text>
                        <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._LOGIN, {}, true) }}><Text color={'app.blue'} decoration='underline'>{t('SIGN_IN_NOW')}</Text></Button>
                    </CardFooter>
                </Card>
            </Stack>
        )
    } else {
        return (
            <>
                <Center margin="50px auto 0 auto">
                    <HStack spacing='100px'>
                        <div>
                            <Image boxSize='250px' objectFit='contain' src={require('../../assets/images/logo.png')} alt='BETMAN' />
                        </div>
                        <div>
                            <Text fontSize='2xl' fontWeight={'bold'} marginBottom={"5px"}>{t('REGISTER_AS_NEW_MEMBER')}</Text>
                            <Text fontSize='md' color={"white"} marginBottom={"35px"}>{t('HELLO_AND_WELCOME_PLEASE_LOG_IN')}</Text>
                            <Card border="1px solid white" borderRadius="15px" backgroundColor={'#232E46'} padding="12px 5px" minWidth="480px">
                                <CardBody>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    label='ENTER_MOBILE_NUMBER'
                                                    inputType="tel"
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    errors={errors}
                                                    patternErrorTxt={t(
                                                        'PLEASE_FILL_VALID_PHONE_NO'
                                                    )} />
                                            )}
                                            rules={{
                                                required: true,
                                                pattern:
                                                    ValidationPattern._PHONE_NUMBER_REGEX
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="alias"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    errors={errors}
                                                    label='ENTER_DISPLAY_NAME'
                                                    height="50px"
                                                    borderRadius="10px !important" />
                                            )}
                                            rules={{
                                                required: true,
                                                maxLength: 32,
                                                pattern: ValidationPattern._ALPHANUMBERIC_REGEX
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="referralCode"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    errors={errors}
                                                    label='ENTER_REFERRAL_CODE'
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    marginBottom="10px"
                                                    isReadOnly={!stringIsNullOrEmpty(branchReferral)} />
                                            )}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <Button style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#303e5d", color: "white", padding: "25px 0px" }} w='100%' type='submit'>{t('REGISTER')}</Button>
                                    </form>
                                </CardBody>
                                <CardFooter justify={'center'}>
                                    <Text color={'app.grey'}>{t('ALREADY_HAVE_AN_ACCOUNT')}</Text>
                                    <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._LOGIN, {}, true) }}><Text color={'app.blue'} decoration='underline' fontWeight='normal'>{t('LOGIN_NOW')}</Text></Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </HStack>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Stack, Button, Box, Flex, Input, InputGroup, InputRightElement, Image, Center, Spacer, Divider } from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { Pages } from "../../../../routes/page";
import { showCustomDialog } from "../../../../application/action/app_action";
import { isObjectEmpty, numberWithCurrencyFormat } from "../../../../common/util";
import { ApiKey, BG_TYPE, OrderMode } from "../../../../common/constant";
import CustomPicker from "../../../../component/custom-picker";
import moment from "moment";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _GUID = useSelector(state => state.authState.userData?.guid);
    const _MINI_PROGRAM = _location?.state?.miniProgram;
    const _SELECTED_VENDOR = _location?.state?.selectedVendor;
    const [navigationPlayerId, setNavigationPlayerId] = useState(_location?.state?.playerId);
    const [playerItem, setPlayerItem] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [showPlayerPicker, setShowPlayerPicker] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_MINI_PROGRAM)) {
            _router.back();
        }
        else {
            getPlayerList();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getPlayerList = async () => {
        try {
            var response = await ApiEngine.get(Routes._SWC_GET_PLAYER_LIST + "?vendorId=" + _MINI_PROGRAM.vendorId);

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw (response.Message ?? response.message ?? response.error ?? response.title);
            }

            setPlayerItem(response[ApiKey._API_DATA_KEY].map(item => {
                return { label: item.vendorPlayerId, value: item.id, imagePath: _MINI_PROGRAM?.programThumbnail }
            }));

            // Get player detail
            if (navigationPlayerId && response[ApiKey._API_DATA_KEY].filter(player => player.id == navigationPlayerId).length > 0) {
                getPlayerDetail(navigationPlayerId);
                setNavigationPlayerId(null);
            }
            else {
                getPlayerDetail(selectedPlayer ? selectedPlayer.id : response[ApiKey._API_DATA_KEY][0].id);
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: t(err) }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getPlayerDetail = async (playerId) => {
        try {
            var response = await ApiEngine.get(Routes._SWC_GET_PLAYER_DETAILS + "?id=" + playerId);

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw (response.Message ?? response.message ?? response.error ?? response.title);
            }
            setSelectedPlayer({ ...response[ApiKey._API_DATA_KEY], label: response[ApiKey._API_DATA_KEY].vendorPlayerid, value: response[ApiKey._API_DATA_KEY].id });
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: t(err) }));
        }
    }

    return (
        <div className='body-content'>
            <GeneralTopBar bgColor="#ebffe3" useSimpleBackIcon />
            <Flex align={'center'}>
                <Text color={'black'} fontSize={'sm'} as='b'>{t('CURRENT_PLAYER') + ": "}</Text>
                <InputGroup w={'65vw'}>
                    <Input
                        isReadOnly
                        value={selectedPlayer?.label}
                        variant='flushed'
                        borderBottomColor={'black'}
                        pl={3}
                        ml={3}
                        onClick={() => setShowPlayerPicker(!showPlayerPicker)}
                    />
                    <InputRightElement>
                        <Image boxSize={'10px'} src={require('../../../../assets/images/triangular-black-arrow-pointing-down.png')} />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <Flex mt={3.5} align={'baseline'}>
                <Text fontWeight={'bold'} fontSize={'2xl'} color={'black'}>
                    {`RM ${numberWithCurrencyFormat(selectedPlayer?.gameScore ?? 0)}`}
                </Text>
                {
                    selectedPlayer &&
                    <Button ml={5} variant={'solid'} bg={'white'} onClick={() => {
                        _router.navigate(Pages._CONVERT_E_CREDIT_OR_E_POINT, {
                            selectedVendor: {
                                label: _SELECTED_VENDOR.gameName
                            },
                            selectedPlayer: {
                                id: selectedPlayer.id,
                                vendorPlayerid: selectedPlayer.vendorPlayerid,
                                gameScore: selectedPlayer.gameScore
                            },
                            isRequiredNavigateBack: true
                        })
                    }}>
                        <Text fontSize={'sm'} color={'#08CD34'}>{`${t('RELOAD')} +`}</Text>
                    </Button>
                }
                <Spacer />
                <Box borderRadius={50} backgroundColor={'white'} p={2}>
                    <Center>
                        <Image boxSize={'40px'} src={_MINI_PROGRAM?.programThumbnail} />
                    </Center>
                </Box>
            </Flex>
            <Flex mb={5}>
                <Text color={'black'} fontWeight={'bold'}>{t('CREDIT_BALANCE')}</Text>
                <Spacer />
                <Text color={'black'} fontWeight={'bold'}>{moment().format('DD/MM/YYYY')}</Text>
            </Flex>
            <Box bg={'white'} borderRadius={10} mb={5}>
                <Flex p={2} align={'center'} ml={5} onClick={() => { _router.navigate(Pages.SWC_ORDER_HISTORY, {playerId: selectedPlayer.id}); }}>
                    <img style={{ width: '35px', height: '35px' }} src={require('./assets/images/receipt.svg').default} />
                    <Text fontSize={'sm'} color={'#08CD34'} fontWeight={'bold'} ml={5}>{t('ORDER_HISTORY')}</Text>
                </Flex>
                <Divider />
                <Flex p={2} align={'center'} ml={5} onClick={() => { _router.navigate(Pages.SWC_STRIKE_HISTORY, {playerId: selectedPlayer.id}); }}>
                    <img style={{ width: '35px', height: '35px' }} src={require('./assets/images/strike.svg').default} />
                    <Text fontSize={'sm'} color={'#08CD34'} fontWeight={'bold'} ml={5}>{t('STRIKE_HISTORY')}</Text>
                </Flex>
                <Divider />
                <Flex p={2} align={'center'} ml={5} onClick={() => { _router.navigate(Pages.SWC_PACKAGE_LIST, {playerId: selectedPlayer.id}); }}>
                    <img style={{ width: '35px', height: '35px' }} src={require('./assets/images/green-package.svg').default} />
                    <Text fontSize={'sm'} color={'#08CD34'} fontWeight={'bold'} ml={5}>{t('PACKAGE_LIST')}</Text>
                </Flex>
            </Box>
            <Box
                backgroundColor={'#F5F4FA'}
                borderTopLeftRadius={30}
                borderTopRightRadius={30}
                p={5}
                marginInline={'-3.75vw'}
                overflowY={'hidden'}
                height={'75vh'}>
                <Text fontSize={'md'} color={'#08CD34'} fontWeight={'bold'} mb={1}>{t('ORDER')}</Text>
                <Box className="order-box-bg" mb={5}>
                    <Box p={5} onClick={() => {
                        _router.navigate(Pages.SWC_ORDER, { 
                            title: OrderMode._ORDER_MODE_4D, 
                            orderMode: OrderMode._ORDER_MODE_4D, 
                            gameName: _SELECTED_VENDOR.gameName,
                            playerId: selectedPlayer.id 
                        });
                    }}>
                        <Text color={'black'} fontSize={'md'} fontWeight={'bold'}>4D | 5D | 6D | 3D</Text>
                        <Text color={'#939393'} fontWeight={'700'}>{t('ORDER')}</Text>
                    </Box>
                    <Flex
                        backgroundColor={'#08CD34'}
                        w='full'
                        bottom={0}
                        position={'absolute'}
                        borderBottomLeftRadius={'15px'}
                        borderBottomRightRadius={'15px'}
                        p={2}
                        paddingInline={5}>
                        <Text fontSize={'sm'} fontWeight={'750'}>{t('ORDER_NOW')}</Text>
                        <Spacer />
                        <Image boxSize={'20px'} src={require('./assets/images/right-arrow.png')} />
                    </Flex>
                </Box>
                <Text fontSize={'md'} color={'#08CD34'} fontWeight={'bold'} mb={1}>{t('VIEW_RESULTS')}</Text>
                <Box className="result-box-bg" onClick={() => { _router.navigate(Pages.SWC_VIEW_RESULTS, { playerId: selectedPlayer.id }); }} />
            </Box>
            <CustomPicker
                showWhiteBg
                isVisible={showPlayerPicker}
                title={'SELECT_PLAYER_ID'}
                options={playerItem}
                selectedOption={selectedPlayer}
                onSelect={(isSelected = true, value) => {
                    if (isSelected) {
                        if (selectedPlayer && value != selectedPlayer.id) {
                            getPlayerDetail(value);
                        }
                        setSelectedPlayer(value);
                    }
                    setShowPlayerPicker(!showPlayerPicker);
                }}
            />
        </div>
    )
}
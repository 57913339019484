import React, { useEffect, useState } from 'react';
import { useRouter } from '../../../hooks/useRouter';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiEngine from '../../../common/api_engine';
import Routes from '../../../common/api_routes';
import { Pages } from '../../../routes/page';
import { showCustomDialog } from '../../../application/action/app_action';
import { ApiKey, ValidationPattern } from '../../../common/constant';
import { numberWithCurrencyFormat, createFormBody, removeSpecialChar, stringIsNullOrEmpty, checkBrowser } from '../../../common/util';
import PasswordModal from '../../../component/password-modal';
import { Stack, Image, Button, Text, Flex, SimpleGrid, Divider, Box, Card, CardBody, HStack } from '@chakra-ui/react';
import CustomInput from '../../../component/input';
import CurrencyInput from 'react-currency-input-field';

/// <summary>
/// Author: Saitama
/// </summary>
const PaymentReload = ({ service, serviceDetail, type, memberGuid, successImage }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const { handleSubmit, errors, control, setValue, watch } = useForm();
    const [selectedPayment, setSelectedPayment] = useState(serviceDetail.services[0]);
    const _REQUIRED_AMOUNT_FIELD = selectedPayment.max_denom != selectedPayment.min_denom
    const _INPUT_TYPE_PHONE = 'phone_no';
    const _INPUT_TYPE_ACCOUNT = 'acc_no';
    const [authorizationModal, setAuthorizationModal] = useState({
        password: '',
        visible: false
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        setValue('amount', _REQUIRED_AMOUNT_FIELD ? '' : selectedPayment.min_denom.toString());
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        if (_REQUIRED_AMOUNT_FIELD) {
            return (!stringIsNullOrEmpty(watch('accountNo')) && !stringIsNullOrEmpty(watch('amount')));
        } else {
            return (!stringIsNullOrEmpty(watch('accountNo')));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data) => {
        setAuthorizationModal({ ...authorizationModal, visible: true });
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function submitPayment(authorization) {
        let params = {
            'amount': watch('amount') * 100,
            'serviceId': selectedPayment.id,
            'type': type,
            'memberGuid': memberGuid,
            'deviceId': window.navigator.userAgent,
            'enableVerification': true,
            'accountNo': watch('accountNo'),
            'password': authorization.password
        };

        var responseJson = await ApiEngine.post(Routes._EPAY_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._RELOAD_SUCCESS, {
                instruction: responseJson[ApiKey._API_DATA_KEY].details.replace('Instruction-\n', ''),
                amount: 'RM ' + numberWithCurrencyFormat(parseFloat(watch('amount'))),
                headerTitle: 'SUCCESSFULLY_PAID',
                contentImage: successImage,
                details: [
                    { label: 'SERVICE', value: selectedPayment.service_name },
                    { label: 'TRANSACTION_NO', value: responseJson[ApiKey._API_DATA_KEY].trans_ref },
                    {
                        label: selectedPayment.input_type == _INPUT_TYPE_PHONE ? 'PHONE_NUMBER'
                            : 'ACCOUNT_NUMBER', value: watch('accountNo')
                    }
                ]
            });
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <Flex align={'flex-end'} mb={5}>
                    <Button
                        variant={'link'}
                        alignItems={'center'}
                        borderRadius={50}
                        backgroundColor={'#C6D1E2'}
                        borderWidth={2.5}
                        borderColor={'#A0B1CB'}
                        p={3}
                        disabled >
                        <Image boxSize={'35px'} src={service.img} />
                    </Button>
                    <Text fontSize={'md'} ml={5}>{service.ssc_name}</Text>
                </Flex>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Controller
                        control={control}
                        name="accountNo"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                onChange={(e) =>
                                    onChange(removeSpecialChar(e.target.value))
                                }
                                value={value}
                                label={selectedPayment.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}
                                errors={errors}
                                patternErrorTxt={t(
                                    'PLEASE_FILL_VALID_PHONE_NO'
                                )} />
                        )}
                        rules={{
                            required: true
                        }}
                    />
                    {
                        _REQUIRED_AMOUNT_FIELD &&
                        <Stack spacing={0}>
                            <Text fontSize={'sm'}>{t('AMOUNT')}</Text>
                            <Box w='full' h='45px' background={'#192031'} borderRadius={5} borderColor='#334060' borderWidth={2} mt={2}>
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CurrencyInput
                                            style={{
                                                textAlign: 'start',
                                                backgroundColor: 'transparent',
                                                color: '#AACBFF',
                                                fontWeight: '500',
                                                fontSize: '1rem',
                                                width: '100%',
                                                height: '100%',
                                                paddingLeft: '10px'
                                            }}
                                            id={name}
                                            name={name}
                                            value={value}
                                            placeholder="0.00"
                                            allowNegativeValue
                                            decimalScale={2}
                                            autoComplete={false}
                                            onFocus={(e) => e.target.select()}
                                            onValueChange={(value, name) => {
                                                onChange(value);
                                            }}
                                        />
                                    )}
                                    rules={{
                                        required: _REQUIRED_AMOUNT_FIELD,
                                        pattern: ValidationPattern._DECIMAL_REGEX,
                                        maxLength: 8,
                                        minLength: 1,
                                        min: selectedPayment.min_denom,
                                        max: selectedPayment.max_denom
                                    }}
                                />
                            </Box>
                            {
                                errors && errors['amount'] &&
                                <Text color={'red'} mt={3} fontSize={'sm'}>{t('AMOUNT_ERROR_TXT')}</Text>
                            }
                        </Stack>
                    }
                    {
                        !_REQUIRED_AMOUNT_FIELD || serviceDetail.services.length > 1 ?
                            <SimpleGrid columns={3} spacing={5} mt={5}>
                                {
                                    serviceDetail.services.map((item, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                className={`amount-button ${item.id == selectedPayment.id ? 'selected' : ''}`}
                                                whiteSpace={"normal"} height={"auto"} blockSize={"auto"}
                                                onClick={() => {
                                                    setSelectedPayment(item);
                                                    if (!_REQUIRED_AMOUNT_FIELD) {
                                                        setValue('amount', item.min_denom);
                                                    }
                                                }}>
                                                <Text>{item.service_name}</Text>
                                            </Button>
                                        )
                                    })
                                }
                            </SimpleGrid> : <></>

                    }
                    <Stack className="bottom-sticky-button">
                        <Flex
                            style={{
                                padding: '1rem',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                backgroundColor: 'rgba(0,0,0, 0.9)'
                            }}>
                            <Stack spacing={0}>
                                <Text color={'app.blue'} fontSize={'lg'}>{t('TOTAL_AMOUNT')}</Text>
                                <Flex align={'center'}>
                                    <Image boxSize={'30px'} src={require('../../../assets/images/topbar-icon-credit.png')} />
                                    <Text fontSize={'md'} fontWeight={'bold'}>{`RM ${stringIsNullOrEmpty(watch('amount')) || isNaN(parseFloat(watch('amount'))) ? '0.00' : numberWithCurrencyFormat(parseFloat(watch('amount')))}`}</Text>
                                </Flex>
                            </Stack>
                            <Button
                                type='submit'
                                className="grey-gradient-button"
                                style={{ borderRadius: '10px', width: '40%', marginLeft: '5vw' }}>
                                {t('CONFIRM_PAYMENT')}
                            </Button>
                        </Flex>
                    </Stack>
                </form>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            submitPayment(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    } else {
        return (
            <>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Card>
                        <CardBody bg={'#2c3956'} pb={24} paddingInline={8} pt={5}>
                            <HStack spacing={5} mb={6}>
                                <Button
                                    variant={'link'}
                                    alignItems={'center'}
                                    borderRadius={50}
                                    backgroundColor={'#C6D1E2'}
                                    borderWidth={2.5}
                                    borderColor={'#A0B1CB'}
                                    p={2}
                                    disabled >
                                    <Image boxSize={9} src={service.img} />
                                </Button>
                                <Text fontSize={'md'}>{service.ssc_name}</Text>
                            </HStack>
                            <Controller
                                control={control}
                                name="accountNo"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(e) =>
                                            onChange(removeSpecialChar(e.target.value))
                                        }
                                        value={value}
                                        borderRadius="15px !important"
                                        boxShadow="none !important"
                                        label={selectedPayment.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}
                                        errors={errors}
                                        patternErrorTxt={t(
                                            'PLEASE_FILL_VALID_PHONE_NO'
                                        )} />
                                )}
                                rules={{
                                    required: true
                                }}
                            />
                            {
                                _REQUIRED_AMOUNT_FIELD &&
                                <Stack spacing={0}>
                                    <Text fontSize={'md'}>{t('AMOUNT')}</Text>
                                    <Box w='full' h='43px' background={'#192031'} borderRadius={15} borderColor='#334060' borderWidth={2} mt={2} _hover={{ border: '2px solid white' }} transitionDuration={'normal'}>
                                        <Controller
                                            control={control}
                                            name="amount"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CurrencyInput
                                                    style={{
                                                        textAlign: 'start',
                                                        backgroundColor: 'transparent',
                                                        color: '#AACBFF',
                                                        fontWeight: '500',
                                                        fontSize: '1rem',
                                                        width: '100%',
                                                        height: '100%',
                                                        paddingLeft: '15px',
                                                        borderRadius: '15px'
                                                    }}
                                                    id={name}
                                                    name={name}
                                                    value={value}
                                                    placeholder="0.00"
                                                    allowNegativeValue
                                                    decimalScale={2}
                                                    autoComplete={false}
                                                    onFocus={(e) => e.target.select()}
                                                    onValueChange={(value, name) => {
                                                        onChange(value);
                                                    }}
                                                />
                                            )}
                                            rules={{
                                                required: _REQUIRED_AMOUNT_FIELD,
                                                pattern: ValidationPattern._DECIMAL_REGEX,
                                                maxLength: 8,
                                                minLength: 1,
                                                min: selectedPayment.min_denom,
                                                max: selectedPayment.max_denom
                                            }}
                                        />
                                    </Box>
                                    {
                                        errors && errors['amount'] &&
                                        <Text color={'red'} fontSize={'sm'} mt={2}>{t('AMOUNT_ERROR_TXT')}</Text>
                                    }
                                </Stack>
                            }
                            {
                                !_REQUIRED_AMOUNT_FIELD || serviceDetail.services.length > 1 ?
                                    <SimpleGrid columns={3} spacing={5} mt={10}>
                                        {
                                            serviceDetail.services.map((item, index) => {
                                                return (
                                                    <Button
                                                        key={index}
                                                        paddingInline={8}
                                                        paddingBlock={4}
                                                        className={`amount-button ${item.id == selectedPayment.id ? 'selected' : ''}`}
                                                        whiteSpace={"normal"} height={"auto"} blockSize={"auto"}
                                                        onClick={() => {
                                                            setSelectedPayment(item);
                                                            if (!_REQUIRED_AMOUNT_FIELD) {
                                                                setValue('amount', item.min_denom);
                                                            }
                                                        }}>
                                                        <Text fontSize={'sm'}>{item.service_name}</Text>
                                                    </Button>
                                                )
                                            })
                                        }
                                    </SimpleGrid> : <></>

                            }
                        </CardBody>
                    </Card>
                    <HStack spacing={5} justifyContent={'flex-end'} bg={'#1e283d'} paddingInline={8} paddingBlock={5} borderBottomRadius={15}>
                        <Stack spacing={0} textAlign={'right'}>
                            <Text fontSize={'md'}>{t('TOTAL_AMOUNT')}</Text>
                            <HStack spacing={0}>
                                <Image boxSize={'30px'} src={require('../../../assets/images/topbar-icon-credit.png')} />
                                <Text color={'app.blue'} fontSize={'md'} fontWeight={'bold'}>{`RM ${stringIsNullOrEmpty(watch('amount')) || isNaN(parseFloat(watch('amount'))) ? '0.00' : numberWithCurrencyFormat(parseFloat(watch('amount')))}`}</Text>
                            </HStack>
                        </Stack>
                        <Button
                            type='submit'
                            fontWeight={'500'} fontSize={'sm'} borderRadius={10} backgroundImage={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : ''} bg={!activeBtn() ? '#303e5d' : ''} color={'white'} paddingBlock={6}>
                            {t('CONFIRM_PAYMENT')}
                        </Button>
                    </HStack>
                </form>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            submitPayment(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    }
}

export default PaymentReload;
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Button,
    AbsoluteCenter,
    Center,
    SimpleGrid,
    Box,
    Flex,
    Card,
    CardBody,
    HStack,
    Spacer
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { Language, ApiKey } from "../../../common/constant";
import { ConfigEnum } from "../../../common/config";
import ApiEngine from "../../../common/api_engine";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser } from "../../../common/util";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const languageCode = localStorage.getItem(ConfigEnum._LANGUAGE) || Language._ENGLISH;
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [newMembersCount, setNewMembersCount] = useState(0);
    const [directDownlinesCount, setDirectDownlinesCount] = useState(0);
    const [isRefreshingMemberInfo, setIsRefreshingMemberInfo] = useState(false);
    const _MENU_OPTION = [
        { title: 'GAME_DAILY_REPORT', key: Pages._WIN_LOSS_REPORT, imgPath: require('../../../assets/images/winloss-report.png') },
        { title: 'DAILY_LOTTO_COMM_REPORT', key: Pages._COMM_LOTTO_REPORT, imgPath: require('../../../assets/images/lotto-report.png') },
        // { title: 'MONTHLY_COMM_REPORT', key: Pages._COMM_REPORT, imgPath: require('../../../assets/images/comm-report.png') }
    ]

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getMyDownlinesInfo();
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getMyDownlinesInfo = async () => {
        setIsRefreshingMemberInfo(true);
        var responseJson = await ApiEngine.get(Routes._MY_DOWNLINES_INFO, { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];

            setNewMembersCount(data.todayNewMemberCount);
            setDirectDownlinesCount(data.directDownlineCount);
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
        setIsRefreshingMemberInfo(false);
        setIsFirstLoad(false);
    }

    if (!checkBrowser()) {
        return (
            <Stack>
                <GeneralTopBar title={`${t('DOWNLINES_MEMBER')} / ${t('REPORT')}`} rightComponent={
                    <Button variant='link' onClick={() => {
                        _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/downline-comm-rules-regulations?lang=${languageCode}` });
                    }}>
                        <Image
                            boxSize={'30px'}
                            src={require('../../../assets/images/home-question.png')} />
                    </Button>
                } />
                {
                    !isFirstLoad ? directDownlinesCount > 0 ?
                        <>
                            <AbsoluteCenter w={'90%'}>
                                <SimpleGrid columns={1} spacing={5}>
                                    {
                                        _MENU_OPTION.map((item, index) => {
                                            return (
                                                <Button
                                                    key={index}
                                                    variant={'link'}
                                                    onClick={() => { _router.navigate(item.key) }}>
                                                    <Stack className="app-game-button epay" >
                                                        <AbsoluteCenter>
                                                            <Image boxSize={'100px'} src={item.imgPath} />
                                                        </AbsoluteCenter>
                                                        <Box position={'absolute'} bottom={.5} backgroundColor={'#253145'} w={'99%'} p={3}>
                                                            <Text noOfLines={2} whiteSpace={'break-spaces'}>{t(item.title)}</Text>
                                                        </Box>
                                                    </Stack>
                                                </Button>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                            </AbsoluteCenter>
                            <Flex className="bottom-sticky-button">
                                <Button
                                    isLoading={isRefreshingMemberInfo}
                                    className="my-qr-bottom-button"
                                    style={{
                                        borderTopLeftRadius: '20px'
                                    }}>
                                    <Stack spacing={0}>
                                        <Text fontSize={'lg'} fontWeight={'bold'}>{newMembersCount > 0 ? '+' : ''} {newMembersCount}</Text>
                                        <Text>{t("NEW_MEMBER_TODAY")}</Text>
                                    </Stack>
                                </Button>
                                <Button
                                    isLoading={isRefreshingMemberInfo}
                                    className="my-qr-bottom-button"
                                    style={{
                                        borderTopRightRadius: '20px'
                                    }}>
                                    <Stack spacing={0}>
                                        <Text fontSize={'lg'} fontWeight={'bold'}>{directDownlinesCount}</Text>
                                        <Text>{t("TOTAL_DIRECT_DOWNLINES")}</Text>
                                    </Stack>
                                </Button>
                            </Flex>
                        </>
                        :
                        <AbsoluteCenter textAlign={'center'} w={'full'}>
                            <Center>
                                <Image boxSize={'150px'} src={require("../../../assets/images/empty-downline.png")} />
                            </Center>
                            <Text fontSize={'md'} fontWeight={'bold'}>{t("NO_DOWNLINE_MEMBER")}</Text>
                            <Text fontSize={'sm'} fontStyle={'italic'}>{t("CURRENTLY_YOU_DONT_HAVE_ANY_DIRECT_DOWNLINE_YET")}</Text>
                        </AbsoluteCenter>
                        :
                        <></>
                }
            </Stack>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "40rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={3}>{`${t('DOWNLINES_MEMBER')} / ${t('REPORT')}`}</Text>
                            <Button variant={'link'} display={'inline-block'}>
                                <Image
                                    boxSize={'25px'}
                                    mb={'-3px'}
                                    src={require('../../../assets/images/home-question-after-click.png')}
                                />
                            </Button>
                        </div>
                        {
                            !isFirstLoad ? directDownlinesCount > 0 ?
                                <>
                                    <Card>
                                        <CardBody bg={'#2c3956'} borderRadius={15} padding={6} marginBottom={2}>
                                            {
                                                _MENU_OPTION.map((item, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            variant={'link'}
                                                            bg={'#182031'}
                                                            w={'100%'}
                                                            justifyContent={'left'}
                                                            borderWidth={2}
                                                            borderColor={'transparent'}
                                                            _hover={{ border: "2px solid white" }}
                                                            borderRadius={10}
                                                            padding={5}
                                                            mb={5}
                                                            onClick={() => { _router.navigate(item.key) }}>
                                                            <HStack spacing={6}>
                                                                <div style={{ background: "#242c41", borderRadius: "50px", padding: "20px" }}>
                                                                    <Image boxSize={16} src={item.imgPath} />
                                                                </div>
                                                                <Text noOfLines={2} whiteSpace={'break-spaces'}>{t(item.title)}</Text>
                                                            </HStack>
                                                        </Button>
                                                    )
                                                })
                                            }
                                            <HStack spacing={4}>
                                                <Button
                                                    w={'100%'}
                                                    bg={'#182031'}
                                                    paddingBlock={10}
                                                    borderRadius={15}
                                                    cursor={'default'}
                                                    isLoading={isRefreshingMemberInfo}>
                                                    <Stack spacing={0}>
                                                        <Text fontSize={'lg'} fontWeight={'bold'}>{newMembersCount > 0 ? '+' : ''} {newMembersCount}</Text>
                                                        <Text>{t("NEW_MEMBER_TODAY")}</Text>
                                                    </Stack>
                                                </Button>
                                                <Button
                                                    w={'100%'}
                                                    bg={'#182031'}
                                                    paddingBlock={10}
                                                    borderRadius={15}
                                                    cursor={'default'}
                                                    isLoading={isRefreshingMemberInfo}>
                                                    <Stack spacing={0}>
                                                        <Text fontSize={'lg'} fontWeight={'bold'}>{directDownlinesCount}</Text>
                                                        <Text>{t("TOTAL_DIRECT_DOWNLINES")}</Text>
                                                    </Stack>
                                                </Button>
                                            </HStack>
                                        </CardBody>
                                    </Card>
                                </>
                                :
                                <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                                    <Center>
                                        <Image boxSize={'150px'} src={require("../../../assets/images/empty-downline.png")} />
                                    </Center>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{t("NO_DOWNLINE_MEMBER")}</Text>
                                    <Text fontSize={'sm'} fontStyle={'italic'}>{t("CURRENTLY_YOU_DONT_HAVE_ANY_DIRECT_DOWNLINE_YET")}</Text>
                                </div>
                                :
                                <></>
                        }
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}

import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Stack, Button, Box, Flex, Spacer } from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { Pages } from "../../../../routes/page";
import { showCustomDialog } from "../../../../application/action/app_action";
import { isObjectEmpty, numberWithCurrencyFormat, share, stringIsNullOrEmpty, isTicketCancelled } from "../../../../common/util";
import { ApiKey, BG_TYPE, ACTIVE_STATUS, CANCELLED_STATUS, CANCELLED_STATUS_TXT } from "../../../../common/constant";
import moment from "moment";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PLAYER_ID = _location?.state?.playerId;
    const _ORDER_DETAILS = _location?.state?.orderDetail;
    const [isCancelled, setIsCancelled] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PLAYER_ID) || isObjectEmpty(_ORDER_DETAILS)) {
            _router.back();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    async function cancelTicket() {
        try {
            let params = JSON.stringify({
                "PlayerId": _PLAYER_ID,
                "OrderId": _ORDER_DETAILS.raw_input_id
            });

            var response = await ApiEngine.post(Routes._SWC_CANCEL_TICKET, params, {
                headers: {
                    "content-type": ApiKey._API_APPLICATION_JSON
                }
            });

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw response.Message ?? response.message ?? response.error ?? response.title;
            }

            _dispatch(showCustomDialog({
                success: true,
                title: t('INFO'),
                content: t('CANCEL_ORDER_SUCCESS'),
                onConfirm: () => {
                    _router.navigate(Pages.SWC_ORDER_HISTORY, { playerId: _PLAYER_ID, status: CANCELLED_STATUS }, true);
                }
            }))

            setIsCancelled(true);
        }
        catch(err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <div className='body-content'>
            <GeneralTopBar title={t('ORDER_ID') + ": " + _ORDER_DETAILS.raw_input_id} bgColor="#ebffe3" blackTitle titleAlignLeft useSimpleBackIcon
                onBack={() => _router.navigate(Pages.SWC_ORDER_HISTORY, { playerId: _PLAYER_ID, status: isTicketCancelled(_ORDER_DETAILS.status, isCancelled) ? CANCELLED_STATUS : ACTIVE_STATUS }, true)} />
            <Box
                backgroundColor={'#F5F4FA'}
                borderTopLeftRadius={30}
                borderTopRightRadius={30}
                marginInline={'-3.75vw'}
                overflowY={'hidden'}
                height={'110vh'}>
                <Flex
                    backgroundColor={'white'}
                    borderTopLeftRadius={30}
                    borderTopRightRadius={30}
                    p={5}>
                    <Text color={isTicketCancelled(_ORDER_DETAILS.status, isCancelled) ? 'red' : '#08CD34'} fontSize={'sm'} fontWeight={'bold'}>{`${t('TOTAL')}: -RM${isTicketCancelled(_ORDER_DETAILS.status, isCancelled) ? "0.00" : numberWithCurrencyFormat(_ORDER_DETAILS.ticketAmount)}`}</Text>
                    <Spacer />
                    <Text color={isTicketCancelled(_ORDER_DETAILS.status, isCancelled) ? 'red' : '#08CD34'} fontSize={'sm'} fontWeight={'bold'}>{isTicketCancelled(_ORDER_DETAILS.status, isCancelled) ? CANCELLED_STATUS_TXT : _ORDER_DETAILS.status}</Text>
                </Flex>
                <Flex p={5} align={'center'}>
                    <Text color={'#54595F'} fontSize={'sm'}>{moment(_ORDER_DETAILS.created_datetime, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm A')}</Text>
                    <Spacer />
                    {
                        !isTicketCancelled(_ORDER_DETAILS.status, isCancelled) &&
                        <Button
                            size={'sm'}
                            variant='solid'
                            bg='red'
                            borderRadius={15}
                            color={'white'}
                            onClick={() => {
                                _dispatch(showCustomDialog({
                                    success: true,
                                    title: t('CONFIRMATION'),
                                    content: t('CANCEL_ORDER_CONFIRMATION'),
                                    onConfirm: () => {
                                        cancelTicket();
                                    },
                                    onCancel: () => { },
                                    horizontalBtn: false
                                }))
                            }}>
                            {t('CANCEL_ORDER')}
                        </Button>
                    }
                </Flex>
                <Flex p={5} align={'flex-start'}>
                    <Text color={'#54595F'} fontSize={'sm'} fontWeight={'bold'}>{t('ORDER_STRING')}:</Text>
                    <Spacer />
                    <Text color={'#54595F'} fontSize={'sm'} textAlign={'left'} whiteSpace={'break-spaces'}>{_ORDER_DETAILS.input_text}</Text>
                    <Spacer />
                </Flex>
                <Flex p={5} align={'flex-start'}>
                    <Text color={'#54595F'} fontSize={'sm'} fontWeight={'bold'}>{t('ORDER_RESPONSE')}:</Text>
                    <Spacer />
                    <Text color={isTicketCancelled(_ORDER_DETAILS.status, isCancelled) ? 'red' : '#54595F'} fontSize={'sm'} whiteSpace={'break-spaces'}>{_ORDER_DETAILS.response_text}</Text>
                    <Spacer />
                </Flex>
            </Box>
            <Stack className="bottom-sticky-button" bottom={2}>
                <Button
                    variant={'solid'}
                    bg={'white'}
                    borderWidth={1}
                    borderColor={'#08CD34'}
                    borderRadius={15}
                    onClick={() => {
                        share(_ORDER_DETAILS.response_text);
                    }}
                >
                    <Flex align={'center'}>
                        <img src={require('./assets/images/share-green.svg').default} />
                        <Text color={'#08CD34'} ml={3}>{t('SHARE')}</Text>
                    </Flex>
                </Button>
            </Stack>
        </div>
    )
}
import React from "react";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    VStack,
    AbsoluteCenter
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import { _RECEIPT_WATERMARK, _WEB_VERSION, _VERSION_BUILD_NUMBER } from "../../../common/constant";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();

    return (
        <div className='body-content'>
            <GeneralTopBar title={'ABOUT_US'} bgColor="black" />
            <VStack h="100%">
                <AbsoluteCenter>
                    <Stack align={'center'} gap={0}>
                        <Image boxSize={'150px'} src={require('../../../assets/images/logo.png')} />
                        <Text as='b' fontSize={'2xl'} mt={5}>{_RECEIPT_WATERMARK}</Text>
                        <Text as='b' fontSize={'sm'} color={'#C6D1E2'}>{`${t("VERSION")}: ${_WEB_VERSION}(${_VERSION_BUILD_NUMBER})`} </Text>
                    </Stack>
                </AbsoluteCenter>
                <Stack align={'center'} gap={0} style={{
                    position: 'fixed',
                    bottom: 10
                }}>
                    <Text fontSize={'sm'} color={'#AACBFF'}>{t("TERMS_AND_PRIVACY")}</Text>
                    <Text color={'#C6D1E2'}>{`${t("COPYRIGHT")} © 2024 ${_RECEIPT_WATERMARK}. ${t('ALL_RIGHT_RESERVED')}`}</Text>
                </Stack>
            </VStack>
        </div>
    )
}
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import App from "./app";
import store from "./application/store";
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles/DatePicker.css';
import './styles/Calendar.css';
import './styles/Keyboard.css';
import 'react-tiny-fab/dist/styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

let persistor = persistStore(store);

const theme = extendTheme({
  colors: {
    app: {
      blue: '#AACBFF',
      grey: '#C6D1E2'
    }
  },
  components: {
    Text: {
      baseStyle: {
        color: 'white',
        fontSize: ['xs', 'md']
      },
    },
    Button: {
      variants: {
        base: {
          _hover: { outline: 'none' }
        }
      },
      defaultProps: {
        variant: 'base'
      }
    },
    Table: {
      variants: {
        swc: {
          th: {
            bg: '#08CD34',
            color: 'white',
            borderWidth: '1.5px',
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderColor: "grey.700"
          },
          td: {
            bg: 'white',
            borderColor: "grey.700",
            borderWidth: '1.5px',
            textAlign: 'center',
            borderLeft: 0,
            padding: '5px'
          }
        }
      }
    }
  },
})

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
    Button,
    Divider,
    Text,
    Flex,
    Spacer
} from "@chakra-ui/react";
import { isObjectEmpty } from "../common/util";
import Marquee from "react-fast-marquee";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({
    title,
    options = [],
    selectedOption,
    isVisible,
    allowTouchOutside = true,
    onSelect,
    showImg = true,
    showWhiteBg = false
}) => {
    const { t } = useTranslation();
    const cancelRef = useRef();
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        setIsDialogVisible(isVisible);
    }, [isVisible]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const hidePicker = () => {
        onSelect(false);
    }

    return (
        <Modal
            isOpen={isVisible}
            onClose={() => {
                hidePicker();
            }}
            closeOnOverlayClick={allowTouchOutside}
            motionPreset='scale'
            leastDestructiveRef={cancelRef}
            size={['sm', 'lg']}
            isCentered>
            <ModalOverlay style={{ zIndex: 99999 }} />
            <ModalContent className="app-picker-content">
                <ModalHeader color={'#AACBFF'}>
                    <Text as='b' fontSize={'md'}>{t(title)}</Text>
                    <ModalCloseButton pt={'0.35rem'}><Image boxSize={'25px'} src={require('../assets/images/close.png')} /></ModalCloseButton>
                </ModalHeader>
                <Divider />
                <ModalBody>
                    {
                        options.map((item, index) => {
                            return (
                                <>
                                    <Button justifyContent={'flex-start'} variant={'link'} h='3.3rem' w='full' onClick={() => onSelect(true, item)}>
                                        <Flex align={'center'} w='full'>
                                            {
                                                showImg &&
                                                <Image
                                                    boxSize={'40px'}
                                                    backgroundColor={showWhiteBg ? 'white' : 'transparent'}
                                                    borderRadius={showWhiteBg ? 50 : 0}
                                                    p={1}
                                                    src={item?.imagePath ? item?.imagePath : require('../assets/images/logo.png')} mr={5} />
                                            }
                                            <Text whiteSpace={'break-spaces'} textAlign={'left'}>{t(item.label)}</Text>
                                            <Spacer />
                                            <img
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    opacity: !isObjectEmpty(selectedOption) && selectedOption.value == item.value ? 1 : 0
                                                }}
                                                src={require('../assets/images/svg/general-selected.svg').default} />
                                        </Flex>
                                    </Button>
                                    <Divider className="app-divider-full-width-setting" />
                                </>
                            )
                        })
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
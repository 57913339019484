import React from "react";
import LandingPage from '../pages/non-auth-pages';
import Login from "../pages/non-auth-pages/login";
import Register from "../pages/non-auth-pages/register";
import SecondaryPasswordLogin from "../pages/non-auth-pages/secondary-password-login";
import Home from "../pages/auth-pages/home";
import PlayerDetail from "../pages/auth-pages/games/player-detail";
import Profile from "../pages/auth-pages/profile";
import WebView from "../component/web-view";
import MyQrCode from "../pages/auth-pages/profile/my-qr-code";
import Settings from "../pages/auth-pages/profile/settings";
import AboutUs from "../pages/auth-pages/profile/about-us";
import EditProfile from "../pages/auth-pages/profile/edit-profile";
import BankDetail from "../pages/auth-pages/profile/bank-detail";
import CreateOrUpdateBank from "../pages/auth-pages/profile/create-or-update-bank";
import SecurityPrivacy from "../pages/auth-pages/profile/security-privacy";
import ChangePassword from "../pages/auth-pages/profile/change-password";
import ChangeSecondaryPassword from "../pages/auth-pages/profile/change-secondary-password";
import ConvertECreditOrEPoint from "../pages/auth-pages/transactions/convert-e-credit-or-e-point";
import ConvertSuccess from "../pages/auth-pages/transactions/convert-success";
import RewardToCredit from "../pages/auth-pages/transactions/reward-to-credit";
import TransferScore from "../pages/auth-pages/transactions/transfer-score";
import GpayDeposit from "../pages/auth-pages/transactions/gpay-deposit";
import GpayPaymentGateway from "../pages/auth-pages/transactions/gpay-payment-gateway";
import EWalletDeposit from "../pages/auth-pages/transactions/e-wallet-deposit";
import EWalletPaymentGateway from "../pages/auth-pages/transactions/e-wallet-payment-gateway";
import MobilePinDeposit from "../pages/auth-pages/transactions/mobile-pin-deposit";
import SendECredit from "../pages/auth-pages/transactions/send-e-credit";
import SendECreditDetail from "../pages/auth-pages/transactions/send-e-credit-detail";
import WithdrawECredit from "../pages/auth-pages/transactions/withdraw-e-credit";
import TransactionHistory from "../pages/auth-pages/transactions/transaction-history";
import TransactionHistoryDetail from "../pages/auth-pages/transactions/transaction-history-detail";
import WithdrawEPoint from "../pages/auth-pages/transactions/withdraw-e-point";
import ReloadCategory from "../pages/auth-pages/epay/reload-category";
import ReloadDetail from "../pages/auth-pages/epay/reload-detail";
import ReloadSuccess from "../pages/auth-pages/epay/reload-success";
import DownlineReport from "../pages/auth-pages/profile/downline-report";
import WinLossReport from "../pages/auth-pages/profile/win-loss-report";
import CommReport from "../pages/auth-pages/profile/comm-report";
import LiveChat from "../pages/auth-pages/chat/live-chat";
import RobotMessaging from "../pages/auth-pages/chat/robot-messaging";
import RewardHistory from "../pages/auth-pages/transactions/reward-history";
import EghlTransactionHistory from "../pages/auth-pages/epay/eghl-transaction-history";
import SwcHome from "../pages/auth-pages/mini-program/swc/swc-home";
import SwcViewResults from "../pages/auth-pages/mini-program/swc/swc-view-results";
import SwcOrder from "../pages/auth-pages/mini-program/swc/swc-order";
import SwcOrderSuccess from "../pages/auth-pages/mini-program/swc/swc-order-success";
import SwcOrderHistory from "../pages/auth-pages/mini-program/swc/swc-order-history";
import SwcOrderHistoryDetails from "../pages/auth-pages/mini-program/swc/swc-order-history-details";
import SwcPackageList from "../pages/auth-pages/mini-program/swc/swc-package-list";
import SwcStrikeHistory from "../pages/auth-pages/mini-program/swc/swc-strike-history";
import SwcStrikeHistoryDetail from "../pages/auth-pages/mini-program/swc/swc-strike-history-detail";
import CommLottoReport from "../pages/auth-pages/profile/comm-lotto-report";
import RankHistory from "../pages/auth-pages/profile/rank-history";
import RankList from "../pages/auth-pages/profile/rank-list";
import ForgotPassword from "../pages/non-auth-pages/forgot-password";
import EventDetails from "../pages/auth-pages/bonus/event-details";
import PrizeDetails from "../pages/auth-pages/bonus/prize-details";
import SpinEvent from "../pages/auth-pages/bonus/spin-event";
import SpinHistory from "../pages/auth-pages/bonus/spin-history";
import Games from "../pages/auth-pages/games/games-desktop";
import Transactions from "../pages/auth-pages/transactions/transactions-desktop";
import Bonus from "../pages/auth-pages/bonus/bonus-desktop";
import Epay from "../pages/auth-pages/epay/epay-desktop";
import GamesDetails from "../pages/auth-pages/games/games-details";
import Chat from "../pages/auth-pages/chat/chat-desktop";
import AccountVerficiation from "../pages/auth-pages/profile/account-verification";

/// <summary>
/// Author: Saitama
/// </summary>
export class Pages {
  static _DEFAULT = '/*';
  static _LOGIN = '/login';
  static _REGISTER = '/register';
  static _SECONDARY_PASSWORD_LOGIN = '/secondary-password-login';
  static _PLAYER_DETAIL = '/player-detail';
  static _PROFILE = '/profile';
  static _WEB_VIEW = '/web-view';
  static _MY_QR_CODE = '/my-qr-code';
  static _SETTINGS = '/settings';
  static _ABOUT_US = '/about-us';
  static _EDIT_PROFILE = '/edit-profile';
  static _BANK_DETAIL = '/bank-detail';
  static _CREATE_OR_UPDATE_BANK = '/create-or-update-bank';
  static _SECURITY_OR_PRIVACY = '/security-privacy';
  static _CHANGE_PASSWORD = '/change-password';
  static _CHANGE_SECONDARY_PASSWORD = '/change-secondary-password';
  static _ACCOUNT_VERIFICATION = '/account-verification';
  static _CONVERT_E_CREDIT_OR_E_POINT = '/convert-ecredit-or-epoint';
  static _CONVERT_SUCCESS = '/convert-success';
  static _REWARD_TO_CREDIT = '/reward-to-credit';
  static _TRANSFER_SCORE = '/transfer-score';
  static _ONLINE_BANKING_TOP_UP = '/online-banking-top-up';
  static _DUITNOW_TOP_UP = '/duitnow-top-up';
  static _E_WALLET_TOP_UP = '/e-wallet-top-up';
  static _MOBILE_PIN_TOP_UP = '/mobile-pin-top-up';
  static _E_WALLET_PAYMENT_GATEWAY = '/e-wallet-payment-gateway';
  static _PAYMENT_GATEWAY = '/payment-gateway';
  static _SEND_E_CREDIT = '/send-e-credit';
  static _SEND_E_CREDIT_DETAIL = '/send-e-credit-detail';
  static _WITHDRAW_E_CREDIT = '/withdraw-e-credit';
  static _TRANSACTION_HISTORY = '/transaction-history';
  static _TRANSACTION_HISTORY_DETAIL = '/transaction-history-detail';
  static _WITHDRAW_E_POINT = '/withdraw-e-point';
  static _RELOAD_CATEGORY = '/relaod-category';
  static _RELOAD_DETAIL = '/reload-detail';
  static _RELOAD_SUCCESS = '/reload-success';
  static _DOWNLINE_REPORT = '/downline-report';
  static _WIN_LOSS_REPORT = '/win-loss-report';
  static _COMM_REPORT = '/comm-report';
  static _LIVE_CHAT = '/live-chat';
  static _ROBOT_MESSAGING = '/announcement';
  static _REWARD_HISTORY = '/reward-history';
  static _EGHL_TRANSACTION_HISTORY = '/eghl-transaction-history';
  static _COMM_LOTTO_REPORT = '/comm-lotto-report';
  static _RANK_HISTORY = '/rank-history';
  static _RANK_LIST = '/rank-list';
  static _FORGOT_PASSWORD = '/forgot-password';
  static _EVENT_DETAILS = '/event-details';
  static _PRIZE_DETAILS = '/prize-details';
  static _SPIN_EVENT = '/spin-event';
  static _SPIN_HISTORY = '/spin-history';
  static _GAMES = '/games';
  static _TRANSACTIONS = '/transactions';
  static _CHAT = '/chat';
  static _BONUS = '/bonus';
  static _EPAY = '/epay';
  static _GAMES_DETAILS = '/games-details';



  // Mini Program
  static SWC_HOME = '/buy-lotto';
  static SWC_VIEW_RESULTS = '/view-results';
  static SWC_ORDER = '/order-lotto';
  static SWC_ORDER_SUCCESS = '/order-success';
  static SWC_ORDER_HISTORY = '/order-history';
  static SWC_ORDER_HISTORY_DETAILS = '/order-history-details';
  static SWC_PACKAGE_LIST = '/package-list';
  static SWC_STRIKE_HISTORY = '/strike-history';
  static SWC_STRIKE_HISTORY_DETAILS = '/strike-history-details';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _NON_AUTH_ROUTES = [
  {
    path: Pages._DEFAULT,
    title: "Welcome To BETMAN",
    auth: false,
    component: <LandingPage />,
  },
  {
    path: Pages._LOGIN,
    title: "Login",
    auth: false,
    component: <Login />,
  },
  {
    path: Pages._REGISTER,
    title: "Register",
    auth: false,
    component: <Register />,
  },
  {
    path: Pages._SECONDARY_PASSWORD_LOGIN,
    title: "Secondary Password Login",
    auth: false,
    component: <SecondaryPasswordLogin />,
  },
  {
    path: Pages._CREATE_OR_UPDATE_BANK,
    title: "Create or Update Bank",
    auth: false,
    component: <CreateOrUpdateBank />,
  },
  {
    path: Pages._FORGOT_PASSWORD,
    title: "Forgot Password",
    auth: false,
    component: <ForgotPassword />,
  },
]

/// <summary>
/// Author: Saitama
/// </summary>
export const _AUTH_ROUTES = [
  {
    path: Pages._DEFAULT,
    title: "HOME",
    auth: true,
    component: <Home />,
  },
  {
    path: Pages._PLAYER_DETAIL,
    title: "Player Detail",
    auth: true,
    component: <PlayerDetail />,
  },
  {
    path: Pages._PROFILE,
    title: "Profile",
    auth: true,
    component: <Profile />,
  },
  {
    path: Pages._WEB_VIEW,
    title: "Web View",
    auth: true,
    component: <WebView />,
  },
  {
    path: Pages._MY_QR_CODE,
    title: "My QR Code",
    auth: true,
    component: <MyQrCode />,
  },
  {
    path: Pages._SETTINGS,
    title: "Settings",
    auth: true,
    component: <Settings />,
  },
  {
    path: Pages._ABOUT_US,
    title: "About Us",
    auth: true,
    component: <AboutUs />,
  },
  {
    path: Pages._EDIT_PROFILE,
    title: "Edit Profile",
    auth: true,
    component: <EditProfile />,
  },
  {
    path: Pages._BANK_DETAIL,
    title: "Bank Detail",
    auth: true,
    component: <BankDetail />,
  },
  {
    path: Pages._CREATE_OR_UPDATE_BANK,
    title: "Create or Update Bank",
    auth: true,
    component: <CreateOrUpdateBank />,
  },
  {
    path: Pages._SECURITY_OR_PRIVACY,
    title: "Security or Privacy",
    auth: true,
    component: <SecurityPrivacy />,
  },
  {
    path: Pages._CHANGE_PASSWORD,
    title: "Change Password",
    auth: true,
    component: <ChangePassword />,
  },
  {
    path: Pages._CHANGE_SECONDARY_PASSWORD,
    title: "Change Secondary Password",
    auth: true,
    component: <ChangeSecondaryPassword />,
  },
  {
    path: Pages._ACCOUNT_VERIFICATION,
    title: "Account Verification",
    auth: true,
    component: <AccountVerficiation />,
  },
  {
    path: Pages._CONVERT_E_CREDIT_OR_E_POINT,
    title: "Convert e-Credit or e-Point",
    auth: true,
    component: <ConvertECreditOrEPoint />,
  },
  {
    path: Pages._CONVERT_SUCCESS,
    title: "Convert Success",
    auth: true,
    component: <ConvertSuccess />,
  },
  {
    path: Pages._REWARD_TO_CREDIT,
    title: "Reward to Credit",
    auth: true,
    component: <RewardToCredit />,
  },
  {
    path: Pages._TRANSFER_SCORE,
    title: "Transfer Score",
    auth: true,
    component: <TransferScore />,
  },
  {
    path: Pages._ONLINE_BANKING_TOP_UP,
    title: "Online Banking Top Up",
    auth: true,
    component: <GpayDeposit />,
  },
  {
    path: Pages._DUITNOW_TOP_UP,
    title: "DuitNow Top Up",
    auth: true,
    component: <GpayDeposit isDuitNow={true} />,
  },
  {
    path: Pages._E_WALLET_TOP_UP,
    title: "E-Wallet Top Up",
    auth: true,
    component: <EWalletDeposit />,
  },
  {
    path: Pages._PAYMENT_GATEWAY,
    title: "Payment Gateway",
    auth: true,
    component: <GpayPaymentGateway />,
  },
  {
    path: Pages._E_WALLET_PAYMENT_GATEWAY,
    title: "e-Wallet Payment Gateway",
    auth: true,
    component: <EWalletPaymentGateway />,
  },
  {
    path: Pages._MOBILE_PIN_TOP_UP,
    title: "Mobile Pin Top Up",
    auth: true,
    component: <MobilePinDeposit />,
  },
  {
    path: Pages._SEND_E_CREDIT,
    title: "Send e-Credit",
    auth: true,
    component: <SendECredit />,
  },
  {
    path: Pages._SEND_E_CREDIT_DETAIL,
    title: "Send e-Credit Detail",
    auth: true,
    component: <SendECreditDetail />,
  },
  {
    path: Pages._WITHDRAW_E_CREDIT,
    title: "Withdraw e-Credit",
    auth: true,
    component: <WithdrawECredit />,
  },
  {
    path: Pages._TRANSACTION_HISTORY,
    title: "Transaction History",
    auth: true,
    component: <TransactionHistory />,
  },
  {
    path: Pages._TRANSACTION_HISTORY_DETAIL,
    title: "Transaction History Detail",
    auth: true,
    component: <TransactionHistoryDetail />,
  },
  {
    path: Pages._WITHDRAW_E_POINT,
    title: "Withdraw e-Point",
    auth: true,
    component: <WithdrawEPoint />,
  },
  {
    path: Pages._RELOAD_CATEGORY,
    title: "Reload Category",
    auth: true,
    component: <ReloadCategory />,
  },
  {
    path: Pages._RELOAD_DETAIL,
    title: "Reload Details",
    auth: true,
    component: <ReloadDetail />,
  },
  {
    path: Pages._RELOAD_SUCCESS,
    title: "Reload Success",
    auth: true,
    component: <ReloadSuccess />,
  },
  {
    path: Pages._DOWNLINE_REPORT,
    title: "Downline Report",
    auth: true,
    component: <DownlineReport />,
  },
  {
    path: Pages._WIN_LOSS_REPORT,
    title: "WinLoss Report",
    auth: true,
    component: <WinLossReport />,
  },
  {
    path: Pages._COMM_REPORT,
    title: "Comm Report",
    auth: true,
    component: <CommReport />,
  },
  {
    path: Pages._LIVE_CHAT,
    title: "Live Chat",
    auth: true,
    component: <LiveChat />,
  },
  {
    path: Pages._ROBOT_MESSAGING,
    title: "Announcement",
    auth: true,
    component: <RobotMessaging />,
  },
  {
    path: Pages._REWARD_HISTORY,
    title: "Reward History",
    auth: true,
    component: <RewardHistory />,
  },
  {
    path: Pages._EGHL_TRANSACTION_HISTORY,
    title: "Transaction History",
    auth: true,
    component: <EghlTransactionHistory />,
  },
  {
    path: Pages.SWC_HOME,
    title: "Buy Lotto",
    auth: true,
    component: <SwcHome />,
  },
  {
    path: Pages.SWC_VIEW_RESULTS,
    title: "View Results",
    auth: true,
    component: <SwcViewResults />,
  },
  {
    path: Pages.SWC_ORDER,
    title: "Order Lotto",
    auth: true,
    component: <SwcOrder />,
  },
  {
    path: Pages.SWC_ORDER_SUCCESS,
    title: "Order Success",
    auth: true,
    component: <SwcOrderSuccess />,
  },
  {
    path: Pages.SWC_ORDER_HISTORY,
    title: "Order History",
    auth: true,
    component: <SwcOrderHistory />,
  },
  {
    path: Pages.SWC_ORDER_HISTORY_DETAILS,
    title: "Order History Details",
    auth: true,
    component: <SwcOrderHistoryDetails />,
  },
  {
    path: Pages._COMM_LOTTO_REPORT,
    title: "Lotto Comm Report",
    auth: true,
    component: <CommLottoReport />,
  },
  {
    path: Pages.SWC_PACKAGE_LIST,
    title: "Package List",
    auth: true,
    component: <SwcPackageList />,
  },
  {
    path: Pages.SWC_STRIKE_HISTORY,
    title: "Strike History",
    auth: true,
    component: <SwcStrikeHistory />,
  },
  {
    path: Pages.SWC_STRIKE_HISTORY_DETAILS,
    title: "Strike History Details",
    auth: true,
    component: <SwcStrikeHistoryDetail />,
  },
  {
    path: Pages._RANK_HISTORY,
    title: "Rank History",
    auth: true,
    component: <RankHistory />,
  },
  {
    path: Pages._RANK_LIST,
    title: "Rank List",
    auth: true,
    component: <RankList />,
  },
  {
    path: Pages._EVENT_DETAILS,
    title: "Event Details",
    auth: true,
    component: <EventDetails />,
  },
  {
    path: Pages._PRIZE_DETAILS,
    title: "Prize Details",
    auth: true,
    component: <PrizeDetails />,
  },
  {
    path: Pages._SPIN_EVENT,
    title: "Spin Event",
    auth: true,
    component: <SpinEvent />,
  },
  {
    path: Pages._SPIN_HISTORY,
    title: "Spin History",
    auth: true,
    component: <SpinHistory />,
  },
  {
    path: Pages._GAMES,
    title: "Games",
    auth: true,
    component: <Games />,
  },
  {
    path: Pages._TRANSACTIONS,
    title: "Transactions",
    auth: true,
    component: <Transactions />,
  },
  {
    path: Pages._CHAT,
    title: "Chat",
    auth: true,
    component: <Chat />,
  },
  {
    path: Pages._BONUS,
    title: "Bonus",
    auth: true,
    component: <Bonus />,
  },
  {
    path: Pages._EPAY,
    title: "Epay",
    auth: true,
    component: <Epay />,
  },
  {
    path: Pages._GAMES_DETAILS,
    title: "Games Details",
    auth: true,
    component: <GamesDetails />,
  },
];


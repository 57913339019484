import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Card,
    CardBody,
    Center,
    HStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog, disposeCustomDialog } from "../../../application/action/app_action";
import GeneralTopBar from "../../../component/general-top-bar";
import { ApiKey } from "../../../common/constant";
import { checkBrowser, stringIsNullOrEmpty } from "../../../common/util";
import { useForm } from 'react-hook-form';
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
import Iframe from "react-iframe";
import { ConfigEnum } from "../../../common/config";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const [livenessUrl, setLivenessUrl] = useState('');

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var responseJson = await ApiEngine.get(`${Routes._GET_ACCOUNT_VERIFICATION}?deviceId=${localStorage.getItem(ConfigEnum._DEVICE_ID)}&isApp=false`);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                window.open(responseJson[ApiKey._API_DATA_KEY], "_self");
                //setLivenessUrl(responseJson[ApiKey._API_DATA_KEY]);
            }
            catch (err) {
                _dispatch(showCustomDialog({
                    success: false,
                    content: err,
                    onConfirm: () => _router.back()
                }));
            }
        })();
    }, []);

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'ACCOUNT_VERIFICATION'} />
                {/* {
                    !stringIsNullOrEmpty(livenessUrl) &&
                    <Iframe
                        url={livenessUrl}
                        cacheEnabled={false}
                        incognito
                        mediaPlaybackRequiresUserAction={false}
                        originWhitelist={['*']}
                        javaScriptEnabled={true}
                        showsVerticalScrollIndicator={false}
                        onNavigationStateChange={(value) => {
                            console.log(value)
                        }}
                        className="web-view-frame"
                    />
                } */}
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", minWidth: "32rem", }}>
                        <div style={{ textAlign: "center", marginBottom: "25px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={10}>{t('ACCOUNT_VERIFICATION')}</Text>
                        </div>
                        {/* {
                            !stringIsNullOrEmpty(livenessUrl) &&
                            <Iframe
                                url={livenessUrl}
                                cacheEnabled={false}
                                incognito
                                mediaPlaybackRequiresUserAction={false}
                                originWhitelist={['*']}
                                javaScriptEnabled={true}
                                showsVerticalScrollIndicator={false}
                                onNavigationStateChange={(value) => {
                                    console.log(value)
                                }}
                                className="account-verification-web-view-frame"
                            />
                        } */}
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import {
    Text,
    Image,
    Button,
    Center,
    Flex,
    Stack
} from '@chakra-ui/react';
import { ValidationPattern, ApiKey } from "../../../common/constant";
import { stringIsNullOrEmpty } from "../../../common/util";
import { Pages } from "../../../routes/page";
import QrReader from 'react-qr-scanner'
import CustomInput from "../../../component/input";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue } = useForm();
    var _router = useRouter();
    var _dispatch = useDispatch();
    const [isScanned, setIsScanned] = useState(false);
    const _DELAY_IN_MS = 500;

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function qrDetectedHandler(decodedQr) {
        if (!stringIsNullOrEmpty(decodedQr?.text) && !isScanned) {
            setIsScanned(true);
            getUser(decodedQr.text);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            getUser(data.phoneNo);
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err,
            }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getUser(data) {
        var responseJson = await ApiEngine.get(Routes._SCAN_MEMBER_QR + '?username=' + data);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._SEND_E_CREDIT_DETAIL, { data: responseJson[ApiKey._API_DATA_KEY]}, true);
        }
        else {
            _dispatch(showCustomDialog({
                success: false,
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: () => setIsScanned(false)
            }));
        }
    }

    return (
        <div className="qr-wrapper">
            <QrReader
                legacyMode={true}
                constraints={{
                    video: { facingMode: "environment" }
                }}
                style={{ width: "100%", height: '170vw', objectFit: "cover" }}
                delay={_DELAY_IN_MS}
                onError={(err) => { 
                    _dispatch(showCustomDialog({
                        success: false,
                        content: err
                    }));
                }}
                onScan={qrDetectedHandler}
            />
            <div className="qr-scanner-overlay">
                <div className={`scan-area`}></div>
                <Center>
                    <Stack style={{ position: 'absolute', top: '15vw' }}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Flex>
                                <Controller
                                    control={control}
                                    name="phoneNo"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label='ENTER_MOBILE_NUMBER'
                                            inputType="tel"
                                            errors={errors}
                                            patternErrorTxt={t(
                                                'PLEASE_FILL_VALID_PHONE_NO'
                                            )} />
                                    )}
                                    rules={{
                                        required: true,
                                        pattern:
                                            ValidationPattern._PHONE_NUMBER_REGEX
                                    }}
                                />
                                <Button type="submit" variant='link' alignSelf={'center'} ml={5}>
                                    <Image
                                        boxSize={'35px'}
                                        transform={'rotate(180deg)'}
                                        src={require('../../../assets/images/general-back.png')} />
                                </Button>
                            </Flex>
                        </form>
                        <Text textAlign={'center'} fontSize={'md'} fontWeight={'bold'}>{t('OR')}</Text>
                    </Stack>
                </Center>
                <Center>
                        <Image
                        boxSize={'50px'}
                        style={{ position: 'absolute', bottom: '3vw' }}
                        src={require('../../../assets/images/close.png')}
                        onClick={() => _router.back()} />
                </Center>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Image,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import { isBrowser } from "react-device-detect";
import { checkBrowser } from "../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({
    isOpen,
    closeOnOverlayClick = true,
    onClose,
    title = 'BETMAN',
    content,
}) => {
    const { t } = useTranslation();

    return (
        <Drawer placement={'bottom'} isOpen={isOpen} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent borderTopLeftRadius={'20px'} borderTopRightRadius={'20px'} maxW={checkBrowser() ? '1080px !important' : ''} left={'unset !important'} right={'unset !important'}>
                <DrawerHeader className="bottom-sheet-header">
                    {t(title)}
                    <DrawerCloseButton pt={'0.35rem'}><Image boxSize={'25px'} src={require('../assets/images/close.png')} /></DrawerCloseButton>
                </DrawerHeader>
                <DrawerBody className="bottom-sheet-body">
                    {content}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Stack, Text, Image, Flex } from '@chakra-ui/react';
import { useRouter } from "../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { SessionKey, BOTTOM_BAR } from "../common/constant";
import { stringIsNullOrEmpty, isObjectEmpty } from "../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onMenuChange }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector(state => state.authState.userData);
    const [menuId, setMenuId] = useState('');

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        var cacheMenuId = sessionStorage.getItem(SessionKey._BOTTOM_MENU_ITEM);

        if (stringIsNullOrEmpty(cacheMenuId)) {
            setMenuId(BOTTOM_BAR[0].id);
        }
        else {
            setMenuId(cacheMenuId);
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(menuId)){
            sessionStorage.setItem(SessionKey._BOTTOM_MENU_ITEM, menuId);
            onMenuChange(menuId);
        }
    }, [menuId]);

    return (
        <Stack className="app-bottom-bar">
            <Stack className="stack-container">
                <Flex justify={'center'}>
                    {
                        BOTTOM_BAR.map((menu, index) => {
                            var selected = !stringIsNullOrEmpty(menuId) && menuId == menu.id.toString();
                            return (
                                <div onClick={() => setMenuId(menu.id)} className={'app-bottom-bar-item'} style={{ flex: 1 / BOTTOM_BAR.length }} key={index}>
                                    <div style={{ position: 'relative' }}>
                                        <Image boxSize={selected ? '45px' : '38px'} src={selected ? menu.selectedIcon : menu.icon} />
                                        {
                                            menu.showIndicator && !isObjectEmpty(_USER_DATA.memberBonusInfo) && _USER_DATA.memberBonusInfo.isLocked &&
                                            <span className="red-dot" />
                                        }
                                    </div>
                                    <Text fontSize={selected ? 'sm' : 'xs'} as={selected ? 'b' : ''}>{t(menu.title)}</Text>
                                </div>
                            )
                        })
                    }
                </Flex>
            </Stack>
        </Stack>
    )
}
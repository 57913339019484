import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import { Stack, Flex, Text, Divider, Spinner, Spacer, AbsoluteCenter, Image, Button, Center } from "@chakra-ui/react";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import { ApiKey, _PAGE_SIZE, EGHLCategory, EGHLStatus } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat } from "../../../common/util";
import moment from "moment";
import DateFilter from "../../../component/date-filter";
import InfiniteScroll from 'react-infinite-scroll-component';
import BottomSheet from "../../../component/bottom-sheet";
import { Pages } from "../../../routes/page";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _INITIAL_PAGE_NO = 1;
    const [transaction, setTransaction] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showFilterBar, setShowFilterBar] = useState(false);
    const filterOptions = [
        {
            label: 'ALL',
            value: ''
        },
        {
            label: 'MOBILE_RELOAD',
            value: EGHLCategory._MOBILE_RELAOD
        },
        {
            label: 'GAME_RELOAD',
            value: EGHLCategory._GAME_RELOAD
        },
        {
            label: 'BILL_PAYMENT',
            value: EGHLCategory._BILL_PAYMENT
        },
        {
            label: 'EWALLET_RELOAD',
            value: EGHLCategory._EWALLET
        }
    ];
    const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
    const _RESULT_OPTION = [
        {
            name: 'MOBILE_RELOAD',
            categoryKey: 'Mobile Reload',
            imagePath: require('../../../assets/images/epay/epay-icon-mobilereload.png')
        },
        {
            name: 'GAME_RELOAD',
            categoryKey: 'Games Reload',
            imagePath: require('../../../assets/images/epay/epay-icon-gamereload.png')
        },
        {
            name: 'BILL_PAYMENT',
            categoryKey: 'Bill Payment',
            imagePath: require('../../../assets/images/epay/epay-icon-billpayment.png')
        },
        {
            name: 'EWALLET_RELOAD',
            categoryKey: 'eWallet Reload',
            imagePath: require('../../../assets/images/epay/epay-icon-ewallet.png')
        }
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getTransactionHistory();
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getTransactionHistory(
        startedDate = startDate,
        endedDate = endDate,
        filterData = selectedFilter.value,
        pagedNo = _INITIAL_PAGE_NO
    ) {
        var responseJson = await ApiEngine.get(
            Routes._GET_EGHL_TRANSACTION_LIST +
            '?startDate=' +
            startedDate +
            '&endDate=' +
            endedDate +
            '&categoryId=' +
            filterData +
            '&page=' +
            pagedNo +
            '&pageSize=' +
            _PAGE_SIZE
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTotalCount(responseJson['count']);
            setTotalAmount(responseJson[ApiKey._API_TOTAL_AMOUNT_KEY]);

            if (
                responseJson[ApiKey._API_DATA_KEY] &&
                responseJson[ApiKey._API_DATA_KEY].length > 0 &&
                pagedNo > _INITIAL_PAGE_NO
            ) {
                setTransaction([
                    ...transaction,
                    ...responseJson[ApiKey._API_DATA_KEY]
                ]);
            } else {
                setTransaction(responseJson[ApiKey._API_DATA_KEY]);
            }
        } else {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: responseJson[ApiKey._API_MESSAGE_KEY]
                })
            );
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getTransactionHistoryDetails(transaction) {
        var responseJson = await ApiEngine.get(
            Routes._GET_EGHL_TRANSACTION_DETAIL +
            '?transactionId=' +
            transaction.transaction_id
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let result = {
                ...responseJson[ApiKey._API_DATA_KEY],
                ..._RESULT_OPTION.find(
                    (r) =>
                        r.categoryKey ==
                        responseJson[ApiKey._API_DATA_KEY].category_name
                )
            };

            _router.navigate(Pages._RELOAD_SUCCESS, {
                instruction: result.details.replace('Instruction-\n', ''),
                toCopy: result.pin,
                amount: '- RM ' + numberWithCurrencyFormat(result.price),
                headerTitle: result.name,
                contentImage: transaction.img,
                backToLastPage: true,
                details: [
                    {
                        label: 'DATE_AND_TIME',
                        value: moment(result.created_at).format(
                            'DD MMM yyyy, hh:mm:ss a'
                        )
                    },
                    { label: 'SERVICE', value: result.service_name },
                    { label: 'TRANSACTION_NO', value: result.payment_id },
                    { label: 'PIN', value: result.pin },
                    { label: 'EXPIRY_DATE', value: result.pin_expiry }
                ]
            });
        } else {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: responseJson[ApiKey._API_MESSAGE_KEY]
                })
            );
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const RenderItem = ({ item, index }) => {
        return (
            <Stack marginBlock={2}>
                <Button
                    key={index}
                    variant={'link'}
                    isDisabled={item.status == EGHLStatus._FAILED}
                    opacity={item.status == EGHLStatus._FAILED ? 0.6 : 1}
                    onClick={() => { getTransactionHistoryDetails(item); }}

                >
                    <Flex align={'center'} w='full' gap={checkBrowser() ? 2 : ''}>
                        <Button
                            variant={'link'}
                            alignItems={'center'}
                            borderRadius={50}
                            backgroundColor={'#C6D1E2'}
                            borderWidth={2.5}
                            borderColor={'#A0B1CB'}
                            p={1.5}
                            disabled >
                            <Image boxSize={'35px'} src={item.img} />
                        </Button>
                        <Stack spacing={0} ml={2} align={'flex-start'}>
                            <Text>{t(item.service_name)}</Text>
                            <Text fontSize={checkBrowser() ? 'sm' : ''} fontWeight={checkBrowser() ? 'normal' : ''} color={'app.blue'}>{moment(item.created_at).format('DD MMM yyyy, hh:mm:ss a')}</Text>
                        </Stack>
                        <Spacer />
                        <Stack spacing={0} align={'flex-end'}>
                            <Text>{'- RM ' + numberWithCurrencyFormat(item.price)}</Text>
                            <Text color={item.status == EGHLStatus._FAILED ? '#FF5555' : '#27D918'}>{item.status == EGHLStatus._FAILED ? t("FAILED") : t("SUCCESSFUL")}</Text>
                        </Stack>
                        {item.status !== EGHLStatus._FAILED && (
                            <img src={require('../../../assets/images/svg/general-icon-arrow.svg').default} />
                        )}
                    </Flex>
                </Button>
                <Divider className="app-divider-full-width-setting" mt={3} />
            </Stack>
        )
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'TRANSACTION_HISTORY'}>
                    <Divider className='app-divider-full-width-setting' />
                    <Flex mt={2.5} paddingInline={3}>
                        <Text fontWeight={'semibold'} mr={3} onClick={() => setShowDatePicker(!showDatePicker)}>
                            {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                        </Text>
                        <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                        <Spacer />
                        <Text fontWeight={'semibold'} mr={3} onClick={() => setShowFilterBar(!showFilterBar)}>
                            {t('FILTER_BY')}
                        </Text>
                        <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                    </Flex>
                </GeneralTopBar>
                <Stack className="header-margin-top">
                    {
                        transaction.length > 0 ?
                            <>
                                <Text color={'app.blue'}>
                                    {t('TOTAL_AMOUNT') +
                                        ' : RM ' +
                                        numberWithCurrencyFormat(totalAmount)}
                                </Text>
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        height: '100vh',
                                        overflow: "auto"
                                    }}>
                                    <InfiniteScroll
                                        style={{ display: "flex", flexDirection: "column" }}
                                        dataLength={transaction.length}
                                        next={() => {
                                            let page = pageNo + 1;
                                            setPageNo(page);
                                            getTransactionHistory(startDate, endDate, selectedFilter.value, page);
                                        }}
                                        hasMore={transaction.length < totalCount}
                                        loader={<Spinner color="white" />}
                                    >
                                        {
                                            transaction.map((item, index) => {
                                                return (
                                                    <RenderItem
                                                        item={item}
                                                        index={index}
                                                    />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                </div>
                            </> :
                            <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                                <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("NO_TRANSACTIONS")}</Text>
                                <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("YOU_HAVENT_MADE_ANY_TRANSACTIONS_YET")}</Text>
                            </AbsoluteCenter>
                    }
                </Stack>
                <DateFilter
                    isOpen={showDatePicker}
                    onClose={() => setShowDatePicker(!showDatePicker)}
                    closeOnOverlayClick={false}
                    applyDateFilter={(data) => {
                        let start = moment(data.startDate).format('yyyy-MM-DD');
                        let end = moment(data.endDate).format('yyyy-MM-DD');
                        setStartDate(start);
                        setEndDate(end);
                        getTransactionHistory(start, end);
                    }}
                />
                <BottomSheet
                    title={t('FILTER_BY')}
                    isOpen={showFilterBar}
                    onClose={() => setShowFilterBar(!showFilterBar)}
                    content={
                        <Stack>
                            {
                                filterOptions.map((item, index) => {
                                    return (
                                        <>
                                            <Button key={index} variant={'link'} onClick={() => { setSelectedFilter(item); }}>
                                                <Flex align={'center'} w='full' h='2.5rem' paddingInline={5}>
                                                    <Text fontSize={'md'} as='b'>{t(item.label)}</Text>
                                                    <Spacer />
                                                    <img
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            opacity: selectedFilter.value == item.value ? 1 : 0.3
                                                        }}
                                                        src={require('../../../assets/images/svg/general-selected.svg').default} />
                                                </Flex>
                                            </Button>
                                            {
                                                index + 1 != filterOptions.length &&
                                                <Divider className="app-divider-full-width-setting" />
                                            }
                                        </>
                                    )
                                })
                            }
                            <Flex w={'100%'}>
                                <Button className="date-filter-reset-button" onClick={() => setSelectedFilter(filterOptions[0])}>
                                    {t('CLEAR_ALL')}
                                </Button>
                                <Button className="date-filter-apply-button" onClick={() => {
                                    setPageNo(_INITIAL_PAGE_NO);
                                    getTransactionHistory(startDate, endDate, selectedFilter.value);
                                    setShowFilterBar(!showFilterBar);
                                }}>
                                    {t('APPLY')}
                                </Button>
                            </Flex>
                        </Stack>
                    } />
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "50rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('TRANSACTION_HISTORY')}</Text>
                        </div>
                        <div style={{ background: "#2c3956", padding: "20px", borderRadius: "20px" }}>
                            <Flex mt={2.5} paddingInline={3}>
                                <Text fontWeight={'semibold'} mr={3} cursor={'pointer'} onClick={() => setShowDatePicker(!showDatePicker)}>
                                    {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                </Text>
                                <img src={require('../../../assets/images/svg/updown-white.svg').default} onClick={() => setShowDatePicker(!showDatePicker)} style={{ cursor: "pointer" }} />
                                <Spacer />
                                <Text fontWeight={'semibold'} mr={3} onClick={() => setShowFilterBar(!showFilterBar)}>
                                    {t('FILTER_BY')}
                                </Text>
                                <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                            </Flex>
                            <Stack paddingInline={5} mt={4}>
                                {
                                    transaction.length > 0 ?
                                        <>
                                            <Text color={'app.blue'} mb={3}>
                                                {t('TOTAL_AMOUNT') +
                                                    ' : RM ' +
                                                    numberWithCurrencyFormat(totalAmount)}
                                            </Text>
                                            <div
                                                id="scrollableDiv"
                                                style={{
                                                    height: '23rem',
                                                    overflow: "auto"
                                                }}>
                                                <InfiniteScroll
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                    dataLength={transaction.length}
                                                    next={() => {
                                                        let page = pageNo + 1;
                                                        setPageNo(page);
                                                        getTransactionHistory(startDate, endDate, selectedFilter.value, page);
                                                    }}
                                                    hasMore={transaction.length < totalCount}
                                                    loader={<Spinner color="white" />}
                                                >
                                                    {
                                                        transaction.map((item, index) => {
                                                            return (
                                                                <RenderItem
                                                                    item={item}
                                                                    index={index}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </InfiniteScroll>
                                            </div>
                                        </> :
                                        <div style={{ textAlign: "center", paddingBlock: "5rem" }}>
                                            <Center>
                                                <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                            </Center>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("NO_TRANSACTIONS")}</Text>
                                            <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("YOU_HAVENT_MADE_ANY_TRANSACTIONS_YET")}</Text>
                                        </div>
                                }
                            </Stack>
                            <DateFilter
                                isOpen={showDatePicker}
                                onClose={() => setShowDatePicker(!showDatePicker)}
                                closeOnOverlayClick={false}
                                applyDateFilter={(data) => {
                                    let start = moment(data.startDate).format('yyyy-MM-DD');
                                    let end = moment(data.endDate).format('yyyy-MM-DD');
                                    setStartDate(start);
                                    setEndDate(end);
                                    getTransactionHistory(start, end);
                                }}
                            />
                            <BottomSheet
                                title={t('FILTER_BY')}
                                isOpen={showFilterBar}
                                onClose={() => setShowFilterBar(!showFilterBar)}
                                content={
                                    <Stack>
                                        {
                                            filterOptions.map((item, index) => {
                                                return (
                                                    <>
                                                        <Button key={index} variant={'link'} onClick={() => { setSelectedFilter(item); }}>
                                                            <Flex align={'center'} w='full' h='2.5rem' paddingInline={5}>
                                                                <Text fontSize={'md'} as='b'>{t(item.label)}</Text>
                                                                <Spacer />
                                                                <img
                                                                    style={{
                                                                        width: '30px',
                                                                        height: '30px',
                                                                        opacity: selectedFilter.value == item.value ? 1 : 0.3
                                                                    }}
                                                                    src={require('../../../assets/images/svg/general-selected.svg').default} />
                                                            </Flex>
                                                        </Button>
                                                        {
                                                            index + 1 != filterOptions.length &&
                                                            <Divider className="app-divider-full-width-setting" />
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        <Flex w={'100%'}>
                                            <Button className="date-filter-reset-button" onClick={() => setSelectedFilter(filterOptions[0])}>
                                                {t('CLEAR_ALL')}
                                            </Button>
                                            <Button className="date-filter-apply-button" onClick={() => {
                                                setPageNo(_INITIAL_PAGE_NO);
                                                getTransactionHistory(startDate, endDate, selectedFilter.value);
                                                setShowFilterBar(!showFilterBar);
                                            }}>
                                                {t('APPLY')}
                                            </Button>
                                        </Flex>
                                    </Stack>
                                } />
                        </div>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import {
    Text,
    Image,
    Button,
    Flex,
    Stack,
    Spacer,
    Divider,
    Box,
    Center,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Input
} from '@chakra-ui/react';
import { ApiKey, _AMOUNT_TYPE, _WALLET_ACTIONS, TransactionType, STATUS_TYPE } from "../../../common/constant";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, checkBrowser, getValidationMessage } from "../../../common/util";
import { Pages } from "../../../routes/page";
import CustomInput from "../../../component/input";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import CurrencyInput from "react-currency-input-field";
import PasswordModal from "../../../component/password-modal";
import CustomPicker from "../../../component/custom-picker";
import { setBusy, setIdle } from "../../../application/action/app_action";
import { TriangleDownIcon } from "@chakra-ui/icons";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { ConfigEnum } from "../../../common/config";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const _PARAMS = _location?.state;
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { username, credit, reward } = useSelector((state) => state.authState.userData);
    const [bankPickerVisible, setBankPickerVisible] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [withdrawAmountConstraints, setWithdrawAmountConstraints] = useState({
        min: 0,
        max: 0
    });
    const [bankDetails, setBankDetails] = useState({
        bankId: '',
        bankAccountNumber: '',
        bankAccountHolderName: ''
    });
    const [authorizationModal, setAuthorizationModal] = useState({
        password: '',
        visible: false
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getBankList();
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getBankList = async () => {
        try {
            _dispatch(setBusy());
            let responseJson = await ApiEngine.get(Routes._BANK_INFO, { headers: { "skipLoading": true } });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setWithdrawAmountConstraints({
                min: responseJson[ApiKey._API_DATA_KEY]?.minThreshold,
                max: responseJson[ApiKey._API_DATA_KEY]?.maxThreshold
            });

            setValue(
                'amount',
                credit > responseJson[ApiKey._API_DATA_KEY]?.minThreshold ? responseJson[ApiKey._API_DATA_KEY]?.minThreshold : credit //here
            );

            let userBankResponseJson = await ApiEngine.get(`${Routes._GET_USER_BANK_ACCOUNTS}?userId=${_USER_DATA.guid}`, { headers: { "skipLoading": true } });

            if (!userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
                throw userBankResponseJson[ApiKey._API_MESSAGE_KEY];
            }

            let userBanks = userBankResponseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

            if (userBanks.length > 0) {
                setValue('bankAccountHolderName', userBanks[0]['bankAccountHolderName']);
                setBankList(userBanks.map(item => ({
                    label: item?.bankName,
                    value: item?.id,
                    imagePath: item?.bankLogo,
                    accountNo: item?.bankAccountNumber,
                    bankId: item?.bankId
                })));
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
        finally {
            _dispatch(setIdle());
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        if (data.amount > credit) {
            _dispatch(showCustomDialog({ success: false, content: 'INSUFFICIENT_CREDIT' }));
        }
        else {
            setAuthorizationModal({ ...authorizationModal, visible: true });
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('amount')) && !stringIsNullOrEmpty(watch('bankId')));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const authorizationSuccess = async (authorization) => {
        try {
            let raw = JSON.stringify({
                amount: watch('amount'),
                bankAccountId: watch('bankId')?.value,
                password: authorization.password
            });

            let responseJson = await ApiEngine.post(
                Routes._ONLINE_WITHDRAWAL,
                raw,
                {
                    ...ApplicationJsonHeaderConfig
                }
            );

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _router.navigate(Pages._TRANSACTION_HISTORY, { type: _WALLET_ACTIONS.WITHDRAWAL_REQUEST }, true);
            _router.navigate(Pages._TRANSACTION_HISTORY_DETAIL, {
                type: TransactionType._WITHDRAW_CREDIT,
                status: STATUS_TYPE._PENDING_APPROVAL,
                transactionId: responseJson[ApiKey._API_DATA_KEY]
            });
        }
        catch (err) {
            setTimeout(() => {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: err,
                        onConfirm: () => { }
                    })
                );
            }, 300);
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'WITHDRAW_WALLET'}>
                    <ConversionTopBar credit={credit} isReward />
                </GeneralTopBar>
                <Stack className="header-margin-top">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Controller
                            control={control}
                            name="username"
                            defaultValue={username}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    readOnly
                                    id={name}
                                    value={value}
                                    label={`1. ${t('USERNAME')}`} />
                            )}
                        />
                        <Flex>
                            <Text fontSize={'sm'}>{'2. ' + t('ENTER_AMOUNT')}</Text>
                            <Spacer />
                            <Text fontStyle={'italic'}>{t('MIN_AMOUNT_RM') + withdrawAmountConstraints?.min}</Text>
                        </Flex>
                        <Stack mb='25px'>
                            <Box w='full' h={['12.5vw', '3vw']} background={'#192031'} borderRadius={5} borderColor='#334060' borderWidth={2} mt={2}>
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={0}
                                    render={({ onChange, value, name }) => (
                                        <Stack direction='row' paddingInline={3}>
                                            <CurrencyInput
                                                style={{
                                                    textAlign: 'start',
                                                    backgroundColor: 'transparent',
                                                    color: '#AACBFF',
                                                    fontWeight: '500',
                                                    fontSize: '1rem',
                                                    width: '100%',
                                                    outline: 0
                                                }}
                                                id={name}
                                                name={name}
                                                value={value}
                                                placeholder="0.00"
                                                decimalScale={2}
                                                autoComplete={false}
                                                onFocus={(e) => e.target.select()}
                                                onValueChange={(value, name) => {
                                                    let newValue = value

                                                    if (newValue > credit) {
                                                        newValue = credit;
                                                    }

                                                    onChange(newValue);
                                                }}
                                            />
                                            <Divider borderColor={'#2D3C5C'} orientation='vertical' h={['12.5vw', '3vw']} />
                                            <Button variant='link'
                                                onClick={() => {
                                                    setValue('amount', credit);
                                                }}>
                                                <Text fontSize={'sm'}>{t('MAX')}</Text>
                                            </Button>
                                        </Stack>
                                    )}
                                    rules={{
                                        required: true,
                                        min: withdrawAmountConstraints?.min,
                                        max: withdrawAmountConstraints?.max
                                    }}
                                />
                            </Box>
                            {
                                errors && errors['amount'] &&
                                <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                            }
                        </Stack>
                        <Controller
                            control={control}
                            name="bankAccountHolderName"
                            defaultValue={bankDetails?.bankAccountHolderName}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    readOnly
                                    id={name}
                                    value={value}
                                    label={`3. ${t('BENEFICIARY_NAME')}`} />
                            )}
                            rules={{ required: true }}
                        />
                        <Controller
                            control={control}
                            name="bankId"
                            defaultValue={bankDetails?.bankId}
                            render={({ value, name }) => (
                                <>
                                    <Flex mb={-3}>
                                        <Text fontSize={'sm'}>{`5. ${t('BANK_NAME')}`}</Text>
                                        <Spacer />
                                        <Button variant='link' onClick={() => { _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: watch('bankAccountHolderName'), isNewUser: false }) }}>
                                            <Text as='i' decoration='underline'>{t('ADD_BANK_ACCOUNT')}</Text>
                                        </Button>
                                    </Flex>
                                    <Button variant={'link'} w='full' onClick={() => {
                                        if (bankList.length > 0) {
                                            setBankPickerVisible(!bankPickerVisible)
                                        }
                                        else {
                                            _dispatch(showCustomDialog({
                                                isPlainTheme: true,
                                                title: 'ADD_BANK',
                                                customContent: (
                                                    <Text>{t('IT_APPEARS_THAT_YOU_HAVENT_ADDED_A_BANK_FOR_WITHDRAWAL')}</Text>
                                                ),
                                                confirmTxt: 'BRING_ME_THERE',
                                                onConfirm: () => {
                                                    _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: '', isNewUser: false })
                                                },
                                                onCancel: () => { }
                                            }));
                                        }
                                    }}>
                                        <CustomInput
                                            isReadOnly
                                            id={name}
                                            value={value?.label}
                                            placeholder={t('SELECT_BANK')}
                                            errors={errors}
                                            icon={
                                                <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                            }
                                        />
                                    </Button>
                                </>
                            )}
                            rules={{ required: true }}
                        />
                        <Controller
                            control={control}
                            name="bankAccountNumber"
                            defaultValue={
                                bankDetails?.bankAccountNumber
                            }
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    readOnly
                                    id={name}
                                    value={value}
                                    label={`5. ${t('ACCOUNT_NUMBER')}`} />
                            )}
                            rules={{ required: true }}
                        />
                        <Button className="grey-gradient-button" type='submit'>
                            {t('WITHDRAW')}
                        </Button>
                    </form>
                </Stack>
                <CustomPicker
                    showWhiteBg
                    isVisible={bankPickerVisible}
                    title={'SELECT_BANK'}
                    options={bankList}
                    selectedOption={watch('bankId')}
                    onSelect={(isSelected = true, value) => {
                        if (isSelected) {
                            setValue('bankId', value);
                            setValue('bankAccountNumber', value.accountNo)
                        }
                        setBankPickerVisible(!bankPickerVisible);
                    }}
                />
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            authorizationSuccess(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('WITHDRAW_WALLET')}</Text>
                        </div>
                        <ConversionTopBar credit={credit} isReward />


                        <Stack mt={6}>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Controller
                                    control={control}
                                    name="username"
                                    defaultValue={username}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            readOnly
                                            id={name}
                                            value={value}
                                            bg='#2c3956 !important'
                                            color='white'
                                            borderRadius='10px !important'
                                            label={`1. ${t('USERNAME')}`} />
                                    )}
                                />
                                <Flex>
                                    <Text fontSize={'md'}>{'2. ' + t('ENTER_AMOUNT')}</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'} fontStyle={'italic'}>{t('MIN_AMOUNT_RM') + withdrawAmountConstraints?.min}</Text>
                                </Flex>
                                <Stack mb='25px'>
                                    <Box w='full' h={10} bg='#2c3956 !important' borderRadius={10} borderColor='#334060' borderWidth={2} mt={2} transitionDuration={'normal'} _hover={{ border: '2px solid white' }}>
                                        <Controller
                                            control={control}
                                            name="amount"
                                            defaultValue={0}
                                            render={({ onChange, value, name }) => (
                                                <Stack direction='row' paddingInline={3}>
                                                    <CurrencyInput
                                                        style={{
                                                            textAlign: 'start',
                                                            backgroundColor: 'transparent',
                                                            color: 'white',
                                                            fontWeight: '500',
                                                            fontSize: '1rem',
                                                            width: '100%',
                                                            outline: 0,
                                                        }}
                                                        id={name}
                                                        name={name}
                                                        value={value}
                                                        placeholder="0.00"
                                                        decimalScale={2}
                                                        autoComplete={false}
                                                        onFocus={(e) => e.target.select()}
                                                        onValueChange={(value, name) => {
                                                            let newValue = value

                                                            if (newValue > credit) {
                                                                newValue = credit;
                                                            }

                                                            onChange(newValue);
                                                        }}
                                                    />
                                                    <Divider borderColor={'white'} orientation='vertical' h={'36px'} />
                                                    <Button variant='link'
                                                        onClick={() => {
                                                            setValue('amount', credit);
                                                        }}>
                                                        <Text fontSize={'sm'}>{t('MAX')}</Text>
                                                    </Button>
                                                </Stack>
                                            )}
                                            rules={{
                                                required: true,
                                                min: withdrawAmountConstraints?.min,
                                                max: withdrawAmountConstraints?.max
                                            }}
                                        />
                                    </Box>
                                    {
                                        errors && errors['amount'] &&
                                        <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                                    }
                                </Stack>
                                <Controller
                                    control={control}
                                    name="bankAccountHolderName"
                                    defaultValue={bankDetails?.bankAccountHolderName}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            readOnly
                                            id={name}
                                            value={value}
                                            bg='#2c3956 !important'
                                            color='white'
                                            borderRadius='10px !important'
                                            label={`3. ${t('BENEFICIARY_NAME')}`} />
                                    )}
                                    rules={{ required: true }}
                                />
                                <Controller
                                    control={control}
                                    name="bankId"
                                    defaultValue={bankDetails?.bankId}
                                    render={({ field }) => (
                                        <>
                                            {/* <Button variant={'link'} w='full' onClick={() => {
                                                        if (bankList.length > 0) {
                                                            setBankPickerVisible(!bankPickerVisible)
                                                        }
                                                        else {
                                                            _dispatch(showCustomDialog({
                                                                isPlainTheme: true,
                                                                title: 'ADD_BANK',
                                                                customContent: (
                                                                    <Text>{t('IT_APPEARS_THAT_YOU_HAVENT_ADDED_A_BANK_FOR_WITHDRAWAL')}</Text>
                                                                ),
                                                                confirmTxt: 'BRING_ME_THERE',
                                                                onConfirm: () => {
                                                                    _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: '', isNewUser: false })
                                                                },
                                                                onCancel: () => { }
                                                            }));
                                                        }
                                                    }}> */}
                                            <Flex mb={3}>
                                                <Text fontSize={'md'}>{`5. ${t('BANK_NAME')}`}</Text>
                                                <Spacer />
                                                <Button variant='link' onClick={() => { _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: watch('bankAccountHolderName'), isNewUser: false }) }}>
                                                    <Text as='i' fontSize={'sm'} decoration='underline'>{t('ADD_BANK_ACCOUNT')}</Text>
                                                </Button>
                                            </Flex>
                                            <Select {...field} color={'white'} mb={6} h={10} background={'#2c3956'} mt={2} fontSize={'md'} errors={errors}
                                                defaultValue={bankDetails?.bankId} _hover={{ border: "2px solid white" }} borderRadius={10} borderColor='#334060' borderWidth={2} 
                                                onChange={(e) => {
                                                    setValue('bankId', bankList.find((item) => item.value == e.target.value));
                                                    setValue('bankAccountNumber', bankList.find((item) => item.value == e.target.value).accountNo);
                                                }}
                                                icon={<TriangleDownIcon fontSize={13} />}>
                                                <option disabled selected style={{ background: "none" }}>{t('SELECT_BANK')}</option>
                                                {bankList.map((option) => (
                                                    <option key={option.value} value={option.value} style={{ background: "none" }}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Select>
                                        </>
                                    )}
                                    rules={{ required: true }}
                                />
                                {
                                    errors && errors["bankId"] &&
                                    <Text mt={-4} mb={6} color={'red'} textAlign={'left'}>{t(getValidationMessage(errors["bankId"]))}</Text>
                                }
                                <Controller
                                    control={control}
                                    name="bankAccountNumber"
                                    defaultValue={
                                        bankDetails?.bankAccountNumber
                                    }
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            readOnly
                                            id={name}
                                            value={value}
                                            bg='#2c3956 !important'
                                            color='white'
                                            borderRadius='10px !important'
                                            label={`5. ${t('ACCOUNT_NUMBER')}`} />
                                    )}
                                    rules={{ required: true }}
                                />
                                <Button
                                w={'100%'} mt={1} color={'white'} height={50} padding={'20px 140px'} borderRadius={15} mb={2} backgroundImage={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : ''} background={activeBtn() ? '' : '#303e5d'}
                                 type='submit'>
                                    {t('WITHDRAW')}
                                </Button>
                            </form>
                        </Stack>                        
                        <PasswordModal
                            authorization={authorizationModal}
                            onPasswordHandler={(isDone = false, authorization) => {
                                if (isDone) {
                                    authorizationSuccess(authorization);
                                }

                                setAuthorizationModal({ ...authorization, visible: false });
                            }} />
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Stack, Flex, Text, Spacer, Input, InputGroup, InputRightElement, Button, Image, Textarea } from '@chakra-ui/react';
import { getValidationMessage, checkBrowser } from "../common/util";
import { ScreenWidth } from "../common/constant";

/// <summary>
/// Author: Saitama
/// </summary>
const CustomInput = React.forwardRef((props, ref) => {
    const {
        id,
        value,
        onChange,
        errors,
        patternErrorTxt,
        label,
        subLabel,
        subLabelStyle,
        subLabelAction,
        placeHolder,
        isPassword = false,
        inputType = 'text', // tel | number, ...
        icon,
        isTextArea = false,
        style,
        isBrowser = false,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(!isPassword);

    return (
        <Stack mb='25px' w='inherit' {...style}>
            <Flex>
                <Text fontSize={!checkBrowser() ? 'sm' : 'md'} mb='5px'>{t(label)}</Text>
                <Spacer />
                {
                    subLabelAction ?
                        <Button variant='link' mb='5px' zIndex={999} onClick={subLabelAction}>
                            <Text as='i' decoration='underline' {...subLabelStyle}>{t(subLabel)}</Text>
                        </Button> :
                        <Text mb='5px' as='i' {...subLabelStyle}>{t(subLabel)}</Text>
                }
            </Flex>
            <InputGroup>
                {
                    isTextArea ?
                        <Textarea
                            id={id}
                            name={id}
                            value={value}
                            onInput={onChange}
                            placeholder={t(placeHolder)}
                            textColor='app.blue'
                            className="input-container"
                            {...restProps} /> :
                        <Input
                            id={id}
                            name={id}
                            value={value}
                            onInput={onChange}
                            type={showPassword ? inputType : 'password'}
                            placeholder={t(placeHolder)}
                            textColor='app.blue'
                            className="input-container"
                            {...restProps} />
                }
                {
                    isPassword &&
                    <InputRightElement width='3.5rem' height={'100%'} >
                        <Button h='1.75rem' size='xs' variant='link' onClick={() => setShowPassword(!showPassword)}>
                            <img src={showPassword ? require('../assets/images/svg/icon-eye-close.svg').default : require('../assets/images/svg/icon-eye.svg').default} alt='betman' />
                        </Button>
                    </InputRightElement>
                }
                {
                    icon &&
                    <InputRightElement height={'100%'} width='3.5rem'>
                        {icon}
                    </InputRightElement>
                }
            </InputGroup>
            {
                errors && errors[id] &&
                <Text color={'red'} fontSize={'sm'} textAlign={'left'}>{t(getValidationMessage(errors[id], patternErrorTxt))}</Text>
            }
        </Stack>
    )
});

export default CustomInput;
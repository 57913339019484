import React, { useEffect, useState, createRef } from "react";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Center,
    Input,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showCustomDialog } from "../../../application/action/app_action";
import { copyToClipboard, share, dataURLtoFile, checkBrowser } from "../../../common/util";
import GeneralTopBar from "../../../component/general-top-bar";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { ApiKey } from "../../../common/constant";
import QrCode from "../../../component/qr-code";
import { useScreenshot, createFileName } from "use-react-screenshot";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { username, alias } = useSelector(state => state.authState.userData);
    const [referralCode, setReferralCode] = useState('-');
    const [referralUrl, setReferralUrl] = useState('-');
    const { isMemberReferral } = _location?.state;
    const _BOTTOM_ELEMENTS = [
        { title: 'SAVE_QR', borderTopLeft: true },
        { title: 'SHARE_QR', borderTopRight: true }
    ];
    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getRefferalLink();
    }, [])

    /// <summary>
    /// Author: Saitama
    ///</summary>
    const getRefferalLink = async () => {
        try {
            let responseJson = await ApiEngine.get(Routes._GET_MEMBER_REFERRAL_LINK);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];

                setReferralCode(data.referralCode);
                setReferralUrl(data.deepLink);
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }))
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function bottomBtnActionHandler(key) {
        switch (key) {
            case _BOTTOM_ELEMENTS[0]['title']:
                downloadScreenshot();
                break;
            case _BOTTOM_ELEMENTS[1]['title']:
                takeScreenShot(ref.current).then((image) => {
                    share(`${alias}-QR`, image);
                })
                break;
            default:
                break;
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const download = (image, { name = `${alias}-QR`, extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'MY_QR_CODE'} />
                <Stack paddingInline={10} pt={15}>
                    {
                        isMemberReferral ?
                            <>
                                <Flex>
                                    <Text color='app.blue' fontSize={'2xl'} as='b'>{referralCode}</Text>
                                    <Button variant='link' onClick={() => {
                                        copyToClipboard(referralCode, t('COPIED_SUCCESFULLY'))
                                    }}>
                                        <Image boxSize='30px' src={require('../../../assets/images/copy.png')} />
                                    </Button>
                                </Flex>
                                <Text mt={-1.5}>{t("MY_REFERRAL_CODE")}</Text>
                            </> :
                            <>
                                <Text color='app.blue' fontSize={'lg'} mb={-2}>{username}</Text>
                                <Text fontSize={'md'}>{alias}</Text>
                            </>
                    }
                    <Box className="my-qr-code-box">
                        <Center ref={ref}>
                            <QrCode
                                value={isMemberReferral ? referralUrl : username}
                                qrSize={228}
                                containerSize={260}
                                borderRadius={20}
                                logoImage={require('../../../assets/images/logo.png')} />
                        </Center>
                    </Box>
                    {
                        isMemberReferral &&
                        <InputGroup size='md' mt={5}>
                            <Input
                                color={'#AACBFF'}
                                value={referralUrl}
                                paddingInline={1}
                                backgroundColor={'#192031'}
                                borderColor={'#334060'}
                                borderRadius={9}
                                w='90%'
                                isReadOnly
                            />
                            <InputRightElement>
                                <Button
                                    h='2.45rem'
                                    className="copy-white-button"
                                    color={'#AACBFF'}
                                    variant={'link'}
                                    size='sm'
                                    onClick={() => copyToClipboard(referralUrl, t('COPIED_SUCCESFULLY'))}>
                                    <Image boxSize={'25px'} src={require('../../../assets/images/copy-white.png')} />
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    }
                </Stack>
                <Flex className="bottom-sticky-button">
                    {
                        _BOTTOM_ELEMENTS.map((key, index) => {
                            return (
                                <Button
                                    key={index}
                                    className="my-qr-bottom-button"
                                    w={'100%'}
                                    style={{
                                        borderTopLeftRadius: key.borderTopLeft ? '20px' : 0,
                                        borderTopRightRadius: key.borderTopRight ? '20px' : 0,
                                    }}
                                    onClick={() => bottomBtnActionHandler(key.title)}>
                                    {t(key.title)}
                                </Button>
                            )
                        })
                    }
                </Flex>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "30rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('MY_QR_CODE')}</Text>
                        </div>
                        <Stack paddingInline={5} mb={2}>
                            {
                                isMemberReferral ?
                                    <>
                                        <Flex margin={'auto'}>
                                            <Text color='app.blue' fontSize={'2xl'} as='b'>{referralCode}</Text>
                                            <Button variant='link' onClick={() => {
                                                copyToClipboard(referralCode, t('COPIED_SUCCESFULLY'))
                                            }}>
                                                <Image boxSize='30px' src={require('../../../assets/images/copy.png')} />
                                            </Button>
                                        </Flex>
                                        <Text margin={'auto'} mt={-1.5}>{t("MY_REFERRAL_CODE")}</Text>
                                    </> :
                                    <>
                                        <Text color='app.blue' fontSize={'lg'} mb={-2}>{username}</Text>
                                        <Text fontSize={'md'}>{alias}</Text>
                                    </>
                            }
                            <Box className="my-qr-code-box" margin={'auto'} mt={4}>
                                <Center ref={ref}>
                                    <QrCode
                                        value={isMemberReferral ? referralUrl : username}
                                        qrSize={228}
                                        containerSize={260}
                                        borderRadius={20}
                                        logoImage={require('../../../assets/images/logo.png')} />
                                </Center>
                            </Box>
                            {
                                isMemberReferral &&
                                <InputGroup size='md' mt={5}>
                                    <Input
                                        color={'#AACBFF'}
                                        value={referralUrl}
                                        backgroundColor={'#192031'}
                                        borderColor={'white'}
                                        _hover={'none'}
                                        borderRadius={9}
                                        w='100%'
                                        isReadOnly
                                    />
                                    <InputRightElement>
                                        <Button
                                            h='2.45rem'
                                            w='100%'
                                            className="copy-white-button"
                                            color={'#AACBFF'}
                                            variant={'link'}
                                            size='sm'
                                            onClick={() => copyToClipboard(referralUrl, t('COPIED_SUCCESFULLY'))}>
                                            <Image boxSize={'25px'} src={require('../../../assets/images/copy-white.png')} />
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            }
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import {
    Text,
    Button,
    Center,
    Flex,
    Stack,
    Spacer,
    Divider,
    Box,
    SimpleGrid
} from '@chakra-ui/react';
import { ApiKey, _AMOUNT_TYPE, ResultImageType } from "../../../common/constant";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../../common/util";
import { Pages } from "../../../routes/page";
import CustomInput from "../../../component/input";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import CurrencyInput from "react-currency-input-field";
import PasswordModal from "../../../component/password-modal";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    var _router = useRouter();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const _SCANNED_USER = _location?.state.data;
    const { credit } = useSelector(state => state.authState.userData);
    const [wordCount, setWordCount] = useState(0);
    const _DESCRIPTION_MAX_LENGTH = 80;
    const [authorizationModal, setAuthorizationModal] = useState({
        signature: null,
        password: '',
        visible: false
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_SCANNED_USER)) {
            _router.back();
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        setWordCount(watch('description').length);
    }, [watch('description')])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const btnAmountHandler = (value) => {
        setValue("amount", value.toString());

        if (errors['amount']) {
            clearErrors('amount');
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        window.scrollTo(0, 0);
        _dispatch(showCustomDialog({
            success: true,
            headerTitle: t('PAYMENT_CONFIRMATION'),
            isPlainTheme: true,
            customContent: <Center>
                <Stack>
                    <Text fontSize={'lg'} fontWeight={'bold'} fontStyle={'italic'}>{t('TRANSFER_CREDIT_DISCLAIMER')}</Text>
                    <Stack align='center'>
                        <SimpleGrid columns={2}>
                            <Text textAlign={'left'} fontWeight={'bold'}>{`${t('AMOUNT')} : `}</Text>
                            <Text textAlign={'left'}>RM {numberWithCurrencyFormat(parseFloat(data?.amount))}</Text>
                            <Text textAlign={'left'} fontWeight={'bold'}>{`${t('RECEIVER')} : `}</Text>
                            <Text textAlign={'left'}>{_SCANNED_USER.username}</Text>
                            <Text textAlign={'left'} fontWeight={'bold'}>{`${t('DESCRIPTION')} : `}</Text>
                            <Text textAlign={'left'}>{!stringIsNullOrEmpty(data?.description) ? data?.description : '-'}</Text>
                        </SimpleGrid>
                    </Stack>
                </Stack>
            </Center>,
            confirmTxt: t("CONFIRM"),
            onConfirm: () => {
                setAuthorizationModal({ ...authorizationModal, visible: true });
            },
            onCancel: () => { },
            horizontalBtn: false
        }))
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const authorizationSuccess = async (authorization) => {
        let params = {
            'receiverUsername': _SCANNED_USER.username,
            'amount': parseFloat(watch('amount')),
            'description': watch('description'),
            'deviceId': window.navigator.userAgent,
            'password': authorization.password
        };

        var responseJson = await ApiEngine.post(Routes._MEMBER_TRANSFER_CREDIT, params, { headers: { 'content-type': ApiKey._API_APPLICATION_JSON } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._CONVERT_SUCCESS, {
                amount: 'RM ' + numberWithCurrencyFormat(parseFloat(watch('amount'))),
                headerTitle: 'TRANSFER_SUCCESS',
                contentTitle: 'YOU_HAVE_TRANSFER',
                contentImageType: ResultImageType._SEND,
                canShare: true,
                details: [
                    { label: 'FROM_USERNAME', value: responseJson[ApiKey._API_DATA_KEY].senderName },
                    { label: 'TO_USERNAME', value: responseJson[ApiKey._API_DATA_KEY].receiverName },
                    {
                        label: 'DESCRIPTION', value: stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY].description) ? '-' :
                            responseJson[ApiKey._API_DATA_KEY].description
                    },
                    { label: 'DATE_AND_TIME', value: responseJson[ApiKey._API_DATA_KEY].parsedUpdatedTime },
                ]
            }, true)
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    return (
        <>
            <GeneralTopBar title={'SEND_CREDIT'}>
                <ConversionTopBar credit={credit} isReward />
            </GeneralTopBar>
            <Stack className="header-margin-top">
                <Flex align={'center'} mb={5}>
                    <Text fontSize={'md'}>{t('TRANSFERRING_TO')}</Text>
                    <Spacer />
                    <img style={{ height: '30px', width: '30px' }} src={require('../../../assets/images/svg/transfer-payment.svg').default} />
                    <Spacer />
                    <Text fontSize={'md'} color={'app.blue'}>{_SCANNED_USER.username}</Text>
                </Flex>
                <Divider className="app-divider-full-width-setting" />
                <form onSubmit={handleSubmit(submitForm)}>
                    <Text>{'1. ' + t('ENTER_AMOUNT')}</Text>
                    <Box w='full' h='12.5vw' background={'#192031'} borderRadius={5} borderColor='#334060' borderWidth={2} mt={2}>
                        <Controller
                            control={control}
                            name="amount"
                            defaultValue={0}
                            render={({ onChange, value, name }) => (
                                <Stack direction='row' paddingInline={3}>
                                    <CurrencyInput
                                        style={{
                                            textAlign: 'start',
                                            backgroundColor: 'transparent',
                                            color: '#AACBFF',
                                            fontWeight: '500',
                                            fontSize: '1rem',
                                            width: '100%',
                                        }}
                                        id={name}
                                        name={name}
                                        value={value}
                                        placeholder="0.00"
                                        decimalScale={2}
                                        autoComplete={false}
                                        onFocus={(e) => e.target.select()}
                                        onValueChange={(value, name) => {
                                            let newValue = value

                                            if (newValue > credit) {
                                                newValue = credit;
                                            }

                                            onChange(newValue);
                                        }}
                                    />
                                    <Divider borderColor={'#2D3C5C'} orientation='vertical' h='12.5vw' />
                                    <Button variant='link'
                                        onClick={() => {
                                            setValue('amount', credit);
                                        }}>
                                        <Text fontSize={'sm'}>{t('MAX')}</Text>
                                    </Button>
                                </Stack>
                            )}
                            rules={{
                                required: true,
                                min: 1,
                                max: credit
                            }}
                        />
                    </Box>
                    {
                        errors && errors['amount'] &&
                        <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                    }
                    <Flex marginBlock={5}>
                        {
                            _AMOUNT_TYPE.map((item, index) => {
                                return (
                                    <>
                                        <Button
                                            className={`amount-button ${item.amount == watch('amount') ? 'selected' : ''}`}

                                            onClick={() => btnAmountHandler(item.amount)}>
                                            {'RM ' + item.amount}
                                        </Button>
                                        {
                                            index + 1 != _AMOUNT_TYPE.length &&
                                            <Spacer />
                                        }
                                    </>
                                )
                            })
                        }
                    </Flex>
                    <Controller
                        control={control}
                        name="description"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                isTextArea
                                id={name}
                                onChange={(value) =>
                                    onChange(value)
                                }
                                value={value}
                                label={`2. ${t('ENTER_DESCRIPTION')}` + ' (' + wordCount + '/' + _DESCRIPTION_MAX_LENGTH + ')'}
                                errors={errors} />
                        )}
                    />
                    <Button className="grey-gradient-button" type='submit'>
                        {t('NEXT')}
                    </Button>
                </form>
            </Stack>
            <PasswordModal
                authorization={authorizationModal}
                onPasswordHandler={(isDone = false, authorization) => {
                    if (isDone) {
                        authorizationSuccess(authorization);
                    }

                    setAuthorizationModal({ ...authorization, visible: false });
                }} />
        </>
    )
}
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouter } from "../../../hooks/useRouter";
import { stringIsNullOrEmpty, isObjectEmpty, checkBrowser } from "../../../common/util";
import GeneralTopBar from "../../../component/general-top-bar";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { ApiKey } from "../../../common/constant";
import { Text, Flex, Spinner, Progress, HStack, Image, Spacer } from "@chakra-ui/react";
import { LiveChatWidget } from "@livechat/widget-react";
import ChatwootWidget from "../../../component/ChatwootWidget";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    const { t } = useTranslation();
    const _PARAMS = _location?.state;
    const [liveChatConfiguration, setLiveChatConfiguration] = useState({});
    const [liveChatAuth, setLiveChatAuth] = useState({});

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PARAMS)) {
            _router.back();
        }
        else {
            loadLiveChatPage();
        }
    }, [])

    /// <summary>
    /// Author: CK
    /// </summary>
    const loadLiveChatPage = async () => {
        try {
            var responseJson = await ApiEngine.get(
                Routes._INITIATE_LIVE_CHAT_CONFIGURATION + "?phoneNumber=" + _PARAMS.phone + "&department=" + _PARAMS.department
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let liveChatData = responseJson[ApiKey._API_DATA_KEY];
                let tokenObject = {
                    accessToken: liveChatData.accessToken,
                    entityId: liveChatData.entityId,
                    expiresIn: liveChatData.expiresIn,
                    tokenType: 'Bearer',
                    creationDate: Math.round((new Date()).getTime() / 1000),
                    licenseId: liveChatData.lid,
                };

                setLiveChatAuth(tokenObject);
                setLiveChatConfiguration(responseJson[ApiKey._API_DATA_KEY]);
            }
        }
        catch (err) {
            alert(err);
        }
    };

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'CUSTOMER_SERVICE'} />
                {/* <ChatwootWidget /> */}
                {
                    !isObjectEmpty(liveChatConfiguration) && !isObjectEmpty(liveChatAuth) ?
                        <Flex>
                            <Progress size='lg' isIndeterminate colorScheme={'yellow'} className="progress-bar" />
                            <LiveChatWidget
                                license={liveChatConfiguration.lid}
                                group={_PARAMS.department}
                                customerName={liveChatConfiguration.phoneNumber}
                                customerEmail={_PARAMS.email}
                                visibility="maximized"
                                customIdentityProvider={() => {
                                    return {
                                        getToken: () => Promise.resolve(liveChatAuth),
                                        getFreshToken: () => Promise.resolve(liveChatAuth),
                                        hasToken: () => Promise.resolve(false),
                                        invalidate: () => Promise.resolve()
                                    }
                                }}
                            />
                        </Flex>
                        :
                        <Flex direction='row' align={'center'} mt='10px'>
                            <Text fontSize='md'>Loading for redirection...</Text>
                            <Spinner size='sm' color="#AACBFF" ml='10px' />
                        </Flex>
                }
            </div>
        )
    } else {
        return (
            <>
                <div style={{ width: "100%", backgroundImage: "linear-gradient(to right, #192133, #3a4b6f, #192133)", textAlign: "center", marginTop: "-5rem" }}>
                    <HStack paddingInline={6} paddingBlock={1} spacing={6} maxW={'100rem'} margin={'auto'}>
                        <Image
                            boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                            src={require('../../../assets/images/svg/back-desktop.svg').default} />
                        <Spacer />
                        <Text mr={10} textAlign={'center'} fontWeight={'bold'}>{t('CUSTOMER_SERVICE')}</Text>
                        <Spacer />
                    </HStack>
                </div>
                {
                    !isObjectEmpty(liveChatConfiguration) && !isObjectEmpty(liveChatAuth) ?
                        <Flex>
                            <Progress size='lg' isIndeterminate colorScheme={'yellow'} className="progress-bar" marginLeft={0} />
                            <LiveChatWidget
                                license={liveChatConfiguration.lid}
                                group={_PARAMS.department}
                                customerName={liveChatConfiguration.phoneNumber}
                                customerEmail={_PARAMS.email}
                                visibility="maximized"
                                customIdentityProvider={() => {
                                    return {
                                        getToken: () => Promise.resolve(liveChatAuth),
                                        getFreshToken: () => Promise.resolve(liveChatAuth),
                                        hasToken: () => Promise.resolve(false),
                                        invalidate: () => Promise.resolve()
                                    }
                                }}
                            />
                        </Flex>
                        :
                        <Flex direction='row' align={'center'} mt='10px'>
                            <Text fontSize='md'>Loading for redirection...</Text>
                            <Spinner size='sm' color="#AACBFF" ml='10px' />
                        </Flex>
                }
                <Footer />
            </>
        )
    }
}
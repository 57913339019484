import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { Pages } from "../../../routes/page";
import { useLocation, useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
    Stack,
    Text,
    Divider,
    Button,
    Card,
    CardBody,
    useDisclosure,
    Center,
    Image,
    Select
} from '@chakra-ui/react';
import { PageSettings } from "../../../routes/setting";
import GeneralTopBar from "../../../component/general-top-bar";
import { checkBrowser, isObjectEmpty, stringIsNullOrEmpty, getValidationMessage } from "../../../common/util";
import { ApiKey, BG_TYPE } from "../../../common/constant";
import CustomInput from "../../../component/input";
import CustomPicker from "../../../component/custom-picker";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { showCustomDialog } from "../../../application/action/app_action";
import { ConfigEnum } from "../../../common/config";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { TriangleDownIcon } from "@chakra-ui/icons";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    const { control, handleSubmit, errors, watch, setValue } = useForm();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _PARAMS = _location?.state;
    const [bankPickerVisible, setBankPickerVisible] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [finishStatus, setfinishStatus] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);

        if (isObjectEmpty(_PARAMS)) {
            _router.back();
        }
        else {
            getBankList();

            if (!stringIsNullOrEmpty(_PARAMS.userBankId)) {
                getUserBankInfo();
            }

            if (_PARAMS.isNewUser) {
                window.history.pushState(null, null, window.location.pathname);
                window.addEventListener('popstate', onBackButtonEvent);
            }
        }

        return () => {
            _context.toggleBg(BG_TYPE.blur);

            if (!isObjectEmpty(_PARAMS) && _PARAMS.isNewUser) {
                window.removeEventListener('popstate', onBackButtonEvent);
            }
        };
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {
            giveUpWelcomeBonus();
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('bankId')) && !stringIsNullOrEmpty(watch('bankAccountNumber')) || !stringIsNullOrEmpty(watch('bankAccountNumber')));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getBankList = async () => {
        try {
            let responseJson = await ApiEngine.get(Routes._GET_BANK_LIST);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setBankList(
                responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                    imagePath: item?.logo
                }))
            );
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getUserBankInfo = async () => {
        try {
            let responseJson = await ApiEngine.get(`${Routes._GET_USER_BANK_ACCOUNT}/${_PARAMS.userBankId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let bankAccount = responseJson[ApiKey._API_DATA_KEY];

            if (bankAccount) {
                setValue('bankId', { label: bankAccount?.bankName, value: bankAccount?.bankId });
                setValue('bankAccountNumber', bankAccount?.bankAccountNumber);
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const giveUpWelcomeBonus = async () => {
        _dispatch(
            showCustomDialog({
                title: t('ARE_YOU_SURE'),
                content: t("ARE_YOU_SURE_YOU_WANT_GIVE_UP_WELCOME_BONUS", { bonus: _PARAMS.bonus }),
                horizontalBtn: false,
                confirmTxt: t('YES'),
                onConfirm: () => {
                    setfinishStatus(true);
                    _router.navigate(Pages._LOGIN, { username: _PARAMS.username }, true)
                },
                cancelTxt: t('NO'),
                onCancel: () => {
                    window.history.pushState(null, null, window.location.pathname);
                    setfinishStatus(false);
                }
            })
        );
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            window.scrollTo(0, 0);
            let params = {
                ...data,
                bankAccountHolderName: stringIsNullOrEmpty(_PARAMS?.bankAccountHolderName) ? data.bankAccountHolderName : _PARAMS?.bankAccountHolderName,
                isNewUser: _PARAMS?.isNewUser,
                bankId: data.bankId.value
            }

            if (_PARAMS?.isNewUser) {
                params['userId'] = _PARAMS?.userId;
            }

            if (!stringIsNullOrEmpty(_PARAMS?.userBankId)) {
                params['id'] = parseInt(_PARAMS?.userBankId);
            }

            let responseJson = await ApiEngine.post(!stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._BEARER_TOKEN)) ? Routes._CREATE_OR_UPDATE_BANK : Routes._CREATE_BANK_FOR_WELCOME_BONUS, params, { ...ApplicationJsonHeaderConfig });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
            }

            _dispatch(showCustomDialog({
                success: true,
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                customContent: (
                    <>
                        {
                            _PARAMS?.isNewUser &&
                            <Text fontSize={'md'} fontWeight={'bold'} fontStyle={'italic'}>{t('YOU_WILL_HAVE_TO_CLAIM_THIS_THRU_REWARD_CONVERT')}</Text>
                        }
                    </>
                ),
                onConfirm: () => {
                    if (_PARAMS.isNewUser) {
                        _router.navigate(Pages._LOGIN, { username: _PARAMS.username }, true);
                    }
                    else {
                        _router.back();
                    }
                }
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={t(!stringIsNullOrEmpty(_PARAMS?.userBankId) ? "EDIT_BANK" : "ADD_BANK")} bgColor="black" />
                <Stack paddingInline={5} pt={10} w={'full'}>
                    <form onSubmit={handleSubmit(submitForm)} className="display-center">
                        <Card className="grey-box">
                            <CardBody>
                                <Button variant={'link'} w='full' onClick={() => setBankPickerVisible(!bankPickerVisible)}>
                                    <Controller
                                        control={control}
                                        name="bankId"
                                        defaultValue={''}
                                        render={({ value, name }) => (
                                            <CustomInput
                                                isReadOnly
                                                id={name}
                                                value={value?.label}
                                                label='BANK_NAME'
                                                placeholder={t('SELECT_BANK')}
                                                errors={errors}
                                                icon={
                                                    <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                                }
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </Button>
                                {
                                    stringIsNullOrEmpty(_PARAMS?.bankAccountHolderName) &&
                                    <>
                                        <Controller
                                            control={control}
                                            name="bankAccountHolderName"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    errors={errors}
                                                    label='BENEFICIARY_NAME'
                                                    placeholder={t('BENEFICIARY_NAME')} />
                                            )}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <Text mt={-5} mb={25} color={'red'}>{t('PLEASE_MAKE_SURE_IT_IS_MATCHED_WITH_BANK_ACC_NO_CANNOT_CHANGE')}</Text>
                                    </>
                                }
                                <Controller
                                    control={control}
                                    name="bankAccountNumber"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            errors={errors}
                                            label='ACCOUNT_NUMBER'
                                            placeholder={t('ACCOUNT_NUMBER')}
                                            inputMode='tel' />
                                    )}
                                    rules={{
                                        required: true
                                    }}
                                />
                            </CardBody>
                            <Divider />
                            <CardBody>
                                <Button className="grey-button" type="submit">{t('SUBMIT')}</Button>
                            </CardBody>
                        </Card>
                    </form>
                </Stack>
                <CustomPicker
                    showWhiteBg
                    isVisible={bankPickerVisible}
                    title={'SELECT_BANK'}
                    options={bankList}
                    selectedOption={watch('bankId')}
                    onSelect={(isSelected = true, value) => {
                        if (isSelected) {
                            setValue('bankId', value);
                        }
                        setBankPickerVisible(!bankPickerVisible);
                    }}
                />
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem", background: "#161f30" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t(!stringIsNullOrEmpty(_PARAMS?.userBankId) ? "EDIT_BANK" : "ADD_BANK")}</Text>
                        </div>
                        <Stack>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Card>
                                    <CardBody paddingInline={4} paddingBlock={0}>
                                        <Text fontSize={'md'} mb={3}>{t('BANK_NAME')}</Text>
                                        <Controller
                                            control={control}
                                            name="bankId"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <Select value={!stringIsNullOrEmpty(_PARAMS?.userBankId) ? watch('bankId') : null} color={'white'} bg={'#2c3956'} height={50} fontSize={'sm'} mb={6} _hover={{ border: '2px solid white' }}
                                                    fontWeight={'bold'} borderRadius={10} errors={errors} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}
                                                    onChange={(e) => {
                                                        setValue('bankId', bankList.find((item) => item.value == e.target.value));
                                                    }}
                                                    icon={<TriangleDownIcon fontSize={13} />}>
                                                    <option disabled selected style={{ background: "none" }}>{t('SELECT_BANK')}</option>
                                                    {bankList.map((option) => (
                                                        <option key={option.value} value={option.value} style={{ background: "none" }}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                        {errors && errors['bankId'] &&
                                            <Text mt={-5} mb={25} color={'red'}>{t(getValidationMessage(errors["bankId"]))}</Text>
                                        }
                                        {
                                            stringIsNullOrEmpty(_PARAMS?.bankAccountHolderName) &&
                                            <>
                                                <Controller
                                                    control={control}
                                                    name="bankAccountHolderName"
                                                    defaultValue={''}
                                                    render={({ onChange, value, name }) => (
                                                        <CustomInput
                                                            id={name}
                                                            onChange={(value) =>
                                                                onChange(value)
                                                            }
                                                            value={value}
                                                            borderRadius="10px !important"
                                                            boxShadow="none !important"
                                                            bg="#2c3956 !important"
                                                            color="white"
                                                            height="50px"
                                                            errors={errors}
                                                            label='BENEFICIARY_NAME' />
                                                    )}
                                                    rules={{
                                                        required: true
                                                    }}
                                                />
                                                {errors && errors['bankAccountHolderName'] &&
                                                    <Text mt={-5} mb={25} color={'red'}>{t('PLEASE_MAKE_SURE_IT_IS_MATCHED_WITH_BANK_ACC_NO_CANNOT_CHANGE')}</Text>
                                                }
                                            </>
                                        }
                                        <Controller
                                            control={control}
                                            name="bankAccountNumber"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    borderRadius="10px !important"
                                                    boxShadow="none !important"
                                                    bg="#2c3956 !important"
                                                    color="white"
                                                    fontWeight="bold"
                                                    height="50px"
                                                    errors={errors}
                                                    label='ACCOUNT_NUMBER'
                                                    inputMode='tel' />
                                            )}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </CardBody>
                                    <Divider />
                                    <CardBody paddingBlock={3}>
                                        <Button
                                            w={'100%'} color={'white'} padding={'25px 140px'} borderRadius={15} backgroundImage={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : ''} background={activeBtn() ? '' : '#303e5d'}
                                            type="submit">{t('SUBMIT')}</Button>
                                    </CardBody>
                                </Card>
                            </form>
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React from "react";
import { useTranslation } from 'react-i18next';
import { Stack, Text, Image, Badge, Flex, Button, Box, Center } from '@chakra-ui/react';
import { useRouter } from "../hooks/useRouter";
import { useDispatch } from "react-redux";
import { getMemberDetails } from "../application/action/auth_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({
    title,
    children,
    onBack,
    status,
    onBackGetMemberDetails = true,
    isBeta = false,
    rightComponent,
    useSimpleBackIcon = false,
    bgColor = '#1E283A',
    blackTitle = false,
    expandRightComponent = false,
    titleAlignLeft = false,
    style = {}
}) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onBackHandler = () => {
        if (onBack && !status) {
            if (onBackGetMemberDetails) {
                _dispatch(getMemberDetails());
            }
            onBack();
        } else {
            _router.back();
        }
    }

    return (
        <Stack className={`app-general-top-bar ${children ? "with-content" : ""}`} backgroundColor={bgColor} style={style}>
            <Stack className="stack-container">
                <Flex justify={'space-between'} align={'center'} w={'full'} mb={children && '0.25rem'}>
                    <Box flex={[0.2, 0.1]}>
                        <Center>
                            <Button variant='link' onClick={() => onBackHandler()}>
                                {
                                    useSimpleBackIcon ?
                                        <Image src={require('../assets/images/left.png')} boxSize={'30px'} objectFit='cover' /> :
                                        <Image src={require('../assets/images/general-back.png')} boxSize={'30px'} objectFit='cover' />
                                }
                            </Button>
                        </Center>
                    </Box>
                    <Center flex={1} justifyContent={titleAlignLeft ? 'flex-start' : 'center'}>
                        <Text fontSize='md' as='b' color={blackTitle ? 'black' : 'white'}>{t(title)} {isBeta && <Badge colorScheme='twitter' w='fit-content' borderRadius='20px'>{t('BETA')}</Badge>}</Text>
                    </Center>
                    <Box flex={expandRightComponent ? [0.85, 0.1] : [0.2, 0.1]}>
                        {
                            rightComponent
                        }
                    </Box>
                </Flex>
                {children}
            </Stack>
        </Stack>
    )
}

/// <summary>
/// Author: Saitama
/// </summary>
import axios from 'axios';
import { ApiKey } from './constant';
import { _BASE_URL } from './api_routes';
import { Config, ConfigEnum } from './config';
import { stringIsNullOrEmpty } from './util';
import * as AppActions from '../application/action/app_action';
import { updateUserData, performLogout } from '../application/action/auth_action';
import store from '../application/store';
import i18n from '../localization/i18n';

const _REQUEST_COOLDOWN = 3000;
let _pendingRequest = [];

/// <summary>
/// Author : Saitama
/// </summary>
async function interceptRequest(requestConfig) {
    const token = localStorage.getItem(ConfigEnum._BEARER_TOKEN);

    checkPendingRequest(requestConfig);

    if (
        requestConfig.headers.skipLoading === undefined ||
        requestConfig.headers.skipLoading === false
    ) {
        store.dispatch(AppActions.setBusy());
    }

    if (!stringIsNullOrEmpty(token)) {
        requestConfig.headers.Authorization = 'Bearer ' + token;
    }

    requestConfig.headers['language'] = localStorage.getItem(ConfigEnum._LANGUAGE);

    requestConfig.cancelToken = new axios.CancelToken((cancel) => {
        _pendingRequest.push({
            url: requestConfig.url,
            removeRequest: cancel,
            requestTime: new Date().getTime()
        });
    });

    return requestConfig;
}

/// <summary>
/// Author : Saitama
/// </summary>
const checkPendingRequest = (config) => {
    if (
        _pendingRequest.some(
            (request) =>
                request.url == config.url &&
                request.requestTime < _REQUEST_COOLDOWN
        )
    ) {
        for (
            var i = 0;
            i <
            _pendingRequest.filter(
                (request) =>
                    request.url == config.url &&
                    request.requestTime < _REQUEST_COOLDOWN
            ).length;
            i++
        ) {
            _pendingRequest[i].removeRequest();
        }
    }
};

/// <summary>
/// Author : Saitama
/// </summary>
function interceptResponse(response) {
    var returnData = {};
    store.dispatch(AppActions.setIdle());
    _pendingRequest = [];

    // return only if response url starts with expected base url and the http status code is 200
    if (response[ApiKey._API_STATUS_KEY] == ApiKey._API_STATUS_OK) {
        returnData = response[ApiKey._API_DATA_KEY];
    } else {
        returnData[ApiKey._API_STATUS_KEY] = false;
        returnData[ApiKey._API_MESSAGE_KEY] =
            'Server maintainance... Please try again later';
    }

    return returnData;
}

/// <summary>
/// Author : Saitama
/// </summary>
function interceptError(error) {
    var returnData = {};

    store.dispatch(AppActions.setIdle());

    if (error.response) {
        if (error.response.status === 401) {
            returnData[ApiKey._API_SUCCESS_KEY] = false;
            store.dispatch(performLogout());
        } else {
            returnData[ApiKey._API_SUCCESS_KEY] = false;
            returnData[ApiKey._API_MESSAGE_KEY] =
                'Error ' +
                error.response.status +
                ' encounter, please contact admin for help';
        }
    } else if (!axios.isCancel(error)) {
        returnData[ApiKey._API_STATUS_KEY] = false;
        returnData[ApiKey._API_MESSAGE_KEY] = !stringIsNullOrEmpty(
            error['message']
        )
            ? JSON.stringify(error['message'])
            : 'Error ' +
              error.response.status +
              ' encounter, please contact admin for help';
    } else {
        // mean it is canceled
        returnData[ApiKey._API_STATUS_KEY] = false;
        returnData[ApiKey._API_MESSAGE_KEY] = i18n.t('SERVER_IS_PROCESSING');
    }

    return returnData;
}

/// <summary>
/// Author : Saitama
/// </summary>
const ApiEngine = axios.create({
    baseURL: _BASE_URL,
    headers: {
        'content-type': ApiKey._API_FORM_URLENCODED
    },
    timeout: 60000
});

/// <summary>
/// Author : Saitama
/// </summary>
ApiEngine.interceptors.request.use(
    (config) => interceptRequest(config),
    (error) => interceptError(error)
);

/// <summary>
/// Author : Saitama
/// </summary>
ApiEngine.interceptors.response.use(
    (response) => interceptResponse(response),
    (error) => interceptError(error)
);

/// <summary>
/// Author : Saitama
/// </summary>
export const FormHeaderConfig = {
    headers: {
        'content-type': ApiKey._API_FORM_URLENCODED
    }
};

/// <summary>
/// Author : Saitama
/// </summary>
export const ApplicationJsonHeaderConfig = {
    headers: {
        'content-type': ApiKey._API_APPLICATION_JSON
    }
};

export default ApiEngine;

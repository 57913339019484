import React, { useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { PageSettings } from "../../routes/setting";
import {
    Stack, HStack, Text, Image, Spacer, Card, CardBody, CardFooter, Flex, Divider, Button, Center,
} from '@chakra-ui/react';
import CustomInput from "../../component/input";
import { useForm, Controller } from 'react-hook-form';
import { ValidationPattern, _LANGUAGE_OPTION, _RECEIPT_WATERMARK, ScreenWidth } from "../../common/constant";
import { useRouter } from "../../hooks/useRouter";
import { useLocation } from "react-router-dom";
import { Pages } from "../../routes/page";
import { useDispatch } from "react-redux";
import { setBusy, setIdle } from '../../application/action/app_action';
import { performLogin } from '../../application/action/auth_action';
import { showCustomDialog } from "../../application/action/app_action";
import { checkBrowser, isObjectEmpty, stringIsNullOrEmpty } from "../../common/util";
import { isMobile } from "react-device-detect";
import Footer from "./footer";
import Header from "./header";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { i18n, t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const _PARAMS = _location?.state;

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(_PARAMS)) {
            setValue('phoneNo', _PARAMS?.username);
        }
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            _dispatch(setBusy());
            window.scrollTo(0, 0);

            let loginResponse = await _dispatch(
                performLogin(data.phoneNo, data.password)
            );

            if (loginResponse) {
                _router.navigate(Pages._SECONDARY_PASSWORD_LOGIN, { ...loginResponse })
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('phoneNo')) && !stringIsNullOrEmpty(watch('password')));
    }

    if (!checkBrowser()) {
        return (
            <Stack spacing={5}>
                <Flex>
                    <Text noOfLines={2} fontSize='3xl' fontWeight={'bold'} width={'38%'}>{t('MEMBER_SIGN_IN')}</Text>
                    <Spacer />
                    <Image boxSize='100px' objectFit='contain' src={require('../../assets/images/logo.png')} alt='BETMAN' />
                </Flex>
                <Text fontSize='lg'>{t('HELLO_AND_WELCOME_PLEASE_LOG_IN')}</Text>
                <Card backgroundColor={'#232E46'}>
                    <CardBody>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Controller
                                control={control}
                                name="phoneNo"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        label='ENTER_MOBILE_NUMBER'
                                        inputType="tel"
                                        errors={errors}
                                        patternErrorTxt={t(
                                            'PLEASE_FILL_VALID_PHONE_NO'
                                        )} />
                                )}
                                rules={{
                                    required: true,
                                    pattern:
                                        ValidationPattern._PHONE_NUMBER_REGEX
                                }}
                            />
                            <Controller
                                control={control}
                                name="password"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        errors={errors}
                                        label='ENTER_PASSWORD'
                                        subLabel='FORGOT_PASSWORD'
                                        subLabelStyle={{ color: 'app.blue', decoration: 'underline' }}
                                        subLabelAction={() => { _router.navigate(Pages._FORGOT_PASSWORD); }}
                                        inputMode='tel'
                                        isPassword />
                                )}
                                rules={{
                                    required: true,
                                    minLength: 6,
                                    maxLength: 6,
                                    pattern: ValidationPattern._DIGIT_REGEX
                                }}
                            />
                            <Button className="grey-button" w='100%' type='submit'>{t('LOGIN')}</Button>
                        </form>
                    </CardBody>
                    <Divider className="app-divider" />
                    <CardFooter justify={'center'}>
                        <Text color={'app.grey'}>{t('DONT_HAVE_AN_ACCOUNT')}</Text>
                        <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._REGISTER) }}><Text color={'app.blue'} decoration='underline'>{t('REGISTER_NOW')}</Text></Button>
                    </CardFooter>
                </Card>
            </Stack>
        )
    }
    else {
        return (
            <>
                <Center margin="50px auto 0 auto">
                    <HStack spacing='100px'>
                        <div>
                            <Image boxSize='250px' objectFit='contain' src={require('../../assets/images/logo.png')} alt='BETMAN' />
                        </div>
                        <div>
                            <Text fontSize='2xl' fontWeight={'bold'} marginBottom={"5px"}>{t('MEMBER_LOGIN')}</Text>
                            <Text fontSize='md' color={"white"} marginBottom={"35px"}>{t('HELLO_AND_WELCOME_PLEASE_LOG_IN')}</Text>
                            <Card border="1px solid white" borderRadius="15px" backgroundColor={'#232E46'} padding="12px 5px" minWidth="480px">
                                <CardBody>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(e) =>
                                                        onChange(e.currentTarget.value)
                                                    }
                                                    value={value}
                                                    label='ENTER_MOBILE_NUMBER'
                                                    inputType="tel"
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    errors={errors}
                                                    patternErrorTxt={t(
                                                        'PLEASE_FILL_VALID_PHONE_NO'
                                                    )} />
                                            )}
                                            rules={{
                                                required: true,
                                                pattern:
                                                    ValidationPattern._PHONE_NUMBER_REGEX
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="password"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    errors={errors}
                                                    label='ENTER_PASSWORD'
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    marginBottom="10px"
                                                    subLabel='FORGOT_PASSWORD?'
                                                    subLabelStyle={{ color: 'app.blue', decoration: 'none', fontWeight: 'normal' }}
                                                    subLabelAction={() => { _router.navigate(Pages._FORGOT_PASSWORD); }}
                                                    inputMode='tel'
                                                    isPassword
                                                    isBrowser />
                                            )}
                                            rules={{
                                                required: true,
                                                minLength: 6,
                                                maxLength: 6,
                                                pattern: ValidationPattern._DIGIT_REGEX
                                            }}
                                        />
                                        <Button style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#303e5d", color: "white", padding: "25px 0px" }} w='100%' type='submit'>{t('LOGIN')}</Button>
                                    </form>
                                </CardBody>
                                <CardFooter justify={'center'}>
                                    <Text color={'app.grey'}>{t('DONT_HAVE_AN_ACCOUNT')}</Text>
                                    <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._REGISTER) }}><Text color={'app.blue'} decoration='underline' fontWeight='normal' >{t('REGISTER_NOW')}</Text></Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </HStack>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouter } from "../hooks/useRouter";
import { checkBrowser, stringIsNullOrEmpty } from "../common/util";
import GeneralTopBar from "../component/general-top-bar";
import Iframe from 'react-iframe';
import {
    Text,
    Image,
    HStack,
    Spacer
} from '@chakra-ui/react';

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    const { t } = useTranslation();
    const { dataUrl, title } = _location?.state;

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(dataUrl)) {
            _router.back();
        }
    }, [])

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={title ?? 'GENERAL_HELP'} />
                <Iframe url={dataUrl} className="web-view-frame" />
            </div>
        )
    } else {
        return (
            <>
                <div style={{ width: "100%", position: "fixed", top: "0", zIndex: "99999", backgroundImage: "linear-gradient(to right, #192133, #3a4b6f, #192133)", textAlign: "center" }}>
                    <HStack paddingInline={6} paddingBlock={1} spacing={6} maxW={'100rem'} margin={'auto'}>
                        <Image
                            boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                            src={require('../assets/images/svg/back-desktop.svg').default} />
                        <Spacer />
                        <Text textAlign={'center'} mr={10} fontWeight={'bold'}>{title ?? 'GENERAL_HELP'}</Text>
                        <Spacer />
                    </HStack>
                </div>
                <Iframe url={dataUrl} className="web-view-frame" styles={{ paddingTop: "3rem" }} />
            </>
        )
    }
}
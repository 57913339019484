import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    Stack,
    Divider,
    Text,
    Center
} from '@chakra-ui/react';
import { disposeCustomDialog } from "../application/action/app_action";
import { isObjectEmpty, stringIsNullOrEmpty, checkBrowser } from "../common/util";
import { _DEBOUNCE_DURATION_MS, ScreenWidth } from "../common/constant";
import moment from 'moment';
import { isMobile } from "react-device-detect";

/// <summary>
/// Author: Saitama
/// </summary>
const CustomDialog = ({
    isVisible,
    success,
    headerTitle,
    title,
    content,
    onConfirm,
    onCancel,
    headerIcon,
    footerContent,
    confirmTxt = 'OK',
    customContent,
    hideTitle = false,
    hideFooter = false,
    cancelTxt,
    isManualHideAlert = false,
    horizontalBtn = true,
    disableHardwareBackPress = false,
    isPlainTheme = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();
    const [buttonClickTime, setButtonClickTime] = useState(null);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        dispatch(disposeCustomDialog());
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function canelHandler() {
        if (onCancel) {
            onCancel();
        }

        closeDialogHandler();
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const confirmHandler = () => {
        let currentTime = moment();

        if (
            buttonClickTime == null ||
            currentTime.diff(moment(buttonClickTime)) > _DEBOUNCE_DURATION_MS
        ) {
            setButtonClickTime(currentTime);

            if (onConfirm) {
                onConfirm();
            }

            if (!isManualHideAlert) {
                closeDialogHandler();
            }
        }
    };

    if (!checkBrowser()) {
        return (
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    closeDialogHandler();
                }}
                isOpen={isOpen}
                isCentered
                closeOnOverlayClick={disableHardwareBackPress}
                size={['xs', 'xs', 'lg']}
            >
                <AlertDialogOverlay style={{ zIndex: 999999 }} />
                <AlertDialogContent className="app-modal-content">
                    {
                        !isPlainTheme &&
                        <AlertDialogHeader className="app-modal-header">
                            {
                                headerTitle &&
                                <Text fontSize='xl' as='b'>{t(headerTitle)}</Text>
                            }
                            {
                                !hideTitle && (!stringIsNullOrEmpty(title) ||
                                    stringIsNullOrEmpty(headerTitle)) &&
                                <Text fontSize='xl' as='b'>
                                    {
                                        !stringIsNullOrEmpty(title) ? t(title) :
                                            success ? t('SUCCESS') : t('OOPS')
                                    }
                                </Text>
                            }
                        </AlertDialogHeader>
                    }
                    <Divider />
                    <AlertDialogBody className="app-modal-body">
                        {
                            !isPlainTheme &&
                            <Text fontSize='md'>
                                {
                                    !stringIsNullOrEmpty(content) ? t(content) :
                                        success ? t('OPERATION_SUCCESS') : t('INTERNAL_SERVER_ERROR')
                                }
                            </Text>
                        }
                        {customContent && <div style={{ marginTop: '0.3rem' }}>{customContent}</div>}
                    </AlertDialogBody>
                    {
                        !hideFooter &&
                        <AlertDialogFooter className="app-modal-footer">
                            <Stack direction={horizontalBtn ? 'column' : 'row'} w='100%' gap={0}>
                                {
                                    onCancel &&
                                    <Button
                                        w='100%'
                                        className="modal-cancel-button"
                                        ref={cancelRef}
                                        onClick={() => canelHandler()}>
                                        {cancelTxt
                                            ? t(cancelTxt)
                                            : t('CANCEL')}
                                    </Button>
                                }
                                <Button
                                    onClick={() => confirmHandler()}
                                    w='100%'
                                    className="modal-ok-button"
                                    style={{ borderBottomLeftRadius: onCancel ? 0 : 11, borderBottomLeftRadius: horizontalBtn ? 11 : 0 }}>
                                    {t(confirmTxt)}
                                </Button>
                            </Stack>
                        </AlertDialogFooter>
                    }
                </AlertDialogContent>
            </AlertDialog>
        )
    } else {
        return (
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    closeDialogHandler();
                }}
                isOpen={isOpen}
                isCentered
                closeOnOverlayClick={disableHardwareBackPress}
                size={['xs', 'xs', 'lg']}
            >
                <AlertDialogOverlay style={{ zIndex: 999999 }} />
                <AlertDialogContent bg={'#303e5d'} borderRadius={10} border={'1px solid white'} paddingBlock={8}>
                    <AlertDialogBody textAlign={'center'} mb={6} mt={1}>
                        {
                            !isPlainTheme &&
                            <Text fontSize='lg' fontWeight={'bold'}>
                                {
                                    !stringIsNullOrEmpty(content) ? t(content) :
                                        success ? t('OPERATION_SUCCESS') : t('INTERNAL_SERVER_ERROR')
                                }
                            </Text>
                        }
                        {customContent && <div style={{ marginTop: '0.3rem' }}>{customContent}</div>}
                    </AlertDialogBody>
                    {
                        !hideFooter &&
                        <Center>
                            <AlertDialogFooter>
                                <Stack direction={horizontalBtn ? 'column' : 'row'} w='100%' spacing={horizontalBtn ? 4 : 10}>
                                    {
                                        onCancel &&
                                        <Button
                                            w={36}
                                            bg={'#182031'}
                                            color={'white'}
                                            borderRadius={20}
                                            ref={cancelRef}
                                            onClick={() => canelHandler()}>
                                            {cancelTxt
                                                ? t(cancelTxt)
                                                : t('CANCEL')}
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => confirmHandler()}
                                        w={36}
                                        backgroundImage={'linear-gradient(#e5b823, #8d7216)'}
                                        color={'white'}
                                        borderRadius={20}>
                                        {t(confirmTxt)}
                                    </Button>
                                </Stack>
                            </AlertDialogFooter>
                        </Center>
                    }
                </AlertDialogContent>
            </AlertDialog>
        )
    }
}

export default CustomDialog;
import React, { useEffect, useState } from "react";
import Epay from "./index";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    return (
        <div>
            <Epay />
            <Footer />
        </div>
    )
}
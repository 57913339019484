import React, { useEffect, useState, createRef } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import {
    Text,
    Image,
    Button,
    Flex,
    Stack,
    Spacer,
    Divider,
    Card,
    CardBody,
    Center,
    HStack,
    SimpleGrid
} from '@chakra-ui/react';
import {
    ApiKey,
    _AMOUNT_TYPE,
    TransactionType,
    TransactionTypeId,
    _DATETIME_FORMAT,
    STATUS_TYPE,
    EWalletType,
    Status,
    _TRANSACTION_DETAILS
} from "../../../common/constant";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, share, checkBrowser } from "../../../common/util";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import GeneralTopBar from "../../../component/general-top-bar";
import moment from "moment";
import { useScreenshot } from "use-react-screenshot";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const userData = useSelector((state) => state.authState.userData);
    const {
        status: passedStatus,
        type,
        transactionId,
        dateTime,
        description,
        receive,
        createDate,
        memberInitialCredit,
        memberFinalCredit,
        memberFinalRewardCredit,
        memberInitialRewardCredit,
        requestCode,
        remarks
    } = _location?.state;
    const [data, setData] = useState([]);
    const [convertData, setConvertData] = useState({
        dateTime: '-',
        amount: '0.00',
        fromName: '-',
        fromUsername: '-',
        fromInitialValue: '0.00',
        fromFinalValue: '0.00',
        fromInitialHeader: 'INITIAL_SCORES',
        fromFinalHeader: 'FINAL_SCORES',
        toName: '0.00',
        toUsername: '0.00',
        toInitialValue: '0.00',
        toFinalValue: '0.00',
        toInitialHeader: 'INITIAL_SCORES',
        toFinalHeader: 'FINAL_SCORES'
    });
    const [status, setStatus] = useState(passedStatus);
    const [allowRefresh, setAllowRefresh] = useState(false);
    const [withdrawData, setWithdrawData] = useState({});
    const [amount, setAmount] = useState(
        _location?.state?.amount ? _location?.state?.amount : 0
    );
    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_location?.state)) {
            _router.back();
        }
        else if ([
            TransactionType._SEND_PAYMENT,
            TransactionType._RECEIVE_PAYMENT,
            TransactionType._REWARD_TO_WALLET,
            TransactionType._SHOP_TOP_UP,
            TransactionType._SHOP_WITHDRAWAL
        ].includes(type)) {
            initTransactionDetail();
        }
        else {
            getTransactionDetails();
            setAllowRefresh(true);
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const getTransactionDetails = async () => {
        try {
            let route = '';

            if (
                type === TransactionType._WITHDRAW_CREDIT ||
                type === TransactionType._WITHDRAW_POINT
            ) {
                route = Routes._ONLINE_WITHDRAWAL_DETAIL.replace(
                    '{id}',
                    transactionId
                );
            } else if (type === TransactionType._MOBILE_PIN_TOPUP) {
                route = Routes._WINPAY_DETAILS + '?id=' + transactionId;
            } else {
                route =
                    type === TransactionType._GPAY88 ||
                        type === TransactionType._EWALLET ||
                        type === TransactionType._MOBILE_PIN_TOPUP
                        ? type === TransactionType._GPAY88
                            ? Routes._GET_CREDIT_TRANSACTION_DETAIL +
                            '?transactionId=' +
                            transactionId
                            : Routes._EWALLET_TRANSACTION_DETAIL +
                            '?id=' +
                            transactionId
                        : Routes._GET_TRANSFER_HISTORY_DETAIL +
                        '?id=' +
                        transactionId;
            }

            let responseJson = await ApiEngine.get(route);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];

                if (
                    type === TransactionType._WITHDRAW_CREDIT ||
                    type === TransactionType._WITHDRAW_POINT
                ) {
                    setWithdrawData(data);
                    setStatus(data?.statusName);
                } else if (
                    ![
                        TransactionType._GPAY88,
                        TransactionType._EWALLET,
                        TransactionType._SHOP_TOP_UP,
                        TransactionType._SHOP_WITHDRAWAL
                    ].includes(type)
                ) {
                    let newData = {
                        ...convertData,
                        amount: numberWithCurrencyFormat(data.amount),
                        dateTime: moment(
                            data?.createdDate ?? data.createdAt
                        ).format(_DATETIME_FORMAT),
                        fromInitialValue: numberWithCurrencyFormat(
                            data.initialPlayerCredit
                        ),
                        fromFinalValue: numberWithCurrencyFormat(
                            data.finalPlayerCredit
                        )
                    };

                    switch (type) {
                        case TransactionType._TRANSFER_SCORE: {
                            newData = {
                                ...newData,
                                fromName: data.vendorName,
                                fromUsername: data.vendorPlayerUsername,
                                toName: data.targetVendorName,
                                toUsername: data.targetVendorPlayerUsername,
                                toInitialValue: numberWithCurrencyFormat(
                                    data.initialTargetPlayerCredit
                                ),
                                toFinalValue: numberWithCurrencyFormat(
                                    data.finalTargetPlayerCredit
                                )
                            };
                            break;
                        }
                        case TransactionType._CONVERT_TO_CREDIT: {
                            newData = {
                                ...newData,
                                fromName: data.vendorName,
                                fromUsername: data.vendorPlayerUsername,
                                toName: 'e-Credit',
                                toUsername: userData.username,
                                toInitialValue: numberWithCurrencyFormat(
                                    data.initialMemberCredit
                                ),
                                toFinalValue: numberWithCurrencyFormat(
                                    data.finalMemberCredit
                                ),
                                toInitialHeader: 'INITIAL_CREDITS',
                                toFinalHeader: 'FINAL_CREDITS'
                            };
                            break;
                        }
                        case TransactionType._CONVERT_TO_SCORE: {
                            newData = {
                                ...newData,
                                fromName: 'e-Credit',
                                fromUsername: userData.username,
                                fromInitialValue: numberWithCurrencyFormat(
                                    data.initialMemberCredit
                                ),
                                fromFinalValue: numberWithCurrencyFormat(
                                    data.finalMemberCredit
                                ),
                                fromInitialHeader: 'INITIAL_CREDITS',
                                fromFinalHeader: 'FINAL_CREDITS',
                                toName: data.vendorName,
                                toUsername: data.vendorPlayerUsername,
                                toInitialValue: numberWithCurrencyFormat(
                                    data.initialPlayerCredit
                                ),
                                toFinalValue: numberWithCurrencyFormat(
                                    data.finalPlayerCredit
                                )
                            };
                            break;
                        }
                        case TransactionType._MOBILE_PIN_TOPUP: {
                            newData = {
                                ...newData,
                                remark: data?.remark
                            };
                            setStatus(
                                data?.status === 0
                                    ? STATUS_TYPE._PENDING_APPROVAL
                                    : data?.status === Status._SUCCESS
                                        ? STATUS_TYPE._SUCCESSFUL
                                        : STATUS_TYPE._FAILED
                            );
                            break;
                        }
                    }
                    setConvertData(newData);
                } else {
                    let newData = [];

                    if (
                        type == TransactionType._GPAY88 ||
                        type == TransactionType._EWALLET
                    ) {
                        newData = [
                            {
                                title: 'DATE_AND_TIME',
                                value:
                                    data.date_time ??
                                    moment(
                                        data.processedDate ?? data.createdDate
                                    ).format(_DATETIME_FORMAT)
                            },
                            { title: 'FROM_PLAYER_ID', value: null },
                            {
                                title: 'PAYMENT_TYPE',
                                value:
                                    data.bank_name ??
                                    (!stringIsNullOrEmpty(data.paymentType)
                                        ? EWalletType.find(
                                            (e) => e.key == data.paymentType
                                        ).title
                                        : data.transactionTypeId ==
                                            TransactionTypeId._TOP_UP_GPAY_DUITNOW
                                            ? 'DuitNow'
                                            : '-')
                            },
                            {
                                title: 'TRANSACTION_ID',
                                value: data.transaction_id ?? data.transactionId
                            }
                        ];
                    } else if (
                        type == TransactionType._SHOP_TOP_UP ||
                        type == TransactionType._SHOP_WITHDRAWAL ||
                        type == TransactionType._MOBILE_PIN_TOPUP
                    ) {
                        newData = [
                            { title: 'DATE_AND_TIME', value: data.createdTime },
                            { title: 'TRANSACTION_ID', value: requestCode },
                            { title: 'REMARK', value: data?.remark ?? '-' }
                        ];
                    }

                    setData(newData);
                    setAmount(data.amount);
                }
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const initTransactionDetail = () => {
        let newData = [];
        let dataObj = {
            ...convertData,
            amount: numberWithCurrencyFormat(amount),
            dateTime: moment(createDate).format('DD MMM YYYY, hh:mm A')
        };

        if (
            type == TransactionType._SEND_PAYMENT ||
            type == TransactionType._RECEIVE_PAYMENT
        ) {
            newData = [
                {
                    title: 'DATE_AND_TIME',
                    value: moment(dateTime).format('DD MMM YYYY, hh:mm A')
                },
                { title: 'DESCRIPTION', value: description }
            ];
        } else if (
            type == TransactionType._SHOP_TOP_UP ||
            type == TransactionType._SHOP_WITHDRAWAL
        ) {
            newData = [
                {
                    title: 'DATE_AND_TIME',
                    value: moment(createDate).format('DD MMM YYYY, hh:mm A')
                },
                { title: 'REMARK', value: remarks ?? '-' },
                { title: 'TRANSACTION_ID', value: requestCode }
            ];
        } else if (type == TransactionType._REWARD_TO_WALLET) {
            dataObj = {
                ...dataObj,
                fromName: t('REWARD_POINT'),
                fromUsername: '',
                fromInitialValue: numberWithCurrencyFormat(
                    memberInitialRewardCredit
                ),
                fromFinalValue: numberWithCurrencyFormat(
                    memberFinalRewardCredit
                ),
                fromInitialHeader: 'INITIAL_REAWRD_POINT',
                fromFinalHeader: 'FINAL_REWARD_POINT',
                toName: 'e-Credit',
                toUsername: userData.username,
                toInitialValue: numberWithCurrencyFormat(memberInitialCredit),
                toFinalValue: numberWithCurrencyFormat(memberFinalCredit),
                toInitialHeader: 'INITIAL_CREDITS',
                toFinalHeader: 'FINAL_CREDITS'
            };
            setConvertData(dataObj);
        }
        setData(newData);
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const amountPrefix = (preAmount = null) => {
        let amt = preAmount !== null ? parseFloat(preAmount) : amount;
        let amountStr = '';

        if (type == TransactionType._SEND_PAYMENT) {
            amountStr = `- RM ${numberWithCurrencyFormat(amt)}`;
        } else {
            if (amt < 0) {
                amountStr = `- RM ${numberWithCurrencyFormat(Math.abs(amt))}`;
            } else {
                amountStr = `+ RM ${numberWithCurrencyFormat(amt)}`;
            }
        }

        return amountStr;
    };

    /// <summary>
    /// Author: CK
    /// </summary>
    const TransactionDetails = () => {
        if (!checkBrowser()) {
            return (
                <>
                    <CardBody>
                        <Stack spacing={5}>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{amountPrefix()}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text color={'app.blue'} fontStyle={'italic'}>{t(_TRANSACTION_DETAILS[type].modeText)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'} color={'app.blue'}>{t(_TRANSACTION_DETAILS[type].requestText)}{receive}</Text>
                                </Stack>
                            </Flex>
                        </Stack>
                    </CardBody>
                    <Divider className="app-divider-full-width-setting" />
                    <CardBody>
                        {data.map(
                            (value, index) =>
                                !stringIsNullOrEmpty(value.value) && (
                                    <Text color={'app.blue'}>{`${t(value.title)} : ${value.value}`}</Text>
                                )
                        )}
                    </CardBody>
                </>
            );
        } else {
            return (
                <>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <Stack spacing={5}>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{amountPrefix()}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text fontStyle={'italic'}>{t(_TRANSACTION_DETAILS[type].modeText)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{t(_TRANSACTION_DETAILS[type].requestText)}{receive}</Text>
                                </Stack>
                            </Flex>
                        </Stack>
                    </CardBody>
                    <CardBody bg={'#182031'} borderRadius={10} mt={5} marginInline={1}>
                        {data.map(
                            (value, index) =>
                                !stringIsNullOrEmpty(value.value) && (
                                    <Text mb={2} color={'app.blue'}>{`${t(value.title)} : ${value.value}`}</Text>
                                )
                        )}
                    </CardBody>
                </>
            );
        }
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const WithdrawDetails = () => {
        if (!checkBrowser()) {
            return (
                <>
                    <CardBody>
                        <Stack spacing={5}>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{`RM ${numberWithCurrencyFormat(Math.abs(withdrawData?.amount))}`}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text color={'app.blue'} fontStyle={'italic'}>{t(_TRANSACTION_DETAILS[type].modeText)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'} color={'app.blue'}>{t(_TRANSACTION_DETAILS[type].requestText)}{receive}</Text>
                                </Stack>
                            </Flex>
                            <Stack spacing={0}>
                                <Text>{t('BANK_NAME')}</Text>
                                <Text fontSize={'md'} fontWeight={'bold'}>{withdrawData?.bankName}</Text>
                            </Stack>
                            <Stack spacing={0}>
                                <Text>{t('BENEFICIARY_NAME')}</Text>
                                <Text fontSize={'md'} fontWeight={'bold'}>{withdrawData?.bankAccountHolderName}</Text>
                            </Stack>
                            <Stack spacing={0}>
                                <Text>{t('ACCOUNT_NUMBER')}</Text>
                                <Text fontSize={'md'} fontWeight={'bold'}>{withdrawData?.bankAccountNumber}</Text>
                            </Stack>
                        </Stack>
                    </CardBody>
                    <Divider className="app-divider-full-width-setting" />
                    <CardBody>
                        <Text color={'app.blue'}>{`${t('MEMBER_ID')} : ${withdrawData?.username}`}</Text>
                        {
                            !stringIsNullOrEmpty(withdrawData?.playerId) &&
                            <Text color={'app.blue'}>{`${t('PLAYER_ID')} : ${withdrawData?.playerUsername} (${withdrawData?.vendorGameName})`}</Text>
                        }
                        {
                            !stringIsNullOrEmpty(withdrawData?.remark) &&
                            <Text color={'app.blue'}>{`${t('REMARK')} : ${withdrawData?.remark}`}</Text>
                        }
                        <Text color={'app.blue'}>{`${t('DATE_AND_TIME')} : ${moment(withdrawData?.createdTime).format('DD MMM YYYY, hh:mm A')}`}</Text>
                    </CardBody>
                </>
            );
        } else {
            return (
                <>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <Stack spacing={5}>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{`RM ${numberWithCurrencyFormat(Math.abs(withdrawData?.amount))}`}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text fontStyle={'italic'}>{t(_TRANSACTION_DETAILS[type].modeText)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{t(_TRANSACTION_DETAILS[type].requestText)}{receive}</Text>
                                </Stack>
                            </Flex>
                        </Stack>
                    </CardBody>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <Stack spacing={0}>
                            <Text>{t('BANK_NAME')}</Text>
                            <Text mb={2} fontSize={'md'} fontWeight={'bold'}>{withdrawData?.bankName}</Text>
                        </Stack>
                        <Stack spacing={0}>
                            <Text>{t('BENEFICIARY_NAME')}</Text>
                            <Text mb={2} fontSize={'md'} fontWeight={'bold'}>{withdrawData?.bankAccountHolderName}</Text>
                        </Stack>
                        <Stack spacing={0}>
                            <Text>{t('ACCOUNT_NUMBER')}</Text>
                            <Text mb={2} fontSize={'md'} fontWeight={'bold'}>{withdrawData?.bankAccountNumber}</Text>
                        </Stack>
                    </CardBody>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <Text mb={2} color={'app.blue'}>{`${t('MEMBER_ID')} : ${withdrawData?.username}`}</Text>
                        {
                            !stringIsNullOrEmpty(withdrawData?.playerId) &&
                            <Text mb={2} color={'app.blue'}>{`${t('PLAYER_ID')} : ${withdrawData?.playerUsername} (${withdrawData?.vendorGameName})`}</Text>
                        }
                        {
                            !stringIsNullOrEmpty(withdrawData?.remark) &&
                            <Text mb={2} color={'app.blue'}>{`${t('REMARK')} : ${withdrawData?.remark}`}</Text>
                        }
                        <Text mb={2} color={'app.blue'}>{`${t('DATE_AND_TIME')} : ${moment(withdrawData?.createdTime).format('DD MMM YYYY, hh:mm A')}`}</Text>
                    </CardBody>
                </>
            );
        }
    };

    /// <summary>
    /// Author: CK
    /// </summary>
    const MobileTopupDetails = () => {
        if (!checkBrowser()) {
            return (
                <>
                    <CardBody>
                        <Stack spacing={5}>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{amountPrefix(convertData?.amount)}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text color={'app.blue'} fontStyle={'italic'}>{t(_TRANSACTION_DETAILS[type].modeText)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'} color={'app.blue'}>{t(_TRANSACTION_DETAILS[type].requestText)}{receive}</Text>
                                </Stack>
                            </Flex>
                        </Stack>
                    </CardBody>
                    <Divider className="app-divider-full-width-setting" />
                    <CardBody>
                        <Text color={'app.blue'}>{`${t('DATE_AND_TIME')} : ${convertData?.dateTime}`}</Text>
                        <Text color={'app.blue'}>{`${t('REMARK')} : ${convertData?.remark}`}</Text>
                    </CardBody>
                </>
            )
        } else {
            return (
                <>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <Stack spacing={5}>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{amountPrefix(convertData?.amount)}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text fontStyle={'italic'}>{t(_TRANSACTION_DETAILS[type].modeText)}</Text>
                                    <Text fontSize={'md'} fontWeight={'bold'}>{t(_TRANSACTION_DETAILS[type].requestText)}{receive}</Text>
                                </Stack>
                            </Flex>
                        </Stack>
                    </CardBody>
                    <CardBody bg={'#182031'} borderRadius={10} mt={5} marginInline={1}>
                        <Text mb={2} color={'app.blue'}>{`${t('DATE_AND_TIME')} : ${convertData?.dateTime}`}</Text>
                        <Text mb={2} color={'app.blue'}>{`${t('REMARK')} : ${convertData?.remark}`}</Text>
                    </CardBody>
                </>
            )
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const ConvertDetails = () => {
        if (!checkBrowser()) {
            return (
                <>
                    <CardBody>
                        <Stack spacing={5}>
                            <Flex alignItems={'center'}>
                                <Stack spacing={0}>
                                    <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                    <Text color={'app.blue'}>{convertData.dateTime}</Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text fontSize={'22px'} fontWeight={'bold'} color={'#fff'}>
                                        {type == TransactionType._REWARD_TO_WALLET
                                            ? `- RM ${numberWithCurrencyFormat(
                                                Math.abs(convertData.amount)
                                            )}`
                                            : `RM ${convertData.amount}`}
                                    </Text>
                                </Stack>
                            </Flex>
                            <Flex alignItems={'center'} backgroundColor={'#C6D1E2'} className="convert-detail">
                                <Stack spacing={0}>
                                    <Text fontSize={'18px'} fontWeight={'bold'} color={'#2A3247'}>
                                        {t('FROM')}: {convertData.fromName}
                                    </Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text fontSize={'18px'} fontWeight={'bold'} color={'#2A3247'}>
                                        {convertData.fromUsername}
                                    </Text>
                                </Stack>
                            </Flex>
                            <Flex alignItems={'center'} className="convert-amount">
                                <Flex m={'auto'}>
                                    <Stack spacing={0}>
                                        <Text color={'app.blue'} fontSize={'14px'} textAlign={'center'}>
                                            {t(convertData.fromInitialHeader)}
                                        </Text>
                                        <Text className="convert-amount-input left">
                                            {convertData.fromInitialValue}
                                        </Text>
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Text color={'app.blue'} fontSize={'14px'} textAlign={'center'}>
                                            {t(convertData.fromFinalHeader)}
                                        </Text>
                                        <Text className="convert-amount-input right">
                                            {convertData.fromFinalValue}
                                        </Text>
                                    </Stack>
                                </Flex>
                            </Flex>
                            <Flex alignItems={'center'} backgroundColor={'#C6D1E2'} className="convert-detail">
                                <Stack spacing={0}>
                                    <Text fontSize={'18px'} fontWeight={'bold'} color={'#2A3247'}>
                                        {t('TO')}: {convertData.toName}
                                    </Text>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text fontSize={'18px'} fontWeight={'bold'} color={'#2A3247'}>
                                        {convertData.toUsername}
                                    </Text>
                                </Stack>
                            </Flex>
                            <Flex alignItems={'center'} className="convert-amount">
                                <Flex m={'auto'}>
                                    <Stack spacing={0}>
                                        <Text color={'app.blue'} fontSize={'14px'} textAlign={'center'}>
                                            {t(convertData.toInitialHeader)}
                                        </Text>
                                        <Text className="convert-amount-input left">
                                            {convertData.toInitialValue}
                                        </Text>
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Text color={'app.blue'} fontSize={'14px'} textAlign={'center'}>
                                            {t(convertData.toFinalHeader)}
                                        </Text>
                                        <Text className="convert-amount-input right">
                                            {convertData.toFinalValue}
                                        </Text>
                                    </Stack>
                                </Flex>
                            </Flex>
                        </Stack>
                    </CardBody>
                    <Divider className="app-divider-full-width-setting" />
                </>
            )
        } else {
            return (
                <>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <Flex alignItems={'center'}>
                            <Stack spacing={0}>
                                <Text>{t(_TRANSACTION_DETAILS[type].bodyTitle)}</Text>
                                <Text color={'app.blue'}>{convertData.dateTime}</Text>
                            </Stack>
                            <Spacer />
                            <Stack spacing={0}>
                                <Text fontSize={'22px'} fontWeight={'bold'} color={'#fff'}>
                                    {type == TransactionType._REWARD_TO_WALLET
                                        ? `- RM ${numberWithCurrencyFormat(
                                            Math.abs(convertData.amount)
                                        )}`
                                        : `RM ${convertData.amount}`}
                                </Text>
                            </Stack>
                        </Flex>
                    </CardBody>
                    <CardBody bg={'#182031'} borderRadius={10} mt={3} marginInline={1}>
                        <HStack mb={3}>
                            <Text>
                                {t('FROM')}: {convertData.fromName}
                            </Text>
                            <Spacer />
                            <Text>
                                {convertData.fromUsername}
                            </Text>
                        </HStack>
                        <SimpleGrid columns={2} rowGap={2} mb={10}>
                            <Text fontSize={'sm'} textAlign={'center'}>
                                {t(convertData.fromInitialHeader)}
                            </Text>
                            <Text fontSize={'sm'} textAlign={'center'}>
                                {t(convertData.fromFinalHeader)}
                            </Text>
                            <Text className="convert-amount-input left" bg={'#2c3956 !important'} border={'1px solid white !important'}>
                                {convertData.fromInitialValue}
                            </Text>
                            <Text className="convert-amount-input right" bg={'#2c3956 !important'} border={'1px solid white !important'}>
                                {convertData.fromFinalValue}
                            </Text>
                        </SimpleGrid>
                        <HStack mb={3}>
                            <Text>
                                {t('TO')}: {convertData.toName}
                            </Text>
                            <Spacer />
                            <Text>
                                {convertData.toUsername}
                            </Text>
                        </HStack>
                        <SimpleGrid columns={2} rowGap={2} mb={2}>
                            <Text fontSize={'sm'} textAlign={'center'}>
                                {t(convertData.toInitialHeader)}
                            </Text>
                            <Text fontSize={'sm'} textAlign={'center'}>
                                {t(convertData.toFinalHeader)}
                            </Text>
                            <Text className="convert-amount-input left" bg={'#2c3956 !important'} border={'1px solid white !important'}>
                                {convertData.toInitialValue}
                            </Text>
                            <Text className="convert-amount-input right" bg={'#2c3956 !important'} border={'1px solid white !important'}>
                                {convertData.toFinalValue}
                            </Text>
                        </SimpleGrid>
                    </CardBody>
                </>
            )
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'TRANSACTION_DETAILS'} rightComponent={
                    <>
                        <Flex>
                            {
                                allowRefresh &&
                                <Button variant='link' onClick={getTransactionDetails}>
                                    <Image
                                        boxSize={'30px'}
                                        src={require('../../../assets/images/refresh.png')} />
                                </Button>
                            }
                            <Button variant='link' onClick={() => {
                                takeScreenShot(ref.current).then((image) => {
                                    share(t("TRANSFER_RECEIPT"), image);
                                })
                            }}>
                                <Image
                                    boxSize={'30px'}
                                    src={require('../../../assets/images/share-btn.png')} />
                            </Button>
                        </Flex>
                    </>
                } />
                <div className="display-center">
                    <Card className="grey-box" mt={10} borderRadius='20px' ref={ref}>
                        <CardBody>
                            <Flex align={'center'}>
                                <Image boxSize={'60px'} src={_TRANSACTION_DETAILS[type].image} />
                                <Text fontSize={'sm'} color={'app.blue'} fontWeight={'bold'}>{t(_TRANSACTION_DETAILS[type].title)}</Text>
                                <Spacer />
                                <span className={`status-tag ${status}`}>
                                    {t(status)}
                                </span>
                            </Flex>
                        </CardBody>
                        <Divider className="app-divider-full-width-setting" />
                        {type == TransactionType._GPAY88 ||
                            type == TransactionType._EWALLET ||
                            type == TransactionType._SHOP_WITHDRAWAL ||
                            type == TransactionType._SHOP_TOP_UP ||
                            type == TransactionType._RECEIVE_PAYMENT ||
                            type == TransactionType._SEND_PAYMENT ? (
                            <TransactionDetails />
                        ) : type === TransactionType._WITHDRAW_CREDIT ||
                            type === TransactionType._WITHDRAW_POINT ? (
                            <WithdrawDetails />
                        ) : type === TransactionType._MOBILE_PIN_TOPUP ? (
                            <MobileTopupDetails />
                        ) : (
                            <ConvertDetails />
                        )}
                    </Card>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "36rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} ml={10} fontWeight={'bold'}>{t('TRANSACTION_DETAILS')}</Text>
                            <Flex float={'right'}>
                                {
                                    allowRefresh &&
                                    <Button variant='link' onClick={getTransactionDetails}>
                                        <Image
                                            boxSize={'30px'}
                                            src={require('../../../assets/images/refresh.png')} />
                                    </Button>
                                }
                                <Button variant='link' onClick={() => {
                                    takeScreenShot(ref.current).then((image) => {
                                        share(t("TRANSFER_RECEIPT"), image);
                                    })
                                }}>
                                    <Image
                                        boxSize={'30px'}
                                        src={require('../../../assets/images/share-btn.png')} />
                                </Button>
                            </Flex>
                        </div>
                        <Card ref={ref}>
                            <CardBody borderRadius={15} bg={'#2c3956'} pt={5}>
                                <HStack>
                                    <Image boxSize={16} src={_TRANSACTION_DETAILS[type].image} />
                                    <Text fontSize={'md'} fontWeight={'bold'}>{t(_TRANSACTION_DETAILS[type].title)}</Text>
                                    <Spacer />
                                    <span style={{ paddingInline: '2rem' }} className={`status-tag ${status}`}>
                                        {t(status)}
                                    </span>
                                </HStack>
                                {type == TransactionType._GPAY88 ||
                                    type == TransactionType._EWALLET ||
                                    type == TransactionType._SHOP_WITHDRAWAL ||
                                    type == TransactionType._SHOP_TOP_UP ||
                                    type == TransactionType._RECEIVE_PAYMENT ||
                                    type == TransactionType._SEND_PAYMENT ? (
                                    <TransactionDetails />
                                ) : type === TransactionType._WITHDRAW_CREDIT ||
                                    type === TransactionType._WITHDRAW_POINT ? (
                                    <WithdrawDetails />
                                ) : type === TransactionType._MOBILE_PIN_TOPUP ? (
                                    <MobileTopupDetails />
                                ) : (
                                    <ConvertDetails />
                                )}
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
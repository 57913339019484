export const _PUSH_NOTIFICATION_CHANNEL_ID = 'betman';
export const _PIN_LENGTH = 6;
export const _IOS_KEYBOARD_HEIGHT = 50;
export const _IOS_KEYBOARD_VERTICAL_OFFSET = 25;
export const _WEB_VERSION = '1.0.0';
export const _VERSION_BUILD_NUMBER = '3';
export const _RECEIPT_WATERMARK = 'Betman';
export const _DEBOUNCE_DURATION_MS = 600;
export const _APP_CLIENT_ID = '099153c2625149bc8ecb3e85e03f0022';
export const _APP_CLIENT_SECRET = 'IxrAjDoa2FqElO7IhrSrUJELhUckePEPVpaePlS_Xaw';
export const _LIVE_CHAT_LICENSE_ID = '13186098';
export const _MEMBER_ROLE = 'Member';
export const _MARQUEE_DURATION = 3000;
export const _MARQUEE_DELAY = 2000;
export const _MARQUEE_SPACER = 50;
export const _MAXIMUM_PLAYERS = 3;
export const _DATEPICKER_FORMAT = 'DD MMM YY';
export const _PAGE_SIZE = 10;
export const _DATETIME_FORMAT = 'DD MMM YYYY, hh:mm A';
export const _FIREBASE_USER_NOT_FOUND = 'auth/user-not-found';
export const _CURRENT_AD_ID = 'AD20220401';
export const _GOOGLE_API_KEY = 'AIzaSyByqTLvbwm-HA8E4bduoGn4vGmwCJ_PJPM';
export const _BRANCH_IO_KEY = "key_live_atli1NWCKKmU9k080NJB1hfbAEee63gP";
export const _BELOW_AMOUNT_TO_DELETE = 1;
export const _PAYMENT_DEVICE_TYPE = 1; // Web
export const _CHECK_IN_COOLDOWN = 10; //minutes
const _FIREBASE_DB = '/betmanDb';

/// <summary>
/// Author: Saitama
/// </summary>
export class SessionKey {
  static _ROUTE_TO_PROCEED = "routeToProceed";
  static _BOTTOM_MENU_ITEM = "bottomMenuItem";
  static _BET_ITEM = "betItem";
}

/// <summary>
/// Author: Saitama
/// </summary>
export class ApiKey {
  static _API_MESSAGE_KEY = 'message';
  static _API_DATA_KEY = 'data';
  static _API_STATUS_KEY = 'status';
  static _API_SUCCESS_KEY = 'success';
  static _API_REQUEST_KEY = 'request';
  static _API_RESPONSE_URL_KEY = 'responseURL';
  static _API_CODE_KEY = 'code';
  static _API_ERROR_KEY = 'error';
  static _API_TOTAL_AMOUNT_KEY = 'totalAmount';
  static _API_COUNT_KEY = 'count';

  static _API_CONTENT_TYPE = 'content-type';
  static _API_ACCEPT = 'Accept';
  static _API_APPLICATION_JSON = 'application/json';
  static _API_XML_HTTP_REQUEST_KEY = 'XMLHttpRequest';
  static _API_FORM_URLENCODED = 'application/x-www-form-urlencoded';
  static _API_POST = 'POST';
  static _API_GET = 'GET';

  static _API_STATUS_OK = 200;
  static _API_APP_VERSION_NOT_COMPATIBLE = 406;
  static _API_INVALID_AUTHORIZATION = 203;
  static _API_STATUS_UNAUTHORIZED = 401;
}

/// <summary>
/// Author: CK
/// </summary>
export class Status {
  static _DISABLED = 0;
  static _ENABLED = 1;
  static _DELETED = 2;
  static _PENDING = 2;
  static _SUCCESS = 1;
}

/// <summary>
/// Author: JavaChips
/// </summary>
export class WithdrawStatus {
  static _PENDING_APPROVAL = 1;
  static _APPROVED = 2;
  static _REJECTED = -1;
}

/// <summary>
/// Author: Juin
/// </summary>
export class ScreenWidth {
  static _BROWSER = 992;
}

/// <summary>
/// Author: CK
/// </summary>
export class PrizeType {
  static _CASH = 'CASH';
  static _REWARD = 'REWARD';
  static _REBATE = 'REBATE';
  static _PRIZE = 'PRIZE';
}

/// <summary>
/// Author: CK
/// </summary>
export class ConvertType {
  static _CREDIT = 'CREDIT';
  static _SCORE = 'SCORE';
}

/// <summary>
/// Author: CK
/// </summary>
export class InputValidationKey {
  static _REQUIRED_KEY = 'required';
  static _MAX_LENGTH_KEY = 'maxLength';
  static _MIN_LENGTH_KEY = 'minLength';
  static _MAX_KEY = 'max';
  static _MIN_KEY = 'min';
  static _PATTERN = 'pattern';
}

/// <summary>
/// Author : CK
/// </summary>
export class Language {
  static _CHINESE = 'zh';
  static _ENGLISH = 'en';
  static _BAHASA_MELAYU = 'ms';
}

/// <summary>
/// Author : CK
/// </summary>
export class ValidationPattern {
  static _EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static _PHONE_NUMBER_REGEX = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
  static _NUMBER_REGEX = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
  static _DECIMAL_REGEX = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
  static _DIGIT_REGEX = /^\d+$/;
  static _ALPHABETIC_REGEX = /^[a-zA-Z ]+$/;
  static _STANDARD_REGEX = /^[A-Za-z0-9!@#$%^&*()_+}{":?><';,.~ /`|\\]*$/;
  static _IC_NUMBER_REGEX =
    /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})$/;
  static _PASSPORT_NUMBER_REGEX = /^[A-Za-z]{1,2}[0-9]{6,9}[A-Za-z]{0,1}$/;
  static _ALPHANUMBERIC_REGEX = /^[0-9a-zA-Z ]+$/;
  static _SPECIAL_CHARACTER_REGEX =
    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
}

/// <summary>
/// Author: CK
/// </summary>
export const _LANGUAGE_OPTION = [
  {
    label: 'English',
    prefix: 'EN',
    value: Language._ENGLISH
  },
  {
    label: '简体中文',
    prefix: '中文',
    value: Language._CHINESE
  },
  {
    label: 'Bahasa Melayu',
    prefix: 'BM',
    value: Language._BAHASA_MELAYU
  }
];

/// <summary>
/// Author : CK
/// </summary>
export class PlatformType {
  static _ANDROID = 'android';
  static _IOS = 'ios';
}

/// <summary>
/// Author : CK
/// </summary>
export const _FirebaseAuth = {
  EMAIL: 'users@epay.com',
  PASSWORD: '123789'
};

/// <summary>
/// Author : Saitama
/// </summary>
export class FirebaseActionType {
  static _PASSWORD_REQUEST = 'Password Request';
  static _PENDING_CONFIRMATION = 'Pending Confirmation';
  static _SETTLED = 'Settled';
  static _PENDING = 'Pending';
}

/// <summary>
/// Author: Saitama
/// </summary>
export class FirebaseKey {
  static _TRANSACTION_REQUEST = _FIREBASE_DB + '/TransactionRequest-';
}

/// <summary>
/// Author : CK
/// </summary>
export class BiometricKey {
  static _IS_AVAILABLE = 'isBiometricAvailable';
  static _IS_KEY_EXIST = 'isBiometricKeyExist';
  static _BIOMETRIC_TYPE = 'biometricType';
}

/// <summary>
/// Author : Saitama
/// </summary>
export class EpayIndexCategory {
  static _MOBILE_RELOAD = 'mobile';
  static _GAME_RELOAD = 'games';
  static _BILL_PAYMENT = 'bills';
  static _EWALLET_RELOAD = 'ewallet';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _WALLET_ACTIONS = {
  SELECT_TOP_UP: 'SELECT_TOP_UP',
  DEPOSIT_GPAY88: 'DEPOSIT_GPAY88',
  DEPOSIT_QR_CODE: 'DEPOSIT_QRCODE',
  DEPOSIT_ATM: 'DEPOSIT_ATM',
  SELECT_WITHDRAW: 'SELECT_WITHDRAW_FROM',
  WITHDRAW_WALLET: 'WITHDRAW_WALLET',
  WITHDRAW_REWARDS: 'WITHDRAW_REWARDS',
  WITHDRAW_GAME_SCORE: 'WITHDRAW_GAME_SCORE',
  SELECT_TRANSFER: 'SELECT_TRANSFER_MODE',
  SEND_CREDIT: 'SEND_CREDIT',
  RECEIVE_CREDIT: 'RECEIVE_CREDIT',
  ONLINE_BANKING: 'ONLINE_BANKING',
  QR_CODE: 'QR_CODE',
  ATM: 'ATM',
  E_CREDIT: 'ECREDIT',
  REWARDS: 'REWARDS',
  E_POINT: 'EPOINT',
  REWARD_TO_WALLET: 'REWARD_TO_WALLET',
  CONVERT: 'CONVERT',
  TRANSACTION: 'TRANSACTION',
  RANKING: 'RANKING',
  EWALLET: 'EWALLET',
  WITHDRAWAL_REQUEST: 'WITHDRAWAL_REQUEST',
  MOBILE_PIN_TOPUP: 'MOBILE_PIN_TOPUP'
};

/// <summary>
/// Author : Saitama
/// </summary>
export class DepositType {
  static _USERNAME = 'WALLET';
  static _PLAYER_ID = 'PLAYER_ID';
}

/// <summary>
/// Author : Saitama
/// </summary>
export const _DEPOSIT_TYPE = [
  {
    type: DepositType._USERNAME
  },
  {
    type: DepositType._PLAYER_ID
  }
];

/// <summary>
/// Author: Saitama
/// </summary>
export class DateFilterKeys {
  static _TODAY = 'TODAY';
  static _YESTERDAY = 'YESTERDAY';
  static _THIS_WEEK = 'THIS_WEEK';
  static _LAST_WEEK = 'LAST_WEEK';
  static _THIS_MONTH = 'THIS_MONTH';
  static _LAST_MONTH = 'LAST_MONTH';
  static _PREV_DAY = 'PREV_DAY';
  static _NEXT_DAY = 'NEXT_DAY';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _DATE_FILTER_OPTIONS = [
  DateFilterKeys._TODAY,
  DateFilterKeys._YESTERDAY,
  DateFilterKeys._THIS_WEEK,
  DateFilterKeys._LAST_WEEK,
  DateFilterKeys._THIS_MONTH,
  DateFilterKeys._LAST_MONTH,
  DateFilterKeys._PREV_DAY,
  DateFilterKeys._NEXT_DAY
];

/// <summary>
/// Author : Saitama
/// </summary>
export const _AMOUNT_TYPE = [
  {
    amount: 20
  },
  {
    amount: 50
  },
  {
    amount: 100
  }
];

/// <summary>
/// Author : Saitama
/// </summary>
export class EGHLStatus {
  static _FAILED = -1;
  static _SUCCESS = 1;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class WithdrawAction {
  static _SUBMIT_REQUEST = 0;
  static _GENERATE_QR = 1;
  static _ONLINE_WITHDRAWAL = 2;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class EGHLCategory {
  static _MOBILE_RELAOD = 1;
  static _GAME_RELOAD = 2;
  static _BILL_PAYMENT = 3;
  static _EWALLET = 4;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class STATUS_TYPE {
  static _SUCCESSFUL = 'SUCCESSFUL';
  static _FAILED = 'FAILED';
  static _PENDING = 'PENDING';
  static _PENDING_APPROVAL = 'PENDING_APPROVAL';
  static _APPROVED = 'APPROVED';
  static _REJECTED = 'REJECTED';
}

/// <summary>
/// Author : Saitama
/// </summary>
export class TRANSACTION_TYPE {
  static _CONVERT_DETAIL = 'Convert Detail';
  static _WALLET_DETAIL = 'Wallet Detail';
  static _WITHDRAW_SCORE = 'Withdraw Score';
  static _QR_TRANSFER = 'Qr Transfer';
}

/// <summary>
/// Author : Saitama
/// </summary>
export class TransactionCategory {
  static _ALL = 0;
  static _REWARD_TO_WALLET = 1;
  static _WALLET_OR_SCORE_CONVERTER = 2;
  static _TRANSFER_SCORE = 3;
  static _GPAY88 = 1;
  static _SHOP_TOP_UP = 2;
  static _SHOP_WITHDRAWAL = 3;
  static _TRANSFER_HISTORY = 4;
  static _EWALLET = 5;
  static _MOBILE_PIN_TOPUP = 13;
}

/// <summary>
/// Author : Saitama
/// </summary>
export const _DEFAULT_AVAILABLE_SPIN = {
  requiredLoginCount: 7,
  accumulatedLoginCount: 2,
  spinCount: 1,
  availableSpin: {
    title: 'Lucky Spin',
    totalSlices: 8,
    slices: [
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      },
      {
        prizeType: PrizeType._REWARD,
        prizeAmount: 0,
        prizeName: 0
      }
    ]
  }
};

/// <summary>
/// Author : Saitama
/// </summary>
export class TransactionHistoryStatus {
  static SUCCESSFUL = 1;
  static FAILED = 2;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class TransactionTypeId {
  static _BONUS_CLAIM = 1;
  static _REWARD_CLAIM = 2;
  static _SET_LIMIT = 3;
  static _SET_SCORE = 4;
  static _WALLET_TRANSFER = 5;
  static _TOP_UP_GPAY = 6;
  static _TOP_UP_EGHL = 7;
  static _EWALLET = 8;
  static _TOP_UP_GPAY_DUITNOW = 9;
  static _MOBILE_PIN_TOPUP = 13;
  static _KINGPAY_DUITNOW = 14;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class TransactionType {
  static _ALL_CONVERT = 'ALL_CONVERT';
  static _ALL_TRANSACTION = 'ALL_TRANSACTION';
  static _DEPOSIT = 'DEPOSIT';
  static _WITHDRAW = 'WITHDRAW';
  static _GPAY88 = 'GPAY88';
  static _SHOP_TOP_UP = 'SHOP_TOP_UP';
  static _SHOP_WITHDRAWAL = 'SHOP_WITHDRAWAL';
  static _TRANSFER_SCORE = 'TRANSFER_SCORE';
  static _CONVERT_TO_SCORE = 'CONVERT_TO_SCORE';
  static _CONVERT_TO_CREDIT = 'CONVERT_TO_CREDIT';
  static _REWARD_TO_WALLET = 'REWARD_TO_WALLET';
  static _TRANSFER_HISTORY = 'TRANSFER_HISTORY';
  static _SEND_PAYMENT = 'SEND_PAYMENT';
  static _RECEIVE_PAYMENT = 'RECEIVE_PAYMENT';
  static _EWALLET = 'EWALLET';
  static _WITHDRAW_CREDIT = 'WITHDRAW_CREDIT';
  static _WITHDRAW_POINT = 'WITHDRAW_POINT';
  static _MOBILE_PIN_TOPUP = 'MOBILE_PIN_TOPUP';
}

/// <summary>
/// Author : Saitama
/// </summary>
export class TransactionRequestStatus {
  static _REQUEST_PENDING = 0;
  static _REQUEST_SUCCESS = 1;
  static _REQUEST_REJECTED = 2;
  static _REQUEST_EXPIRED = 3;
  static _REQUEST_PENDING_PASSWORD_CONFIRMATION = 4;
}

/// <summary>
/// Author: Saitama
/// </summary>
export class KeyboardTypes {
  static _DEFAULT = 'default';
  static _PASSWORD = 'password';
  static _NUMBER_PAD = 'number-pad';
  static _PHONE_PAD = 'phone-pad';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _DEFAULT_VALIDATION_RULES = {
  [KeyboardTypes._DEFAULT]: {
    required: true,
    pattern: {
      value: ValidationPattern._ALPHANUMBERIC_REGEX,
      message: 'Only alphabets and numbers are allowed.'
    }
  },
  [KeyboardTypes._NUMBER_PAD]: {
    required: true,
    min: 10,
    pattern: {
      value: ValidationPattern._DECIMAL_REGEX,
      message: "Only numbers and '.' are allowed."
    }
  },
  [KeyboardTypes._PHONE_PAD]: {
    required: true,
    pattern: {
      value: ValidationPattern._PHONE_NUMBER_REGEX,
      message: 'Invalid phone number format.'
    }
  }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const _TRANSACTION_DETAILS = {
  [TransactionType._GPAY88]: {
    title: 'GPAY88_DEPOSIT',
    bodyTitle: 'YOU_HAVE_DEPOSIT',
    modeText: 'DEPOSIT_MODE',
    requestText: 'ONLINE_BANKING',
    image: require('../assets/images/transaction/wallet-deposit.png')
  },
  [TransactionType._SHOP_TOP_UP]: {
    title: 'SHOP_TOP_UP',
    bodyTitle: 'SHOP_TOP_UP',
    modeText: 'TOP_UP',
    image: require('../assets/images/transaction/wallet-deposit.png')
  },
  [TransactionType._SHOP_WITHDRAWAL]: {
    title: 'SHOP_WITHDRAWAL',
    bodyTitle: 'SHOP_WITHDRAWAL',
    modeText: 'WITHDRAW',
    image: require('../assets/images/transaction/wallet-withdraw-minus.png')
  },
  [TransactionType._TRANSFER_SCORE]: {
    title: 'TRANSFER_SCORE',
    bodyTitle: 'TOTAL_SCORE_TRANSFERRED',
    image: require('../assets/images/transaction/convert-transferscore.png')
  },
  [TransactionType._CONVERT_TO_CREDIT]: {
    title: 'SCORE_TO_WALLET',
    bodyTitle: 'TOTAL_SCORE_CONVERTED',
    image: require('../assets/images/transaction/convert-wallet-score.png')
  },
  [TransactionType._CONVERT_TO_SCORE]: {
    title: 'WALLET_TO_SCORE',
    bodyTitle: 'TOTAL_CREDIT_CONVERTED',
    image: require('../assets/images/transaction/convert-wallet-score.png')
  },
  [TransactionType._REWARD_TO_WALLET]: {
    title: 'REWARD_TO_WALLET',
    bodyTitle: 'TOTAL_REWARD_CONVERTED',
    fromTxt: 'REWARD_POINT',
    toTxt: 'EPAY_WALLET',
    image: require('../assets/images/transaction/convert-rewardtowalletpng.png')
  },
  [TransactionType._SEND_PAYMENT]: {
    title: 'SEND_PAYMENT',
    bodyTitle: 'YOU_HAVE_SEND',
    modeText: 'SENT_TO',
    image: require('../assets/images/transaction/wallet-transfer.png')
  },
  [TransactionType._RECEIVE_PAYMENT]: {
    title: 'RECEIVE_PAYMENT',
    bodyTitle: 'YOU_HAVE_RECEIVED',
    modeText: 'RECEIVED_FROM',
    image: require('../assets/images/transaction/wallet-transfer.png')
  },
  [TransactionType._EWALLET]: {
    title: 'EWALLET_DEPOSIT',
    bodyTitle: 'YOU_HAVE_DEPOSIT',
    modeText: 'DEPOSIT_MODE',
    requestText: 'EWALLET',
    image: require('../assets/images/transaction/wallet-deposit.png')
  },
  [TransactionType._WITHDRAW_CREDIT]: {
    title: 'WITHDRAW_E_CREDIT',
    bodyTitle: 'YOU_REQUEST_WITHDRAW',
    modeText: 'WITHDRAW',
    image: require('../assets/images/transaction/withdraw-wallet.png')
  },
  [TransactionType._WITHDRAW_POINT]: {
    title: 'WITHDRAW_E_POINT',
    bodyTitle: 'YOU_REQUEST_WITHDRAW',
    modeText: 'WITHDRAW',
    image: require('../assets/images/transaction/withdraw-score.png')
  },
  [TransactionType._MOBILE_PIN_TOPUP]: {
    title: 'MOBILE_PIN_TOPUP',
    bodyTitle: 'MOBILE_PIN_TOPUP',
    image: require('../assets/images/transaction/wallet-deposit.png')
  }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const _NOTIFICATION_TYPES = {
  EXP_UP: 1,
  EXP_DOWN: 2,
  LEVEL_UP: 3,
  LEVEL_DOWN: 4
};

/// <summary>
/// Author: Saitama
/// </summary>
export const _CURRENCY_SETTINGS = {
  prefix: ' ',
  delimiter: ',',
  separator: '.',
  precision: 0,
  signPosition: 'beforePrefix',
  maxValue: 1000000
};

/// <summary>
/// Author: Saitama
/// </summary>
export const _EVENT_STATUS = {
  COMING_SOON: 2,
  ON_GOING: 1,
  PAUSE: 0,
  ENDED: 3
};

/// <summary>
/// Author: Saitama
/// </summary>
export const _BADGE_STYLES = {
  GOLD: {
    gradient: {
      colors: ['#986b0d', '#ba8519', '#be8c1f', '#dcb64e', '#ffe17b'],
      locations: [0, 0.1002, 0.4923, 0.8966, 1]
    },
    borderColor: '#ffcc40'
  },
  RED: {
    gradient: {
      colors: ['#980d0d', '#ba1919', '#be1f1f', '#dc4e4e', '#ff7b7b'],
      locations: [0, 0.1084, 0.4923, 0.8923, 1]
    },
    borderColor: '#ff4040'
  },
  GREEN: {
    gradient: {
      locations: [0, 0.1167, 0.8299, 1],
      colors: ['#e6ff8b', '#9ccc49', '#3c9580', '#0d757a']
    },
    borderColor: '#4b9759'
  },
  PURPLE: {
    gradient: {
      locations: [0, 0.1084, 0.8227, 1],
      colors: ['#c184ec', '#a854e3', '#8424d1', '#732bac']
    },
    borderColor: '#ae5ceb'
  }
};

/// <summary>
/// Author : Saitama
/// </summary>
export class MergedTransactionTypeId {
  static _ONLINE_BANKING = 1;
  static _SHOP_SET_SCORE = 2;
  static _SHOP_SET_CREDIT = 3;
  static _SEND_CREDIT = 4;
  static _CONVERT_TO_CREDIT = 5;
  static _CONVERT_TO_SCORE = 6;
  static _TRANSFER_SCORE = 7;
  static _REWARD_TO_CREDIT = 8;
  static _EGHL = 9;
  static _EWALLET = 10;
  static _VENDING_MACHINE = 12;
  static _MOBILE_PIN_TOPUP = 13;
}

/// <summary>
/// Author : Saitama
/// </summary>
export const EWalletType = [
  {
    title: 'Touch N Go',
    key: 101,
    imagePath: require('../assets/images/transaction/ewallet-tng.png')
  },
  {
    title: 'GrabPay',
    key: 102,
    imagePath: require('../assets/images/transaction/ewallet-grabpay.png')
  },
  {
    title: 'ShopeePay',
    key: 103,
    imagePath: require('../assets/images/transaction/ewallet-shopee.png')
  },
  {
    title: 'Boost',
    key: 104,
    imagePath: require('../assets/images/transaction/ewallet-boost.png')
  }
];

/// <summary>
/// Author : Saitama
/// </summary>
export class _LOGIN_METHOD {
  static _VERIFIED = 0;
  static _REQUIRED_TAC = 1;
  static _REQUIRED_SECONDARY = 2;
}

/// <summary>
/// Author: JavaChips
/// </summary>
export class AppPopupTypes {
  static LEVEL_UP_DOWN = 'LEVEL_UP_DOWN';
  static BONUS_EXP = 'BONUS_EXP';
  static ADS = 'ADS';
  static DUIT_NOW = 'DUIT_NOW';
  static BIOMETRIC = 'BIOMETRIC';
}

/// <summary>
/// Author : Saitama
/// </summary>
export class OnlineWithdrawalStatus {
  static _SYSTEM_REJECTED = -2;
  static _REJECTED = -1;
  static _PENDING = 0;
  static _PENDING_APPROVAL = 1;
  static _SUCCESS = 2;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class ProcessFeeType {
  static _PAYMENT_GATEWAY_GPAY = 1;
  static _PAYMENT_GATEWAY_MXPAY = 2;
  static _PAYMENT_GATEWAY_GPAY_DUITNOW = 3;
  static _PAYMENT_GATEWAY_VENDING_MACHINE = 4;
  static _PAYMENT_GATEWAY_WINPAY = 5;
  static _PAYMENT_GATEWAY_KINGPAY_DUITNOW = 6;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class BOTTOM_BAR_TITLE {
  static _GAME = 'GAMES';
  static _TRANSACTION = 'TRANSACTION';
  static _CHAT = 'CHAT';
  static _SPIN = 'SPIN';
  static _EPAY = 'ePay';
}

/// <summary>
/// Author : Saitama
/// </summary>
export const BOTTOM_BAR = [
  {
    title: BOTTOM_BAR_TITLE._GAME,
    icon: require('../assets/images/bottombar/menu-purple-game.png'),
    selectedIcon: require('../assets/images/bottombar/menu-gold-game.png'),
    id: 1
  },
  {
    title: BOTTOM_BAR_TITLE._TRANSACTION,
    icon: require('../assets/images/bottombar/menu-purple-transaction.png'),
    selectedIcon: require('../assets/images/bottombar/menu-gold-transaction.png'),
    id: 2,
    showIndicator: true
  },
  {
    title: BOTTOM_BAR_TITLE._CHAT,
    icon: require('../assets/images/bottombar/menu-purple-chat.png'),
    selectedIcon: require('../assets/images/bottombar/menu-gold-chat.png'),
    id: 3
  }
  ,
  {
    title: BOTTOM_BAR_TITLE._SPIN,
    icon: require('../assets/images/bottombar/menu-purple-spin.png'),
    selectedIcon: require('../assets/images/bottombar/menu-gold-spin.png'),
    id: 4
  }
  ,
  {
    title: BOTTOM_BAR_TITLE._EPAY,
    icon: require('../assets/images/bottombar/menu-purple-epay.png'),
    selectedIcon: require('../assets/images/bottombar/menu-gold-epay.png'),
    id: 5
  }
];

/// <summary>
/// Author : Saitama
/// </summary>
export class BG_TYPE {
  static black = 0;
  static blur = 1;
  static clear = 2;
  static animated = 3; 
  static swc = 4; 
  static lightGrey = 5; 
}

/// <summary>
/// Author : Saitama
/// </summary>
export class ResultImageType {
  static _CONVERT = 1;
  static _TRANSFER = 2;
  static _SEND = 3; 
}

/// <summary>
/// Author : Saitama
/// </summary>
export class PoolColor {
  static magnumBg = "#ffbb1b";
  static damacaiBg = '#1B377C';
  static totoBg = "#e91c0d";
  static singaporeBg = "#3f8fd8";
  static sabahBg = "#fb0205";
  static sandakanBg = "#007e33";
  static cashSweepBg = '#00631d';
  static singaBg = "#e6c463";
  static grandDragonBg = "#e91824";
  static nineLottoBg = "#e56028"
}

/// <summary>
/// Author : Saitama
/// </summary>
export class OrderMode {
  static _ORDER_MODE_2D = "2D";
  static _ORDER_MODE_3D = "3D";
  static _ORDER_MODE_4D = "4D";
  static _ORDER_MODE_5D = "5D";
  static _ORDER_MODE_6D = "6D";
}

// Pool Name
export const MAGNUM_POOL_NAME = "Magnum";
export const DAMACAI_POOL_NAME = "Damacai";
export const TOTO_POOL_NAME = "Toto";
export const SINGAPORE_POOL_NAME = "Singapore";
export const SABAH_POOL_NAME = "Sabah";
export const SANDAKAN_POOL_NAME = "STC";
export const CASH_SWEEP_POOL_NAME = "Cashsweep";
export const GRAND_DRAGON_POOL_NAME = "Grand Dragon";
export const NINE_LOTTO_POOL_NAME = "Nine Lotto";

// Pool Short Code
export const MAGNUM_POOL_SHORT_CODE = "M";
export const DAMACAI_POOL_SHORT_CODE = "K";
export const TOTO_POOL_SHORT_CODE = "T";
export const SINGAPORE_POOL_SHORT_CODE = "S";
export const SABAH_POOL_SHORT_CODE = "B";
export const SANDAKAN_POOL_SHORT_CODE = "D";
export const CASH_SWEEP_POOL_SHORT_CODE = "W";
export const GRAND_DRAGON_SHORT_CODE = "H";
export const NINE_LOTTO_SHORT_CODE = "E";

// Pool Result Key
export const MAGNUM_POOL_RESULT_KEY = "mg";
export const DAMACAI_POOL_RESULT_KEY = "kd";
export const TOTO_POOL_RESULT_KEY = "tt";
export const SINGAPORE_POOL_RESULT_KEY = "sg";
export const SABAH_POOL_RESULT_KEY = "sb";
export const SANDAKAN_POOL_RESULT_KEY = "sn";
export const CASH_SWEEP_POOL_RESULT_KEY = "sr";
export const GRAND_DRAGON_POOL_RESULT_KEY = "gd";
export const NINE_LOTTO_POOL_RESULT_KEY = "nl";

// Pools
export const MAGNUM_POOL_VALUE = 1;
export const DAMACAI_POOL_VALUE = 2;
export const TOTO_POOL_VALUE = 3;
export const SINGAPORE_POOL_VALUE = 4;
export const SABAH_POOL_VALUE = 5;
export const SANDAKAN_POOL_VALUE = 6;
export const CASH_SWEEP_POOL_VALUE = 7;
export const GRAND_DRAGON_POOL_VALUE = 8;
export const NINE_LOTTO_POOL_VALUE = 9;

export const AMOUNT_TYPE_BIG = "B";
export const AMOUNT_TYPE_SMALL = "S";
export const AMOUNT_TYPE_2A = "2A";
export const AMOUNT_TYPE_2B = "2B";
export const AMOUNT_TYPE_2C = "2C";
export const AMOUNT_TYPE_2SB = "2SB";
export const AMOUNT_TYPE_2SC = "2SC";
export const AMOUNT_TYPE_2SD = "2SD";
export const AMOUNT_TYPE_2SE = "2SE";
export const AMOUNT_TYPE_3A = "3A";
export const AMOUNT_TYPE_3B = "3B";
export const AMOUNT_TYPE_3C = "3C";
export const AMOUNT_TYPE_3SB = "3SB";
export const AMOUNT_TYPE_3SC = "3SC";
export const AMOUNT_TYPE_3SD = "3SD";
export const AMOUNT_TYPE_3SE = "3SE";
export const AMOUNT_TYPE_4A = "4A";
export const AMOUNT_TYPE_4SB = "4SB";
export const AMOUNT_TYPE_4SC = "4SC";
export const AMOUNT_TYPE_4SD = "4SD";
export const AMOUNT_TYPE_4SE = "4SE";
export const AMOUNT_TYPE_4C = "4C";
export const AMOUNT_TYPE_5D = "5D";
export const AMOUNT_TYPE_6D = "6D";

export const BOX_ORDER_TYPE = "B";
export const IBOX_ORDER_TYPE = "I";
export const PH_ORDER_TYPE = "PH";
export const PT_ORDER_TYPE = "PT";

/// <summary>
/// Author : Saitama
/// </summary>
export const _POOL_DETAILS = [
  {
      id: MAGNUM_POOL_VALUE,
      name: MAGNUM_POOL_NAME,
      shortCode: MAGNUM_POOL_SHORT_CODE
  },
  {
      id: DAMACAI_POOL_VALUE,
      name: DAMACAI_POOL_NAME,
      shortCode: DAMACAI_POOL_SHORT_CODE
  },
  {
      id: TOTO_POOL_VALUE,
      name: TOTO_POOL_NAME,
      shortCode: TOTO_POOL_SHORT_CODE
  },
  {
      id: SINGAPORE_POOL_VALUE,
      name: SINGAPORE_POOL_NAME,
      shortCode: SINGAPORE_POOL_SHORT_CODE
  },
  {
      id: SABAH_POOL_VALUE,
      name: SABAH_POOL_NAME,
      shortCode: SABAH_POOL_SHORT_CODE
  },
  {
      id: SANDAKAN_POOL_VALUE,
      name: SANDAKAN_POOL_NAME,
      shortCode: SANDAKAN_POOL_SHORT_CODE
  },
  {
      id: CASH_SWEEP_POOL_VALUE,
      name: CASH_SWEEP_POOL_NAME,
      shortCode: CASH_SWEEP_POOL_SHORT_CODE
  },
  {
      id: GRAND_DRAGON_POOL_VALUE,
      name: GRAND_DRAGON_POOL_NAME,
      shortCode: GRAND_DRAGON_SHORT_CODE
  },
  {
      id: NINE_LOTTO_POOL_VALUE,
      name: NINE_LOTTO_POOL_NAME,
      shortCode: NINE_LOTTO_SHORT_CODE
  },
];

export const ACTIVE_STATUS = 0;
export const CANCELLED_STATUS = 1;
export const ACTIVE_STATUS_TXT = "Active";
export const CANCELLED_STATUS_TXT = "Canceled";

/// <summary>
/// Author : Saitama
/// </summary>
export class _PROHIBITED_STATE_RANGE {
  static minLat = 1.157115;
  static maxLat = 7.544317;
  static minLng = 109.564849;
  static maxLng = 119.484607;
}

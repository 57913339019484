import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Center } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ResultImageType } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {

    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        _dispatch(showCustomDialog({
            success: true,
            title: "CONFIRMATION",
            content: "CONVERT_REWARD_TO_CREDIT_CONFIRMATION",
            onConfirm: () => {
                convertToWallet(data.amount);
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('amount')));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function convertToWallet(amount) {
        try {
            let params = {
                "RewardClaimed": parseFloat(amount)
            }

            let responseJson = await ApiEngine.post(Routes._CLAIM_REWARD_TO_CREDIT, params, {
                ...ApplicationJsonHeaderConfig
            });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(getMemberDetails(true));

                _router.navigate(Pages._CONVERT_SUCCESS, {
                    headerTitle: 'SUCCESSFULLY_CONVERTED',
                    contentImageType: ResultImageType._CONVERT,
                    contentTitle: 'YOU_VE_CONVERTED',
                    amount: '- RM ' + numberWithCurrencyFormat(parseFloat(amount)),
                    details: [
                        { label: 'CONVERT_TO_USERNAME', value: userData?.username },
                        { label: 'DATE_AND_TIME', value: moment().format('DD MMM YYYY, hh:mm A') }
                    ]
                }, true);
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY]
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div className='body-content display-center'>
                <GeneralTopBar title={'REWARD_TO_WALLET'}>
                    <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                </GeneralTopBar>
                <Card className="player-detail-box header-margin-top" borderRadius='20px' width={'100%'}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <CardBody>
                            <Text color={'app.blue'} fontWeight={800} fontSize={'md'} mb={-2}>{t('REWARD_POINTS')}</Text>
                            <Text as='i'>{`${t('CONVERT_TO_USERNAME')} : ${userData.username}`}</Text>
                            <Box w='full' borderRadius={20} borderColor='#C6D1E2' borderWidth={1} mt={5}>
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={0}
                                    render={({ onChange, value, name }) => (
                                        <Stack direction='row' paddingInline={3}>
                                            <Image
                                                boxSize={'35px'}
                                                src={require('../../../assets/images/topbar-icon-gift.png')} />
                                            <CurrencyInput
                                                style={{
                                                    textAlign: 'end',
                                                    backgroundColor: 'transparent',
                                                    color: 'white',
                                                    fontWeight: '500',
                                                    fontSize: '1.35rem',
                                                    width: '70%',
                                                    outlineWidth: 0
                                                }}
                                                id={name}
                                                name={name}
                                                value={value}
                                                placeholder="0.00"
                                                decimalScale={2}
                                                autoComplete={false}
                                                onFocus={(e) => e.target.select()}
                                                onValueChange={(value, name) => {
                                                    onChange(value);
                                                }}
                                            />
                                            <Divider orientation='vertical' h='35px' />
                                            <Button variant='link'
                                                onClick={() => {
                                                    setValue('amount', userData.reward);
                                                }}>
                                                <Text fontSize={'md'}>{t('MAX')}</Text>
                                            </Button>
                                        </Stack>
                                    )}
                                    rules={{
                                        required: true,
                                        min: 5,
                                        max: userData.reward
                                    }}
                                />
                            </Box>
                            {
                                errors && errors['amount'] &&
                                <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                            }
                            <Stack mt={3} spacing={0}>
                                <Text as='i' color='app.blue'>{'* ' + t("MIN_AMOUNT_RM") + '5'}</Text>
                                <Text as='i' color='app.blue'>{t("REWARDS_POINTS_WILL_BE_TRANSFERRED_TO_WALLET")}</Text>
                            </Stack>
                        </CardBody>
                        <Button
                            className="grey-button"
                            style={{ borderRadius: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                            type="submit">
                            {t('CONVERT_POINTS')}
                        </Button>
                    </form>
                </Card>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('REWARD_TO_WALLET')}</Text>
                        </div>
                        <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                        <Card bg={'#2c3956'} borderRadius='20px' mt={6} width={['75%', '100%']}>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <CardBody>
                                    <Text color={'app.blue'} fontWeight={700} fontSize={'md'} mb={-1}>{t('REWARD_POINTS')}</Text>
                                    <Text as='i'>{`${t('CONVERT_TO_USERNAME')} : ${userData.username}`}</Text>
                                    <Box w='full'paddingBlock={2} borderRadius={10} borderColor={'transparent'} transitionDuration={'normal'} bg={'#161f30'} borderWidth={2} mt={5} _hover={{ border: "2px solid white" }}>
                                        <Controller
                                            control={control}
                                            name="amount"
                                            defaultValue={0}
                                            render={({ onChange, value, name }) => (
                                                <Stack direction='row' paddingInline={3}>
                                                    <Image
                                                        boxSize={'35px'}
                                                        src={require('../../../assets/images/topbar-icon-gift.png')} />
                                                    <CurrencyInput
                                                        style={{
                                                            textAlign: 'end',
                                                            backgroundColor: 'transparent',
                                                            color: 'white',
                                                            fontWeight: '500',
                                                            fontSize: '1.1rem',
                                                            width: '70%',
                                                            outlineWidth: 0
                                                        }}
                                                        id={name}
                                                        name={name}
                                                        value={value}
                                                        placeholder="0.00"
                                                        decimalScale={2}
                                                        autoComplete={false}
                                                        onFocus={(e) => e.target.select()}
                                                        onValueChange={(value, name) => {
                                                            onChange(value);
                                                        }}
                                                    />
                                                    <Divider orientation='vertical' h='35px' />
                                                    <Button variant='link' minW={16}
                                                        onClick={() => {
                                                            setValue('amount', userData.reward);
                                                        }}>
                                                        <Text fontSize={'md'}>{t('MAX')}</Text>
                                                    </Button>
                                                </Stack>
                                            )}
                                            rules={{
                                                required: true,
                                                min: 5,
                                                max: userData.reward
                                            }}
                                        />
                                    </Box>
                                    {
                                        errors && errors['amount'] &&
                                        <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                                    }
                                    <Stack mt={3} spacing={0} mb={10}>
                                        <Text fontSize={'sm'} as='i' color='app.blue'>{'* ' + t("MIN_AMOUNT_RM") + '5'}</Text>
                                        <Text fontSize={'sm'} as='i' color='app.blue'>{t("REWARDS_POINTS_WILL_BE_TRANSFERRED_TO_WALLET")}</Text>
                                    </Stack>
                                    <Button
                                        style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#161f30", color: "white", padding: "25px 0px" }} w='100%'
                                        type="submit">
                                        {t('CONVERT_POINTS')}
                                    </Button>
                                </CardBody>
                            </form>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { Text, Flex, Button, Image, Stack, Spinner, Divider, Box, AbsoluteCenter, Input, InputGroup, InputRightElement, Center } from "@chakra-ui/react";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { showCustomDialog } from "../../../application/action/app_action";
import { stringIsNullOrEmpty, share, checkBrowser } from "../../../common/util";
import { ApiKey, _PAGE_SIZE, ScreenWidth } from "../../../common/constant";
import moment from "moment";
import GeneralTopBar from "../../../component/general-top-bar";
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from "react-device-detect";
import Header from "../../non-auth-pages/header";
import { useNavigate } from "react-router";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [chatData, setChatData] = useState([]);
    const [chatDataTotalCount, setChatDataTotalCount] = useState(0);
    const [userMsg, setUserMsg] = useState('');
    const userData = useSelector((state) => state.authState.userData);
    const scrollRef = useRef();

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        loadMessages();
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (chatData.length > 0 && isFirstLoad) {
            setIsFirstLoad(false);
            scrollToBottom();
        }
    }, [chatData, isFirstLoad]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const loadMessages = async () => {
        setIsRefreshing(true);
        try {
            let currentMessages = [...chatData];

            // Handle pagination
            var page = 1;

            if (currentMessages.length < chatDataTotalCount) {
                page = Math.ceil(currentMessages.length / _PAGE_SIZE) + 1;
            }
            else {
                currentMessages = [];
            }

            let responseJson = await ApiEngine.get(`${Routes._GET_MESSAGES}?memguid=${userData.guid}&page=${page}&pageSize=${_PAGE_SIZE}`, { headers: { "skipLoading": true } });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = [...currentMessages, ...responseJson[ApiKey._API_DATA_KEY]].sort((a, b) => b.creation_date.localeCompare(a.creation_date))
                setChatData(data);
                setChatDataTotalCount(responseJson[ApiKey._API_COUNT_KEY]);
            } else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }))
        }
        finally {
            setIsRefreshing(false);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const sendMessage = () => {
        if (userMsg.length > 0) {
            setChatData([{ id: moment().toString(), message: userMsg }, ...chatData]);
            setUserMsg("");
            scrollToBottom();
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const MessageDialog = ({
        value,
        index,
        arr
    }) => {
        /// <summary>
        /// Author: Saitama
        /// </summary>
        const renderMsgDatetime = (value, index, arr) => {
            let dateTimeComp = <></>;
            let today = moment().format('YYYY-MM-DD');
            let messageDateTime = value.creation_date ?? moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
            let momentDateTime = moment(messageDateTime, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');

            let datetime;
            var date1 = moment(momentDateTime);
            var date2 = moment(today);

            if (index === 0) {
                if (date1.diff(today, 'days') == 0) {
                    datetime = t('TODAY');
                }
                else if (date1.diff(date2, 'days') == 1) {
                    datetime = t('YESTERDAY');
                }
                else {
                    datetime = moment(momentDateTime).format('ddd, D MMM YYYY');
                }
            }
            else {
                var prevMomentDateTime = moment(arr[index - 1].creation_date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');

                // If current date and previous date is different
                if (momentDateTime !== prevMomentDateTime) {
                    if (date1.diff(today, 'days') == 0) {
                        datetime = t('TODAY');
                    }
                    else if (date1.diff(date2, 'days') == 1) {
                        datetime = t('YESTERDAY');
                    }
                    else {
                        datetime = moment(momentDateTime).format('ddd, D MMM YYYY');
                    }
                }
            }

            if (datetime) {
                dateTimeComp = (
                    <Text color={'#AACBFF'} textAlign={'center'} mb={5}>
                        {datetime}
                    </Text>
                );
            }

            return dateTimeComp;
        };

        return (
            <>
                <Flex mb={5} justify={stringIsNullOrEmpty(value.member_id) && 'flex-end'}>
                    {
                        !stringIsNullOrEmpty(value.member_id) && (
                            <Image boxSize={'40px'} src={require('../../../assets/images/chat/chat-robot.png')} />
                        )
                    }
                    <Stack ml={3} backgroundColor={'#232E46'} p={2.5} borderRadius={10}>
                        <Text maxW={'30vw'} whiteSpace={'break-spaces'}>
                            {value.message}
                        </Text>
                        <Text mp={2} paddingHorizontal={2.5} textAlign={'right'} fontSize={'sm'}>
                            {moment(value.creation_date).format("h:mm A")}
                        </Text>
                    </Stack>
                </Flex>
                {
                    !stringIsNullOrEmpty(value.member_id) &&
                    renderMsgDatetime(value, index, arr)
                }
            </>

        )
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={t("ANNOUNCEMENT")} />
                <div
                    id="scrollableDiv"
                    ref={scrollRef}
                    style={{
                        height: '100vh',
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column-reverse",
                        paddingBottom: '7rem',
                        marginTop: '1rem'
                    }}>
                    <InfiniteScroll
                        scrollableTarget="scrollableDiv"
                        inverse={true}
                        style={{ display: "flex", flexDirection: "column-reverse" }}
                        dataLength={chatData.length}
                        next={loadMessages}
                        hasMore={!isRefreshing && !isFirstLoad && chatData.length < chatDataTotalCount}
                        loader={<Spinner color="white" />}
                    >
                        {
                            chatData.map((item, index) => {
                                return (
                                    <MessageDialog
                                        value={item}
                                        index={index}
                                        arr={chatData}
                                    />
                                )
                            })
                        }
                    </InfiniteScroll>
                </div>
                <Stack className="bottom-sticky-button">
                    <InputGroup>
                        <Input
                            value={userMsg}
                            onInput={(e) => setUserMsg(e.target.value)}
                            placeholder={`${t("WRITE_A_MESSAGE")}...`}
                            textColor='app.blue'
                            className="input-container" />
                        <InputRightElement width='3.5rem'>
                            <Button h='1.75rem' size='xs' variant='link' onClick={() => { sendMessage() }}>
                                <Image boxSize={'25px'} src={require('../../../assets/images/chat-send.png')} />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </Stack>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'}>{t("ANNOUNCEMENT")}</Text>
                        </div>

                        <div
                            // id="scrollableDiv"
                            // ref={scrollRef}
                            style={{
                                height: '28rem',
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column-reverse",
                                paddingBottom: '7rem',
                                minWidth: '35rem',
                                margin: 'auto 25px 2rem 25px'
                            }}>
                            <InfiniteScroll
                                scrollableTarget="scrollableDiv"
                                inverse={true}
                                style={{ display: "flex", flexDirection: "column-reverse" }}
                                dataLength={chatData.length}
                                next={loadMessages}
                                hasMore={!isRefreshing && !isFirstLoad && chatData.length < chatDataTotalCount}
                                loader={<Spinner color="white" />}
                            >
                                {
                                    chatData.map((item, index) => {
                                        return (
                                            <MessageDialog
                                                value={item}
                                                index={index}
                                                arr={chatData}
                                            />
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>
                        <Stack bg={'transparent'}>
                            <InputGroup>
                                <Input
                                    borderRadius={24}
                                    bg={'#303e5d'}
                                    borderWidth={2}
                                    borderColor={'transparent'}
                                    transitionDuration={'normal'}
                                    paddingInline={6}
                                    paddingBlock={6}
                                    _placeholder={{ color: 'white' }}
                                    _hover={{border:'2px solid white'}}
                                    value={userMsg}
                                    onInput={(e) => setUserMsg(e.target.value)}
                                    placeholder={`${t("WRITE_A_MESSAGE")}...`}
                                    textColor='app.blue' />
                                <InputRightElement width='3.5rem' paddingBlock={7}>
                                    <Button h='4rem' size='xs' variant='link' onClick={() => { sendMessage() }}>
                                        <Image boxSize={'28px'} src={require('../../../assets/images/chat-send.png')} />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Stack, Button, Box, Flex, AbsoluteCenter, Image, Spinner, Spacer, Tabs, TabList, Tab, Divider } from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { Pages } from "../../../../routes/page";
import { showCustomDialog } from "../../../../application/action/app_action";
import { isObjectEmpty, numberWithCurrencyFormat, share, stringIsNullOrEmpty, isTicketCancelled } from "../../../../common/util";
import { ApiKey, BG_TYPE, ACTIVE_STATUS, CANCELLED_STATUS, _PAGE_SIZE } from "../../../../common/constant";
import CustomPicker from "../../../../component/custom-picker";
import moment from "moment";
import DatePicker from 'react-date-picker';
import InfiniteScroll from 'react-infinite-scroll-component';

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PLAYER_ID = _location?.state?.playerId;
    const _STATUS = _location?.state?.status;
    const [selectedFromDate, setSelectedFromDate] = useState(moment().startOf('months'));
    const [selectedToDate, setSelectedToDate] = useState(moment());
    const _TAB_OPTION = [
        { key: ACTIVE_STATUS, title: 'SUCCESS' },
        { key: CANCELLED_STATUS, title: 'Canceled' }
    ];
    const [selectedTab, setSelectedTab] = useState(_STATUS ? _STATUS : _TAB_OPTION[0].key);
    const [history, setHistory] = useState([]);
    const _INITIAL_PAGE_NO = 1;
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [totalCount, setTotalCount] = useState(0);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PLAYER_ID)) {
            _router.back();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getOrderHistory()
    }, [selectedTab]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getOrderHistory(
        startedDate = selectedFromDate,
        endedDate = selectedToDate,
        filterData = selectedTab,
        pagedNo = _INITIAL_PAGE_NO
    ) {
        try {
            var params = JSON.stringify({
                "PlayerId": _PLAYER_ID,
                "RecordId": "",
                "Status": filterData,
                "DrawDateFrom": moment(startedDate).format('YYYYMMDD'),
                "DrawDateTo": moment(endedDate).format('YYYYMMDD'),
                "PageSize": _PAGE_SIZE,
                "Page": pagedNo
            });

            var response = await ApiEngine.post(Routes._SWC_CHECK_RECEIPTS, params, {
                headers: {
                    "content-type": ApiKey._API_APPLICATION_JSON
                }
            });

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw response.Message ?? response.message ?? response.error ?? response.title;
            }

            setTotalCount(response['count']);

            if (
                response[ApiKey._API_DATA_KEY] &&
                response[ApiKey._API_DATA_KEY].length > 0 &&
                pagedNo > _INITIAL_PAGE_NO
            ) {
                setHistory([
                    ...history,
                    ...response[ApiKey._API_DATA_KEY]
                ]);
            } else {
                setHistory(response[ApiKey._API_DATA_KEY]);
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const RenderItem = ({ item, index }) => {
        return (
            <Button
                key={index}
                variant={'link'}
                mt={3}
                borderRadius={15}
                onClick={() => { _router.navigate(Pages.SWC_ORDER_HISTORY_DETAILS, { orderDetail: item, playerId: _PLAYER_ID }, true); }}>
                <Flex p={1.5} bg={'white'} borderRadius={15} align={'center'}>
                    <img style={{ width: '5%', height: '5%' }} src={require('./assets/images/bet-blue.svg').default} />
                    <Stack ml={1.5} spacing={1}>
                        <Text color={'#54595F'} textAlign={'left'} fontWeight={'bold'} fontSize={'sm'}>{`${t('ORDER_ID')}: ${item.raw_input_id}`}</Text>
                        <Text color={'#54595F'} textAlign={'left'}>{moment(item.created_datetime, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm A')}</Text>
                    </Stack>
                    <Spacer />
                    <Stack spacing={1}>
                        <Text color={isTicketCancelled(item.status) ? 'red' : '#08CD34'}>{item.status}</Text>
                        <Text color={isTicketCancelled(item.status) ? '#B0B0B1' : '#08CD34'} fontWeight={'bold'} fontSize={'sm'}>
                            -RM {isTicketCancelled(item.status) ? "0.00" : numberWithCurrencyFormat(item.ticketAmount)}
                        </Text>
                    </Stack>
                    <Image boxSize={'20px'} ml={1} src={require('./assets/images/caret.png')} />
                </Flex>
            </Button>
        )
    }

    return (
        <div className='body-content'>
            <GeneralTopBar title={'ORDER_HISTORY'} bgColor="#ebffe3" blackTitle titleAlignLeft useSimpleBackIcon />
            <Box marginInline={'-3.75vw'} bg={'white'} p={1} textAlign={'center'}>
                <Text color={'black'} fontWeight={'bold'}>{t('DRAW_DATE')}</Text>
                <Flex align={'flex-end'}>
                    <Text color={'#989898'} fontWeight={'500'} mr={2}>{t('FROM')}</Text>
                    <DatePicker
                        className={'result-date-picker'}
                        onChange={setSelectedFromDate}
                        value={selectedFromDate}
                        clearIcon={null}
                    />
                    <Spacer />
                    <Text color={'#989898'} fontWeight={'500'} mr={2}>{t('TO')}</Text>
                    <DatePicker
                        className={'result-date-picker'}
                        onChange={setSelectedToDate}
                        value={selectedToDate}
                        clearIcon={null}
                    />
                    <Spacer />
                    <Button
                        size={'sm'}
                        variant='solid'
                        bg='#08CD34'
                        borderRadius={10}
                        color={'white'}
                        onClick={() => { getOrderHistory(selectedFromDate, selectedToDate); }}>
                        {t('APPLY')}
                    </Button>
                </Flex>
                <Tabs isFitted size='sm' variant='enclosed' mt={3} defaultIndex={selectedTab}>
                    <TabList>
                        {
                            _TAB_OPTION.map((item) => {
                                return (
                                    <Tab onClick={() => { setSelectedTab(item.key); }} color={'#08CD34'} _selected={{ color: 'white', bg: '#08CD34' }}>{t(item.title)}</Tab>
                                )
                            })
                        }
                    </TabList>
                </Tabs>
            </Box>
            {
                history.length > 0 ?
                    <div
                        id="scrollableDiv"
                        style={{
                            height: '80vh',
                            overflow: "auto"
                        }}>
                        <InfiniteScroll
                            style={{ display: "flex", flexDirection: "column" }}
                            dataLength={history.length}
                            next={() => {
                                let page = pageNo + 1;
                                setPageNo(page);
                                getOrderHistory(selectedFromDate, selectedToDate, selectedTab, page);
                            }}
                            hasMore={pageNo < totalCount}
                            loader={<Spinner color="white" />}
                        >
                            {
                                history.map((item, index) => {
                                    return (
                                        <RenderItem
                                            item={item}
                                            index={index}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </div> :
                    <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                        <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'black'}>{t("NO_RECORD_FOUND")}</Text>
                    </AbsoluteCenter>
            }
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Button,
    AbsoluteCenter,
    Center,
    Box,
    Flex,
    Divider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Spinner,
    Spacer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    Card,
    CardBody
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import Routes from "../../../common/api_routes";
import { ApiKey, Status } from "../../../common/constant";
import ApiEngine from "../../../common/api_engine";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat } from "../../../common/util";
import DateFilter from "../../../component/date-filter";
import moment from "moment";
import Marquee from "react-fast-marquee";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _userData = useSelector((state) => state.authState.userData);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isRefreshingReport, setIsRefreshingReport] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
    const [reportData, setReportData] = useState([]);
    const [overallReportData, setOverallReportData] = useState({});
    const [useSimpleReport, setUseSimpleReport] = useState(true);
    const [targetRefArray, setTargetRefArray] = useState([{ targetRefId: _userData.guid, username: _userData.username }]);
    const [expandIndex, setExpandIndex] = useState([]);
    const _FULL_REPORT_HEADER = [
        { title: 'USER', bgColor: '#364158' },
        { title: 'GAME_WINLOSS', bgColor: '#A7B7D06C' },
        { title: 'GAME_COMM', bgColor: '#A7B7D06C' },
        { title: 'DOWNLINE_GAME_WINLOSS', bgColor: '#65799D' },
        { title: 'DOWNLINE_GAME_COMMISSION', bgColor: '#65799D' },
    ]

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        getCommReport(startDate, endDate);

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, [targetRefArray])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onBackButtonEvent = (e) => {
        e.preventDefault();

        if (targetRefArray.length > 1) {
            setTargetRefArray(targetRefArray.splice(0, targetRefArray.length - 1));
        }
        else {
            _router.back();
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getCommReport(start, end) {
        setIsRefreshingReport(true);
        var responseJson = await ApiEngine.get(`${Routes._DOWNLINE_WINLOSS_REPORT}?startDate=${start}&endDate=${end}&targetRefId=${targetRefArray[targetRefArray.length - 1].targetRefId}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setReportData(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
            setOverallReportData({
                gameTotal: parseFloat(responseJson[ApiKey._API_DATA_KEY]['overallInfo']['OverallGameDirectWinLoss']) + parseFloat(responseJson[ApiKey._API_DATA_KEY]['overallInfo']['OverallInDirectGameWinLoss']),
                gameCommTotal: parseFloat(responseJson[ApiKey._API_DATA_KEY]['overallInfo']['OverallDirectGameCommission']) + parseFloat(responseJson[ApiKey._API_DATA_KEY]['overallInfo']['OverallInDirectGameCommission'])
            });

            setExpandIndex([]);
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }

        setIsRefreshingReport(false);
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const updateTargetRefArray = (refId, username) => {
        if (refId != targetRefArray[targetRefArray.length - 1].targetRefId) {
            let refIdArray = [...targetRefArray];
            let refIndex = refIdArray.findIndex(r => r.targetRefId == refId);

            if (refIndex > -1) {
                refIdArray = refIdArray.slice(0, refIndex + 1);
            }
            else {
                refIdArray.push({ targetRefId: refId, username: username });
            }

            setTargetRefArray(refIdArray);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function fontColorFormatter(value = 0) {
        let fontColor = 'white';

        if (value > 0) {
            fontColor = 'green.300';
        }
        else if (value < 0) {
            fontColor = 'red';
        }

        return fontColor;
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'GAME_DAILY_REPORT'} style={{ paddingTop: '5px' }}
                    rightComponent={
                        <Popover>
                            {({ isOpen, onClose }) => (
                                <>
                                    <PopoverTrigger>
                                        <Button variant={'link'} justifyContent={'flex-end'} onClick={() => { }}>
                                            <Image boxSize={'20px'} src={require('../../../assets/images/dots.png')} />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent w={'30vw'}>
                                        <PopoverHeader onClick={() => {
                                            setUseSimpleReport(true);
                                            onClose();
                                        }}>
                                            <Flex>
                                                <Text fontWeight={'bold'} color={'black'} mr={3}>{useSimpleReport && '✔'}</Text>
                                                <Text color={'black'}>{t('SIMPLE')}</Text>
                                            </Flex>
                                        </PopoverHeader>
                                        <PopoverHeader onClick={() => {
                                            setUseSimpleReport(false);
                                            onClose();
                                        }}>
                                            <Flex>
                                                <Text fontWeight={'bold'} color={'black'} mr={3}>{!useSimpleReport && '✔'}</Text>
                                                <Text color={'black'}>{t('FULL')}</Text>
                                            </Flex>
                                        </PopoverHeader>
                                    </PopoverContent>
                                </>
                            )}
                        </Popover>
                    }>
                    {
                        (reportData.length > 0 || targetRefArray.length > 0) &&
                        <>
                            <Marquee style={{ alignSelf: 'center', width: '70%' }}>
                                <Text color={'white'} fontStyle={'italic'} fontWeight={'semibold'}>{t('GAME_DAILY_REPORT_RULES')}</Text>
                            </Marquee>
                            <Divider className='app-divider-full-width-setting' />
                            <Flex mt={[2.5, 1]} ml={4}>
                                <Text fontWeight={'semibold'} mr={3} onClick={() => setShowDatePicker(!showDatePicker)}>
                                    {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                </Text>
                                <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                            </Flex>
                        </>
                    }
                </GeneralTopBar>
                {
                    reportData.length > 0 || targetRefArray.length > 0 ?
                        <Stack className="header-margin-top">
                            <Breadcrumb separator='>' color={'white'}>
                                {
                                    targetRefArray.map((item, index) => {
                                        return (
                                            <BreadcrumbItem key={index} onClick={() => { updateTargetRefArray(item.targetRefId, item.username) }}>
                                                <BreadcrumbLink color={'white'}><Text textDecoration={targetRefArray.length > 1 && index != targetRefArray.length ? 'underline' : 'unset'}>{item.username}</Text></BreadcrumbLink>
                                            </BreadcrumbItem>
                                        )
                                    })
                                }
                            </Breadcrumb>
                            {
                                useSimpleReport ?
                                    <>
                                        <Box className="transaction-box">
                                            <Flex w='full' align={'center'}>
                                                <Spacer />
                                                <Stack spacing={0} align={'center'}>
                                                    {
                                                        isRefreshingReport ?
                                                            <Spinner color="white" /> :
                                                            <Text fontWeight={'semibold'} fontSize={'md'} color={fontColorFormatter(overallReportData?.gameTotal ?? 0)}>{numberWithCurrencyFormat(overallReportData?.gameTotal ?? 0)}</Text>
                                                    }
                                                    <Text color={'#A0B1CB'}>{t("GAME_WINLOSS_TOTAL")}</Text>
                                                </Stack>
                                                <Spacer />
                                                <Divider className="app-divider-darker-bar-height" orientation="vertical" />
                                                <Spacer />
                                                <Stack spacing={0} align={'center'}>
                                                    {
                                                        isRefreshingReport ?
                                                            <Spinner color="white" /> :
                                                            <Text fontWeight={'semibold'} fontSize={'md'}>{numberWithCurrencyFormat(overallReportData?.gameCommTotal > 0 ? overallReportData?.gameCommTotal : 0)}</Text>
                                                    }
                                                    <Text color={'#A0B1CB'}>{t("GAME_COMMISSION_TOTAL")}</Text>
                                                </Stack>
                                                <Spacer />
                                            </Flex>
                                        </Box>
                                        <Accordion allowMultiple index={expandIndex} onChange={(value) => setExpandIndex([...value])}>
                                            {
                                                reportData.map((item, index) => {
                                                    return (
                                                        <AccordionItem
                                                            key={index}
                                                            borderTopWidth={0}
                                                            borderBottomWidth={0.5}
                                                            borderBottomColor={'#3D4B6E'}
                                                            marginInline={['-3.75vw', '0']}>
                                                            {({ isExpanded }) => (
                                                                <>
                                                                    <AccordionButton>
                                                                        <Flex align={'center'} w={'full'}>
                                                                            <Image boxSize={'35px'} src={require('../../../assets/images/chat/chat-people.png')} />
                                                                            <Stack spacing={0} align={'flex-start'} ml={3}>
                                                                                <Text>{item.directUsername} {item?.status != Status._ENABLED && ' (' + t(item?.statusString) + ')'}</Text>
                                                                                <Text fontWeight={'bold'} color={fontColorFormatter(item.directGameWinLoss + item.inDirectGameWinLoss)}>
                                                                                    {`${t("GAME_WINLOSS")} : ${numberWithCurrencyFormat(item.directGameWinLoss + item.inDirectGameWinLoss)}`}
                                                                                </Text>
                                                                                <Text fontWeight={'bold'}>
                                                                                    {`${t("GAME_COMM")} : ${numberWithCurrencyFormat(item.directGameCommission + item.inDirectGameCommission > 0 ? item.directGameCommission + item.inDirectGameCommission : 0)}`}
                                                                                </Text>
                                                                            </Stack>
                                                                            <Spacer />
                                                                            <Image
                                                                                boxSize={'25px'}
                                                                                transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                                                                                src={require('../../../assets/images/general-back.png')} />
                                                                        </Flex>
                                                                    </AccordionButton>
                                                                    <AccordionPanel pb={4}>
                                                                        <TableContainer>
                                                                            <Table variant={'simple'}>
                                                                                <Tr borderBottomColor={'#2D3C5C'}>
                                                                                    <Th color={'white'}>{t('USER')}</Th>
                                                                                    <Td><Text>{item.directUsername}</Text></Td>
                                                                                    <Td>
                                                                                        <Text whiteSpace={'break-spaces'} textDecoration={targetRefArray.length >= 4 || item.selfContributed ? 'unset' : 'underline'}
                                                                                            onClick={() => {
                                                                                                if (targetRefArray.length < 4 && !item.selfContributed) {
                                                                                                    updateTargetRefArray(item.directId, item.directUsername)
                                                                                                }
                                                                                            }}>
                                                                                            {`${item.directUsername}${t('DOWNLINE')}`}
                                                                                        </Text>
                                                                                    </Td>
                                                                                </Tr>
                                                                                <Tr>
                                                                                    <Th color={'white'}>{t('GAME_WINLOSS')}</Th>
                                                                                    <Td><Text color={fontColorFormatter(item.directGameWinLoss)}>{numberWithCurrencyFormat(item.directGameWinLoss)}</Text></Td>
                                                                                    <Td><Text color={fontColorFormatter(item.inDirectGameWinLoss)}>{!item.selfContributed && numberWithCurrencyFormat(item.inDirectGameWinLoss)}</Text></Td>
                                                                                </Tr>
                                                                                <Tr>
                                                                                    <Th color={'white'}>{t('GAME_COMM')}</Th>
                                                                                    <Td><Text>{numberWithCurrencyFormat(item.directGameCommission > 0 ? item.directGameCommission : 0)}</Text></Td>
                                                                                    <Td><Text>{numberWithCurrencyFormat(!item.selfContributed && (item.inDirectGameCommission > 0 ? item.inDirectGameCommission : 0))}</Text></Td>
                                                                                </Tr>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </AccordionPanel>
                                                                </>
                                                            )}
                                                        </AccordionItem>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                    </> :
                                    <TableContainer borderRadius={10}>
                                        <Table variant={'simple'}>
                                            <Thead>
                                                <Tr color={'white'}>
                                                    {
                                                        _FULL_REPORT_HEADER.map((item) => {
                                                            return (
                                                                <Th color={'white'} fontWeight={'bold'} backgroundColor={item.bgColor}>{t(item.title)}</Th>
                                                            )
                                                        })
                                                    }
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    reportData.map((item) => {
                                                        return (
                                                            <Tr>
                                                                <Td backgroundColor={'#364158'}>
                                                                    <Text
                                                                        textAlign={'center'}
                                                                        textDecoration={targetRefArray.length >= 4 || item.selfContributed ? 'unset' : 'underline'}
                                                                        onClick={() => {
                                                                            if (targetRefArray.length < 4 && !item.selfContributed) {
                                                                                updateTargetRefArray(item.directId, item.directUsername)
                                                                            }
                                                                        }}>{`${item.directUsername}`} {item?.status != Status._ENABLED && ' (' + t(item?.statusString) + ')'}
                                                                    </Text>
                                                                </Td>
                                                                <Td backgroundColor={'#A7B7D06C'}><Text textAlign={'center'} color={fontColorFormatter(item.directGameWinLoss)}>{numberWithCurrencyFormat(item.directGameWinLoss)}</Text></Td>
                                                                <Td backgroundColor={'#A7B7D06C'}><Text textAlign={'center'}>{numberWithCurrencyFormat(item.directGameCommission > 0 ? item.directGameCommission : 0)}</Text></Td>
                                                                <Td backgroundColor={'#65799D'}><Text textAlign={'center'} color={fontColorFormatter(item.inDirectGameWinLoss)}>{!item.selfContributed && numberWithCurrencyFormat(item.inDirectGameWinLoss)}</Text></Td>
                                                                <Td backgroundColor={'#65799D'}><Text textAlign={'center'}>{!item.selfContributed && (numberWithCurrencyFormat(item.inDirectGameCommission > 0 ? item.inDirectGameCommission : 0))}</Text></Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                            <Tfoot backgroundColor={'#B59A00'}>
                                                <Tr>
                                                    <Th color={'white'} fontWeight={'bold'} textAlign={'right'}>{t('TOTAL')}</Th>
                                                    <Th color={fontColorFormatter(reportData.reduce((total, obj) => obj.directGameWinLoss + total, 0))} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.directGameWinLoss + total, 0))}</Th>
                                                    <Th color={'white'} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.directGameCommission + total, 0) > 0 ? reportData.reduce((total, obj) => obj.directGameCommission + total, 0) : 0)}</Th>
                                                    <Th color={fontColorFormatter(reportData.reduce((total, obj) => obj.inDirectGameWinLoss + total, 0))} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.inDirectGameWinLoss + total, 0))}</Th>
                                                    <Th color={'white'} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.inDirectGameCommission + total, 0) > 0 ? reportData.reduce((total, obj) => obj.inDirectGameCommission + total, 0) : 0)}</Th>
                                                </Tr>
                                            </Tfoot>
                                        </Table>
                                    </TableContainer>
                            }
                        </Stack> :
                        <AbsoluteCenter textAlign={'center'} w={'full'}>
                            <Center>
                                <Image boxSize={'150px'} src={require("../../../assets/images/empty-downline.png")} />
                            </Center>
                            <Text fontSize={'md'} fontWeight={'bold'}>{t("NO_DOWNLINE_MEMBER")}</Text>
                            <Text fontSize={'sm'} fontStyle={'italic'}>{t("CURRENTLY_YOU_DONT_HAVE_ANY_DIRECT_DOWNLINE_YET")}</Text>
                        </AbsoluteCenter>
                }
                <DateFilter
                    isOpen={showDatePicker}
                    onClose={() => setShowDatePicker(!showDatePicker)}
                    closeOnOverlayClick={false}
                    applyDateFilter={(data) => {
                        let start = moment(data.startDate).format('yyyy-MM-DD');
                        let end = moment(data.endDate).format('yyyy-MM-DD');
                        setStartDate(start);
                        setEndDate(end);
                        getCommReport(start, end);
                    }}
                />
            </>
        )
    } else {
        return (
            <>
                <Center mt={16} className="winloss-table">
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "45rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "20px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'}>{t('GAME_DAILY_REPORT')}</Text>
                            <Popover>
                                {({ isOpen, onClose }) => (
                                    <>
                                        <PopoverTrigger>
                                            <Button variant={'link'} float={'right'} justifyContent={'flex-end'} onClick={() => { }}>
                                                <Image boxSize={'20px'} src={require('../../../assets/images/dots.png')} />
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent w={'10rem'}>
                                            <PopoverHeader onClick={() => {
                                                setUseSimpleReport(true);
                                                onClose();
                                            }}>
                                                <Flex cursor={'pointer'}>
                                                    <Text fontWeight={'bold'} color={'black'} mr={3}>{useSimpleReport && '✔'}</Text>
                                                    <Text color={'black'}>{t('SIMPLE')}</Text>
                                                </Flex>
                                            </PopoverHeader>
                                            <PopoverHeader onClick={() => {
                                                setUseSimpleReport(false);
                                                onClose();
                                            }}>
                                                <Flex cursor={'pointer'}>
                                                    <Text fontWeight={'bold'} color={'black'} mr={3}>{!useSimpleReport && '✔'}</Text>
                                                    <Text color={'black'}>{t('FULL')}</Text>
                                                </Flex>
                                            </PopoverHeader>
                                        </PopoverContent>
                                    </>
                                )}
                            </Popover>
                        </div>
                        {
                            (reportData.length > 0 || targetRefArray.length > 0) &&
                            <>
                                <Center mb={5}>
                                    <Marquee style={{ width: '80%' }}>
                                        <Text color={'white'} fontStyle={'italic'} fontWeight={'semibold'}>{t('GAME_DAILY_REPORT_RULES')}</Text>
                                    </Marquee>
                                </Center>
                            </>
                        }
                        <Card>
                            <CardBody bg={'#2c3956'} marginInline={2} borderRadius={15}>
                                {
                                    reportData.length > 0 || targetRefArray.length > 0 ?
                                        <>
                                            <Flex mb={5}>
                                                <Text fontWeight={'semibold'} mr={3} cursor={'pointer'} onClick={() => setShowDatePicker(!showDatePicker)}>
                                                    {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                                </Text>
                                                <img style={{cursor:"pointer"}} onClick={() => setShowDatePicker(!showDatePicker)} src={require('../../../assets/images/svg/updown-white.svg').default} />
                                            </Flex>
                                            <Stack>
                                                <Breadcrumb mb={1} separator='>' color={'white'} marginInline={3}>
                                                    {
                                                        targetRefArray.map((item, index) => {
                                                            return (
                                                                <BreadcrumbItem key={index} onClick={() => { updateTargetRefArray(item.targetRefId, item.username) }}>
                                                                    <BreadcrumbLink color={'white'}><Text textDecoration={targetRefArray.length > 1 && index != targetRefArray.length ? 'underline' : 'unset'}>{item.username}</Text></BreadcrumbLink>
                                                                </BreadcrumbItem>
                                                            )
                                                        })
                                                    }
                                                </Breadcrumb>
                                                {
                                                    useSimpleReport ?
                                                        <>
                                                            <Box bg={'#182031'} borderRadius={15} paddingBlock={4} borderColor={'white'} borderWidth={1} marginInline={3}>
                                                                <Flex w='full' align={'center'}>
                                                                    <Spacer />
                                                                    <Stack spacing={0} align={'center'}>
                                                                        {
                                                                            isRefreshingReport ?
                                                                                <Spinner color="white" /> :
                                                                                <Text fontWeight={'semibold'} fontSize={'lg'} color={fontColorFormatter(overallReportData?.gameTotal ?? 0)}>{numberWithCurrencyFormat(overallReportData?.gameTotal ?? 0)}</Text>
                                                                        }
                                                                        <Text color={'#A0B1CB'}>{t("GAME_WINLOSS_TOTAL")}</Text>
                                                                    </Stack>
                                                                    <Spacer />
                                                                    <Divider className="app-divider-darker-bar-height" borderColor={'#92969e !important'} orientation="vertical" />
                                                                    <Spacer />
                                                                    <Stack spacing={0} align={'center'}>
                                                                        {
                                                                            isRefreshingReport ?
                                                                                <Spinner color="white" /> :
                                                                                <Text fontWeight={'semibold'} fontSize={'lg'}>{numberWithCurrencyFormat(overallReportData?.gameCommTotal > 0 ? overallReportData?.gameCommTotal : 0)}</Text>
                                                                        }
                                                                        <Text color={'#A0B1CB'}>{t("GAME_COMMISSION_TOTAL")}</Text>
                                                                    </Stack>
                                                                    <Spacer />
                                                                </Flex>
                                                            </Box>
                                                            <Accordion allowMultiple index={expandIndex} onChange={(value) => setExpandIndex([...value])}>
                                                                {
                                                                    reportData.map((item, index) => {
                                                                        return (
                                                                            <AccordionItem
                                                                                key={index}
                                                                                borderTopWidth={0}
                                                                                borderBottomWidth={'0 !important'}
                                                                                borderBottomColor={'#3D4B6E'}
                                                                                marginInline={['-3.75vw', '0']}>
                                                                                {({ isExpanded }) => (
                                                                                    <>
                                                                                        <AccordionButton mt={1}>
                                                                                            <Flex align={'center'} w={'full'}>
                                                                                                <Image boxSize={'35px'} src={require('../../../assets/images/chat/chat-people.png')} />
                                                                                                <Stack spacing={0} align={'flex-start'} ml={3}>
                                                                                                    <Text>{item.directUsername} {item?.status != Status._ENABLED && ' (' + t(item?.statusString) + ')'}</Text>
                                                                                                    <Text fontSize={'sm'} fontWeight={'bold'} color={fontColorFormatter(item.directGameWinLoss + item.inDirectGameWinLoss)}>
                                                                                                        {`${t("GAME_WINLOSS")} : ${numberWithCurrencyFormat(item.directGameWinLoss + item.inDirectGameWinLoss)}`}
                                                                                                    </Text>
                                                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                                                        {`${t("GAME_COMM")} : ${numberWithCurrencyFormat(item.directGameCommission + item.inDirectGameCommission > 0 ? item.directGameCommission + item.inDirectGameCommission : 0)}`}
                                                                                                    </Text>
                                                                                                </Stack>
                                                                                                <Spacer />
                                                                                                <Image
                                                                                                    boxSize={'28px'}
                                                                                                    transform={isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'}
                                                                                                    src={require('../../../assets/images/general-back.png')} />
                                                                                            </Flex>
                                                                                        </AccordionButton>
                                                                                        <AccordionPanel pb={4}>
                                                                                            <TableContainer>
                                                                                                <Table bg={'#182031'} borderRadius={15} padding={3}>
                                                                                                    <Tr borderBottomColor={'#2D3C5C'}>
                                                                                                        <Th color={'white'}>{t('USER')}</Th>
                                                                                                        <Td><Text>{item.directUsername}</Text></Td>
                                                                                                        <Td>
                                                                                                            <Text whiteSpace={'break-spaces'} textDecoration={targetRefArray.length >= 4 || item.selfContributed ? 'unset' : 'underline'} cursor={targetRefArray.length >= 4 || item.selfContributed ? '' : 'pointer'}
                                                                                                                onClick={() => {
                                                                                                                    if (targetRefArray.length < 4 && !item.selfContributed) {
                                                                                                                        updateTargetRefArray(item.directId, item.directUsername)
                                                                                                                    }
                                                                                                                }}>
                                                                                                                {`${item.directUsername}${t('DOWNLINE')}`}
                                                                                                            </Text>
                                                                                                        </Td>
                                                                                                    </Tr>
                                                                                                    <Tr>
                                                                                                        <Th color={'white'}>{t('GAME_WINLOSS')}</Th>
                                                                                                        <Td><Text color={fontColorFormatter(item.directGameWinLoss)}>{numberWithCurrencyFormat(item.directGameWinLoss)}</Text></Td>
                                                                                                        <Td><Text color={fontColorFormatter(item.inDirectGameWinLoss)}>{!item.selfContributed && numberWithCurrencyFormat(item.inDirectGameWinLoss)}</Text></Td>
                                                                                                    </Tr>
                                                                                                    <Tr>
                                                                                                        <Th borderBottom={'none'} color={'white'}>{t('GAME_COMM')}</Th>
                                                                                                        <Td borderBottom={'none'}><Text>{numberWithCurrencyFormat(item.directGameCommission > 0 ? item.directGameCommission : 0)}</Text></Td>
                                                                                                        <Td borderBottom={'none'}><Text>{numberWithCurrencyFormat(!item.selfContributed && (item.inDirectGameCommission > 0 ? item.inDirectGameCommission : 0))}</Text></Td>
                                                                                                    </Tr>
                                                                                                </Table>
                                                                                            </TableContainer>
                                                                                        </AccordionPanel>
                                                                                    </>
                                                                                )}
                                                                            </AccordionItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Accordion>
                                                        </> :
                                                        <TableContainer borderRadius={10}>
                                                            <Table variant={'simple'}>
                                                                <Thead>
                                                                    <Tr color={'white'}>
                                                                        {
                                                                            _FULL_REPORT_HEADER.map((item) => {
                                                                                return (
                                                                                    <Th color={'white'} fontWeight={'bold'} backgroundColor={item.bgColor}>{t(item.title)}</Th>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {
                                                                        reportData.map((item) => {
                                                                            return (
                                                                                <Tr>
                                                                                    <Td backgroundColor={'#364158'}>
                                                                                        <Text
                                                                                            textAlign={'center'}
                                                                                            textDecoration={targetRefArray.length >= 4 || item.selfContributed ? 'unset' : 'underline'} cursor={targetRefArray.length >= 4 || item.selfContributed ? '' : 'pointer'}
                                                                                            onClick={() => {
                                                                                                if (targetRefArray.length < 4 && !item.selfContributed) {
                                                                                                    updateTargetRefArray(item.directId, item.directUsername)
                                                                                                }
                                                                                            }}>{`${item.directUsername}`} {item?.status != Status._ENABLED && ' (' + t(item?.statusString) + ')'}
                                                                                        </Text>
                                                                                    </Td>
                                                                                    <Td backgroundColor={'#A7B7D06C'}><Text textAlign={'center'} color={fontColorFormatter(item.directGameWinLoss)}>{numberWithCurrencyFormat(item.directGameWinLoss)}</Text></Td>
                                                                                    <Td backgroundColor={'#A7B7D06C'}><Text textAlign={'center'}>{numberWithCurrencyFormat(item.directGameCommission > 0 ? item.directGameCommission : 0)}</Text></Td>
                                                                                    <Td backgroundColor={'#65799D'}><Text textAlign={'center'} color={fontColorFormatter(item.inDirectGameWinLoss)}>{!item.selfContributed && numberWithCurrencyFormat(item.inDirectGameWinLoss)}</Text></Td>
                                                                                    <Td backgroundColor={'#65799D'}><Text textAlign={'center'}>{!item.selfContributed && (numberWithCurrencyFormat(item.inDirectGameCommission > 0 ? item.inDirectGameCommission : 0))}</Text></Td>
                                                                                </Tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </Tbody>
                                                                <Tfoot backgroundColor={'#B59A00'}>
                                                                    <Tr>
                                                                        <Th fontSize={'sm'} color={'white'} fontWeight={'bold'} textAlign={'right'}>{t('TOTAL')}</Th>
                                                                        <Th fontSize={'sm'} color={fontColorFormatter(reportData.reduce((total, obj) => obj.directGameWinLoss + total, 0))} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.directGameWinLoss + total, 0))}</Th>
                                                                        <Th fontSize={'sm'} color={'white'} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.directGameCommission + total, 0) > 0 ? reportData.reduce((total, obj) => obj.directGameCommission + total, 0) : 0)}</Th>
                                                                        <Th fontSize={'sm'} color={fontColorFormatter(reportData.reduce((total, obj) => obj.inDirectGameWinLoss + total, 0))} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.inDirectGameWinLoss + total, 0))}</Th>
                                                                        <Th fontSize={'sm'} color={'white'} fontWeight={'bold'} textAlign={'center'}>{numberWithCurrencyFormat(reportData.reduce((total, obj) => obj.inDirectGameCommission + total, 0) > 0 ? reportData.reduce((total, obj) => obj.inDirectGameCommission + total, 0) : 0)}</Th>
                                                                    </Tr>
                                                                </Tfoot>
                                                            </Table>
                                                        </TableContainer>
                                                }
                                            </Stack>
                                        </>
                                        :
                                        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                                            <Center>
                                                <Image boxSize={'150px'} src={require("../../../assets/images/empty-downline.png")} />
                                            </Center>
                                            <Text fontSize={'md'} fontWeight={'bold'}>{t("NO_DOWNLINE_MEMBER")}</Text>
                                            <Text fontSize={'sm'} fontStyle={'italic'}>{t("CURRENTLY_YOU_DONT_HAVE_ANY_DIRECT_DOWNLINE_YET")}</Text>
                                        </div>
                                }
                            </CardBody>
                        </Card>
                        <DateFilter
                            isOpen={showDatePicker}
                            onClose={() => setShowDatePicker(!showDatePicker)}
                            closeOnOverlayClick={false}
                            applyDateFilter={(data) => {
                                let start = moment(data.startDate).format('yyyy-MM-DD');
                                let end = moment(data.endDate).format('yyyy-MM-DD');
                                setStartDate(start);
                                setEndDate(end);
                                getCommReport(start, end);
                            }}
                        />
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
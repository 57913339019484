import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Tabs, TabList, Tab, Image, Center, Text } from "@chakra-ui/react";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, isObjectEmpty } from "../../../common/util";
import { ApiKey } from "../../../common/constant";
import GeneralTopBar from "../../../component/general-top-bar";
import PinReload from "./pin-reload";
import InstantReload from "./instant-reload";
import PaymentReload from "./payment-reload";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const _PARAMS = _location?.state;
    const [routes, setRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState();
    const _ROUTE_KEY_PIN = "PIN";
    const _ROUTE_KEY_ETU = "ETU";
    const _ROUTE_KEY_PMT = "PMT";
    const _TAB_OPTION = [
        { key: _ROUTE_KEY_ETU, title: 'INSTANT_TOP_UP' },
        { key: _ROUTE_KEY_PIN, title: 'PIN' },
        { key: _ROUTE_KEY_PMT, title: 'PAYMENT' }
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back()
        }
        else {
            getSubcategoryDetail();
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getSubcategoryDetail() {
        var responseJson = await ApiEngine.get(Routes._GET_SERVICE_DETAIL + '?subCategoryId=' + _PARAMS?.subCategory?.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let mergedMenu = responseJson[ApiKey._API_DATA_KEY].selection.map(itm => ({
                ..._TAB_OPTION.find((item) => (item.key.includes(itm.toUpperCase())) && responseJson[ApiKey._API_DATA_KEY][`${item.key.toLowerCase()}`]),
                ...responseJson[ApiKey._API_DATA_KEY][`${itm}`]
            }));

            setRoutes(mergedMenu);
            setSelectedRoute(mergedMenu[0])
        }
        else {
            _dispatch(showCustomDialog({
                success: false,
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: () => {
                    _router.back();
                }
            }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const renderContent = () => {
        if (!isObjectEmpty(selectedRoute)) {
            switch (selectedRoute.key) {
                case _ROUTE_KEY_PIN:
                    return (
                        <PinReload
                            memberGuid={_USER_DATA.guid}
                            type={_ROUTE_KEY_PIN}
                            service={_PARAMS?.subCategory}
                            serviceDetail={selectedRoute}
                            successImage={_PARAMS?.title} />
                    )
                case _ROUTE_KEY_ETU:
                    return (
                        <InstantReload
                            memberGuid={_USER_DATA.guid}
                            type={_ROUTE_KEY_ETU}
                            service={_PARAMS?.subCategory}
                            serviceDetail={selectedRoute}
                            successImage={_PARAMS?.title} />
                    )
                case _ROUTE_KEY_PMT:
                    return (
                        <PaymentReload
                            memberGuid={_USER_DATA.guid}
                            type={_ROUTE_KEY_PMT}
                            service={_PARAMS?.subCategory}
                            serviceDetail={selectedRoute}
                            successImage={_PARAMS?.title} />
                    )
            }
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={_PARAMS?.title} />
                <Stack>
                    <Tabs isFitted size='sm' variant='enclosed' marginInline={'-3.75vw'} mb={5}>
                        <TabList>
                            {
                                routes.map((item) => {
                                    return (
                                        <Tab className="service-tabs" onClick={() => { setSelectedRoute(item); }}>{t(item.title)}</Tab>
                                    )
                                })
                            }
                        </TabList>
                    </Tabs>
                    {
                        renderContent()
                    }
                </Stack>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={10} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/back-desktop.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t(_PARAMS?.title)}</Text>
                        </div>
                        <Stack spacing={0} minW={'33rem'}>
                            <Tabs isFitted size='sm' variant='enclosed'>
                                <TabList>
                                    {
                                        routes.map((item) => {
                                            return (
                                                <Tab bg={selectedRoute == item ? '#2c3956' : '#20283e'} border={'none'} color={'white'} fontSize={'md'} paddingBlock={3} borderTopRadius={15}
                                                    onClick={() => { setSelectedRoute(item); }}>{t(item.title)}</Tab>
                                            )
                                        })
                                    }
                                </TabList>
                            </Tabs>
                            {
                                renderContent()
                            }
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}